import classNames from 'classnames';
import React from 'react';

export interface LinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
}

function Link(props: LinkProps) {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      className={classNames(
        'text-blue-600 hover:underline dark:font-normal dark:text-blue-500 md:right-0',
        props.className
      )}
    >
      {props.children}
    </a>
  );
}

export default Link;
