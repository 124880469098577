import { Porro } from 'porro';
import React, { useMemo } from 'react';

export const RCP_BUCKET = new Porro({
  bucketSize: 3,
  interval: 100,
  tokensPerInterval: 1,
});

export const RcpBucketContext = React.createContext({
  state: RCP_BUCKET,
});

const RcpBucketProvider = (props: { children: React.ReactNode; refreshIntervalMs: number }) => {
  const bucket = useMemo(() => RCP_BUCKET, []);
  return <RcpBucketContext.Provider value={{ state: bucket }}>{props.children}</RcpBucketContext.Provider>;
};

export default RcpBucketProvider;
