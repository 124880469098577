import { createAttributesMap, filterNfts } from 'helpers/nftHelpers';
import React, { useEffect, useState } from 'react';
import { AttributeName, TraitName } from 'types';
import { EvmChainName, getChainByEvmChainName, supportedChains } from 'web3/chainsAndWallets';
import { INftFilterProps } from '../NftFilter';

function useSidebar(props: INftFilterProps) {
  const [expandedAttribute, setExpandedAttribute] = useState<AttributeName | null>(null);
  const [attributeFilters, setAttributeFilters] = React.useState<Map<AttributeName, Set<TraitName>>>(
    props.filters.attributeFilters
  );
  const [filteredTraits, setFilteredTraits] = useState<Map<AttributeName, Map<TraitName, number>>>(new Map());
  const [evmChainNamesInWallet, setEvmChainNamesInWallet] = useState<EvmChainName[]>([]);

  useEffect(() => {
    setAttributeFilters((prevFilters) =>
      prevFilters !== props.filters.attributeFilters ? props.filters.attributeFilters : prevFilters
    );

    if (expandedAttribute) {
      const attributeFilterExcludingExpanded = new Map(props.filters.attributeFilters);
      attributeFilterExcludingExpanded.delete(expandedAttribute);
      const filteredNfts = filterNfts(
        props.vaultWalletNfts,
        attributeFilterExcludingExpanded,
        '',
        undefined,
        props.filters.locationFilter,
        props.filters.chainFilter
      );

      const filteredTraitsMap = new Map<AttributeName, Map<TraitName, number>>();
      [...createAttributesMap(filteredNfts).entries()].forEach(([attributeName, traits]) => {
        const filteredTraits = new Map<string, number>(
          [...traits.entries()].map(([traitName, count]) => [traitName, count])
        );
        filteredTraitsMap.set(attributeName, filteredTraits);
      });
      setFilteredTraits(filteredTraitsMap);
    }
  }, [
    props.filters.attributeFilters,
    expandedAttribute,
    props.vaultWalletNfts,
    props.filters.locationFilter,
    props.filters.chainFilter,
  ]);

  useEffect(() => {
    const newEvmChainNames = [...props.vaultWalletNfts]
      .filter((nft) => getChainByEvmChainName(nft.location.chain) != null)
      .map((nft) => nft.location.chain);

    const sortedEvmChainNames = supportedChains
      .map((chain) => chain.chainName as EvmChainName)
      .filter((chain) => newEvmChainNames.includes(chain));

    setEvmChainNamesInWallet(sortedEvmChainNames);
  }, [props.vaultWalletNfts]);

  const handleClearFilter = (id: boolean, attribute: string | 'all') => {
    id && props.filters.onNameFilterChange('');

    const newFilters = new Map([...props.filters.attributeFilters]);
    if (attribute === 'all') {
      newFilters.clear();
    } else {
      newFilters.delete(attribute);
    }

    setAttributeFilters(newFilters);
    props.filters.onAttributeFiltersChange(newFilters);
  };

  const handleAttributeFilterChange = (attribute: AttributeName, trait: TraitName, checked: boolean) => {
    const newAttributeFilters = new Map(attributeFilters);
    const traits = newAttributeFilters.get(attribute) ?? new Set();
    newAttributeFilters.set(attribute, traits);

    if (checked) {
      traits.add(trait);
    } else {
      traits.delete(trait);
    }

    if (traits.size === 0) {
      newAttributeFilters.delete(attribute);
    }

    setAttributeFilters(newAttributeFilters);
    setTimeout(() => props.filters.onAttributeFiltersChange(newAttributeFilters), 200);
  };

  return {
    attributeFilters,
    expandedAttribute,
    setExpandedAttribute,
    handleAttributeFilterChange,
    handleClearFilter,
    filteredTraits,
    evmChainNamesInWallet,
  };
}

export default useSidebar;
