import { Tooltip } from '@material-tailwind/react';
import Big from 'big.js';
import { Link, StatsCard } from 'components';
import AssetDisplay from 'components/AssetDisplay';
import useStatsTreasury, { IStatsTreasuryToken } from 'components/StatsTreasury/hooks/useStatsTreasury';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig, shortenAddress } from 'helpers/formatHelpers';
import links from 'links';
import React from 'react';
import { mainChain } from 'web3/chainsAndWallets';
import { APEDAO_SAFE_WALLET_ADDRESS } from 'web3/constants';

export interface IStatsTreasuryProps {
  stats: IStats;
  isDarkMode: boolean;
  mobileView: boolean;
}

const minAmountToBeDisplayed = Big(0.001);

export function StatsTreasury(props: IStatsTreasuryProps) {
  const data = useStatsTreasury(props);

  const renderAmount = React.useCallback((amount: Big) => {
    const isSmallAmount = amount.gt(0) && amount.lt(1);
    const prefix = isSmallAmount ? `< ` : '';

    return (
      <div className="grow text-end font-mono">
        {amount.gt(minAmountToBeDisplayed) ? prefix + formatBig(isSmallAmount ? Big(1) : amount, 0, '.', true) : '-'}
      </div>
    );
  }, []);

  const renderColumn = React.useCallback(
    (header: string, amountSupplier: (token: IStatsTreasuryToken) => Big) => {
      return (
        data && (
          <div className="flex flex-col items-end gap-2 sm:gap-1">
            <span className="whitespace-nowrap font-bold">{header}</span>
            {data.tokens
              .filter((token) => (props.mobileView ? amountSupplier(token).gt(minAmountToBeDisplayed) : true))
              .map((token) => (
                <div key={token.symbol} className="flex w-full flex-row sm:gap-2">
                  {props.mobileView && <AssetDisplay key={token.symbol} name={token.symbol} />}
                  <div className="flex w-full flex-row sm:gap-2">
                    <Tooltip content={formatBig(amountSupplier(token), amountSupplier(token).lt(1) ? 8 : 0)}>
                      {renderAmount(amountSupplier(token))}
                    </Tooltip>
                  </div>
                </div>
              ))}
          </div>
        )
      );
    },
    [data, props.mobileView, renderAmount]
  );

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="ApeDAO Treasury">
      <div className="flex flex-col gap-3 text-sm sm:flex-row sm:gap-5 md:gap-6 lg:text-base">
        {!props.mobileView && (
          <div className="flex grow flex-col justify-end gap-1 pr-4">
            {data.tokens.map((token) => (
              <AssetDisplay key={token.symbol} name={token.symbol} />
            ))}
          </div>
        )}
        {renderColumn('Wallet', (token) => token.amountInWallet)}
        {props.mobileView && <hr />}
        {renderColumn('LP/Farming', (token) => token.amountInLPAndFarming)}
        {props.mobileView && <hr />}
        {renderColumn('Staking', (token) => token.amountStaked)}
        {props.mobileView && <hr />}
        {renderColumn('Total', (token) => token.amountTotal)}
        {props.mobileView && <hr />}
        {renderColumn(props.mobileView ? 'Total (USD)' : 'USD', (token) => token.amountInUSD)}
      </div>
      <div>
        <hr className="my-2 sm:my-1" />
        <div className="flex flex-row justify-between font-semibold">
          <span>Total</span>
          <span className="font-mono ">{formatBig(data.totalUSD, 0)}</span>
        </div>
      </div>
      <div className="sd:hidden mt-4 w-full text-end text-[0.75em]">
        Holdings are in a <Link href={`${links.safe}/balances?safe=iota:${APEDAO_SAFE_WALLET_ADDRESS}`}>Safe</Link>{' '}
        (multisig) wallet on this address{' '}
        <Link href={`${mainChain.blockExplorers.default.url}/address/${APEDAO_SAFE_WALLET_ADDRESS}`}>
          {shortenAddress(APEDAO_SAFE_WALLET_ADDRESS)}
        </Link>
      </div>
    </StatsCard>
  );
}

export default StatsTreasury;
