import React, { Children, ReactElement, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

type ValidOpacities =
  | 'opacity-0'
  | 'opacity-5'
  | 'opacity-10'
  | 'opacity-20'
  | 'opacity-25'
  | 'opacity-30'
  | 'opacity-40'
  | 'opacity-50'
  | 'opacity-60'
  | 'opacity-70'
  | 'opacity-80'
  | 'opacity-90'
  | 'opacity-100';

export interface IFadeInOutProps {
  children: ReactElement;
  isVisible: boolean;
  minOpacity?: ValidOpacities;
  maxOpacity?: ValidOpacities;
  duration?: 'fast' | 'normal' | 'slow';
  animateFadeOut?: boolean;
  unmountOnExit?: boolean;
}

const FadeInOut = (props: IFadeInOutProps) => {
  const child = Children.only(props.children);
  const childRef = useRef<HTMLElement | undefined>();

  const durationClassName =
    props.duration === 'slow'
      ? 'duration-500'
      : props.duration === 'normal'
      ? 'duration-300'
      : props.duration === 'fast'
      ? 'duration-100'
      : 'duration-300';
  const timeout = Number(durationClassName.split('-')[1]);
  const visibleOpacity = props.maxOpacity ?? 'opacity-100';
  const invisibleOpacity = props.minOpacity ?? 'opacity-0';
  const unmountOnExit = props.unmountOnExit != null ? props.unmountOnExit : true;
  const fadeOutClassName =
    props.animateFadeOut == null || props.animateFadeOut
      ? `transition-opacity ${invisibleOpacity} ${durationClassName}`
      : 'hidden';

  return (
    <CSSTransition
      nodeRef={childRef}
      in={props.isVisible}
      classNames={{
        appear: invisibleOpacity,
        appearActive: `transition-opacity ${visibleOpacity} ${durationClassName}`,
        enterDone: visibleOpacity,
        appearDone: visibleOpacity,
        enter: invisibleOpacity,
        enterActive: `transition-opacity ${visibleOpacity} ${durationClassName}`,
        exitDone: 'h-0 w-0 mx-0 my-0',
        exitActive: fadeOutClassName,
        exit: fadeOutClassName,
      }}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
    >
      {React.cloneElement(child, {
        ref: (ref: HTMLElement | undefined) => (childRef.current = ref),
      })}
    </CSSTransition>
  );
};

export default FadeInOut;
