export const LOCAL_STORAGE_SIDEBAR_POSITION = 'apedao_sidebar_position';
export const LOCAL_STORAGE_DARK_MODE = 'apedao_dark_mode';
export const LOCAL_STORAGE_FAVORITES = 'apedao_favorites';
export const LOCAL_STORAGE_ERC20_TOKEN_CACHE = 'apedao_erc20_token_cache';
export const LOCAL_STORAGE_POOL_ID_CACHE = 'apedao_pool_id_cache';
export const SESSION_STORAGE_DEV_MODE = 'apedao_dev_mode';
export const SESSION_STORAGE_ON_BRIDGE = 'apedao_on_bridge';

let devModeCache: boolean | undefined = undefined;

const isDevMode = () => {
  if (devModeCache == null) {
    devModeCache = (sessionStorage.getItem(SESSION_STORAGE_DEV_MODE) ?? 'false') === 'true';
  }
  return devModeCache;
};

export { isDevMode };
