import classNames from 'classnames';
import { ApeDaoIcon } from 'components';
import './Spinner.css';

export interface ISpinnerProps {
  className?: string;
  variant?: 'circle' | 'jumping_ape';
}

function Spinner(props: ISpinnerProps) {
  const variant = props.variant ?? 'circle';

  return (
    <>
      {variant === 'circle' && <div className={classNames(props.className, 'apedao-spinner')} />}
      {variant === 'jumping_ape' && (
        <div className={classNames(props.className, 'apedao-jumping-ape')}>
          <ApeDaoIcon noBackground={true} />
        </div>
      )}
    </>
  );
}

export default Spinner;
