import { useRcpBucket } from 'contexts/RcpBucket';
import { useVaultWalletBaseHook } from 'pages/VaultsAndWallet/hooks/useVaultWalletBaseHook';
import { useCallback } from 'react';
import { ICollection } from 'types';
import { readContract } from 'viem/actions';
import { useClient } from 'wagmi';
import { EvmChainName, mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

export function useVaults(props: { collection?: ICollection }) {
  const client = useClient({ chainId: mainChain.id });
  const { throttleRequest } = useRcpBucket();

  const getAllHoldings = useCallback(
    async (collection: ICollection) => {
      throttleRequest && (await throttleRequest());
      return client
        ? await readContract(client, {
            ...contracts.apeDaoVault,
            functionName: 'allHoldings',
            args: [collection?.id],
          })
        : [];
    },
    [client, throttleRequest]
  );

  const getCurrentVaultHoldings = useCallback(
    async (collection: ICollection): Promise<Map<EvmChainName, BigInt[]>> => {
      const holdings = await getAllHoldings(collection);
      return new Map<EvmChainName, BigInt[]>([[mainChain.chainName, holdings.map((h) => BigInt(h))]]);
    },
    [getAllHoldings]
  );

  const getCurrentBalances = useCallback(
    async (collection: ICollection): Promise<Map<EvmChainName, BigInt>> => {
      const holdings = await getAllHoldings(collection);
      return new Map<EvmChainName, BigInt>([[mainChain.chainName, BigInt(holdings.length)]]);
    },
    [getAllHoldings]
  );

  return useVaultWalletBaseHook({
    mode: 'vault',
    getCurrentBalances: getCurrentBalances,
    getCurrentHoldings: getCurrentVaultHoldings,
    addRandom: props.collection?.featureCart ?? false,
    collection: props.collection,
  });
}
