import classNames from 'classnames';
import { useDarkMode } from 'helpers';
import links from 'links';
import { SocialIcon } from 'react-social-icons';

function OneSocialIcon(props: { url: string; darkMode?: boolean }) {
  return (
    <SocialIcon
      url={props.url}
      target="_blank"
      style={{ maxWidth: 32, maxHeight: 32 }}
      bgColor="transparent"
      fgColor={props.darkMode ? 'white' : '#313131'}
    />
  );
}

export interface ISocialIconsProps {
  className?: string;
}

function SocialIcons(props: ISocialIconsProps) {
  const { darkMode } = useDarkMode();

  return (
    <div className={classNames('flex flex-row', props.className)}>
      <OneSocialIcon url={links.discord} darkMode={darkMode} />
      <OneSocialIcon url={links.twitter} darkMode={darkMode} />
      <OneSocialIcon url={links.medium} darkMode={darkMode} />
      <OneSocialIcon url={links.telegram} darkMode={darkMode} />
      <OneSocialIcon url={links.github} darkMode={darkMode} />
    </div>
  );
}

export default SocialIcons;
