import { ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';
import classNames from 'classnames';
import { Footer, Header } from 'components';
import usePageTemplate from 'components/PageTemplate/hooks/usePageTemplate';
import { SIDEBAR_ANIMATION_DURATION } from 'components/Sidebar/Sidebar';
import { useDarkMode } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import useDevMode from 'helpers/useDevMode';
import { appRootId } from 'pages/App/getIds';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { IWalletInfo } from 'types';
import './PageTemplate.css';
import getIds from './getIds';

export interface IPageTemplateSidebarProps {
  render: (onOpenChange: (open: boolean) => void) => React.ReactNode;
  widthCollapsed: number;
  widthExpanded: number;
}

export interface IPageTemplateProps {
  children: React.ReactNode;
  walletInfo: IWalletInfo;
  sidebarLeft?: IPageTemplateSidebarProps;
  sidebarRight?: IPageTemplateSidebarProps;
  scrollToTopButton?: boolean;
  isLoading?: boolean;
  centerChildren?: boolean;
}

function PageTemplate(props: IPageTemplateProps) {
  const ID = useIds(appRootId, getIds);
  const {
    leftSidebarOpen,
    setLeftSidebarOpen,
    rightSidebarOpen,
    setRightSidebarOpen,
    windowWidth,
    isWindowWidthSmallerThan,
    mainAreaRef,
    mainAreaIsOverflow,
    scrollToTopHook,
    unsupportedNetwork,
  } = usePageTemplate();
  const { darkMode } = useDarkMode();
  const { devMode, toggleDevMode } = useDevMode();

  const sidebarIsPresent = props.sidebarLeft || props.sidebarRight;
  const sidebarIsOpen = leftSidebarOpen || rightSidebarOpen;
  const sidebarWidthCollapsed = Math.max(
    props.sidebarLeft?.widthCollapsed ?? 0,
    props.sidebarRight?.widthCollapsed ?? 0
  );
  const sidebarWidthExpanded = Math.max(props.sidebarLeft?.widthExpanded ?? 0, props.sidebarRight?.widthExpanded ?? 0);

  return (
    <>
      <ToastContainer
        position={isWindowWidthSmallerThan('md') ? 'bottom-center' : 'bottom-right'}
        theme={darkMode ? 'dark' : 'light'}
      />
      <Header
        id={ID.header}
        walletInfo={props.walletInfo}
        blurred={!isWindowWidthSmallerThan('md')}
        className="fixed top-0 z-[100] flex h-[3.5rem] w-full flex-col justify-center md:h-[4.5rem]"
        isDevMode={devMode}
        toggleDevMode={toggleDevMode}
        displayBadgeOnSandwichIcon={unsupportedNetwork}
        isWindowWidthSmallerThan={isWindowWidthSmallerThan}
        navItemContainerStyle={{
          paddingLeft: `${
            props.sidebarLeft && !isWindowWidthSmallerThan('md')
              ? (leftSidebarOpen ? props.sidebarLeft.widthExpanded : props.sidebarLeft.widthCollapsed ?? 0) -
                (mainAreaIsOverflow ? 10 : 0)
              : 0
          }px`,
          paddingRight: `${
            props.sidebarRight && !isWindowWidthSmallerThan('md')
              ? (rightSidebarOpen ? props.sidebarRight.widthExpanded : props.sidebarRight.widthCollapsed ?? 0) -
                (mainAreaIsOverflow ? 10 : 0)
              : 0
          }px`,
        }}
      />
      <div className="md:flex md:flex-row">
        {props.sidebarLeft && (
          <aside
            className={classNames(
              'fixed left-0 top-[3.5rem] z-[99] w-full transition-[width] md:relative md:top-[4.5rem] md:h-[calc(100vh-9rem)] md:w-auto',
              SIDEBAR_ANIMATION_DURATION,
              {
                'h-[calc(100vh-6rem)] sm:h-[calc(100vh-7rem)]': leftSidebarOpen,
              }
            )}
            style={
              isWindowWidthSmallerThan('md')
                ? {}
                : {
                    width: leftSidebarOpen ? props.sidebarLeft.widthExpanded : props.sidebarLeft.widthCollapsed,
                  }
            }
          >
            {props.sidebarLeft.render((open: boolean) => setLeftSidebarOpen(open))}
          </aside>
        )}
        <main
          id={ID.main}
          ref={mainAreaRef}
          className={classNames(
            'page-template-main-content relative h-[100vh] grow overflow-y-auto pb-[2.5rem] sm:pb-[3.5rem] md:pb-[4.5rem] md:pt-[4.5rem]',
            { 'with-sidebar pt-[7rem]': props.sidebarRight || props.sidebarLeft },
            { 'pt-[3.5rem]': !props.sidebarRight && !props.sidebarLeft }
          )}
        >
          <div
            className={classNames(
              'sm:min-h-[calc(100vh-3.5rem)] md:min-h-[calc(100vh-9rem)]',
              { 'min-h-[calc(100vh-9.5rem)]': props.sidebarRight || props.sidebarLeft },
              { 'min-h-[calc(100vh-6rem)] sm:min-h-[calc(100vh-7rem)]': !props.sidebarRight && !props.sidebarLeft },
              {
                [`transition-transform ${SIDEBAR_ANIMATION_DURATION}`]: !props.isLoading,
              },
              { 'flex w-full items-center justify-center': props.centerChildren }
            )}
            style={
              !sidebarIsPresent || isWindowWidthSmallerThan('md')
                ? {}
                : {
                    width: windowWidth - sidebarWidthExpanded - (mainAreaIsOverflow ? 10 : 0),
                    transform: `translateX(${
                      !sidebarIsOpen ? (sidebarWidthExpanded - sidebarWidthCollapsed) / 2 : 0
                    }px)`,
                  }
            }
          >
            {props.children}
          </div>
          {props.scrollToTopButton && (
            <div className="sticky bottom-6 z-[98] flex h-0 flex-row justify-end overflow-visible pr-6">
              <IconButton
                className={classNames(
                  'h-10 w-10 -translate-y-10 rounded-full border-2 border-amber-500 bg-white hover:opacity-60 dark:border-apedao-black-800 dark:bg-amber-500 dark:shadow-none dark:hover:opacity-80',
                  {
                    'opacity-100': scrollToTopHook.scrollbarVisible && !scrollToTopHook.isAtTop,
                  },
                  {
                    'pointer-events-none !opacity-0 focus:!opacity-0 active:!opacity-0':
                      !scrollToTopHook.scrollbarVisible || scrollToTopHook.isAtTop,
                  }
                )}
                onClick={scrollToTopHook.scrollToTop}
              >
                <ChevronDoubleUpIcon className="h-8 w-8 text-amber-500 dark:text-apedao-black-800" />
              </IconButton>
            </div>
          )}
        </main>
        {props.sidebarRight && (
          <aside
            className={classNames(
              'fixed right-0 top-[3.5rem] z-[99] w-full transition-[width] md:relative md:top-[4.5rem] md:h-[calc(100vh-9rem)] md:w-auto',
              SIDEBAR_ANIMATION_DURATION,
              {
                'h-[calc(100vh-6rem)] sm:h-[calc(100vh-7rem)]': rightSidebarOpen,
              }
            )}
            style={
              isWindowWidthSmallerThan('md')
                ? {}
                : {
                    width: rightSidebarOpen ? props.sidebarRight.widthExpanded : props.sidebarRight.widthCollapsed,
                  }
            }
          >
            {props.sidebarRight.render((open: boolean) => setRightSidebarOpen(open))}
          </aside>
        )}
      </div>
      <Footer className="fixed bottom-0 z-[100] h-[2.5rem] w-full sm:h-[3.5rem] md:h-[4.5rem]" />
    </>
  );
}

export default PageTemplate;
