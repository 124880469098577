import React from 'react';
import { IWalletInfo } from 'types';
import { WalletInfoContext } from './WalletInfoProvider';

const useWalletInfo = (): IWalletInfo => {
  const walletInfo = React.useContext(WalletInfoContext);
  return walletInfo.state;
};

export default useWalletInfo;
