import { Card, CardBody, Typography } from '@material-tailwind/react';
import React from 'react';

function StatsCard(props: { title: string; error?: boolean; children: React.ReactNode }) {
  return (
    <Card className="min-w-[90vw] flex-grow sm:min-w-[18rem]">
      <CardBody className="flex h-full flex-col p-4 md:p-5">
        <div className="pb-1">
          <div className="flex flex-row justify-between">
            <Typography variant="h6">{props.title}</Typography>
            {props.error && <span className="text-red-500">failed</span>}
          </div>
          <hr className="my-1" />
        </div>
        <div className="h-full text-sm sm:text-base">{props.children}</div>
      </CardBody>
    </Card>
  );
}

export default StatsCard;
