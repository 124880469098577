import { useWalletInfo } from 'contexts/WalletInfo';
import { msg } from 'helpers';
import { groupCartItemsByCollectionId } from 'helpers/nftHelpers';
import { useOnCheckout } from 'pages/VaultsAndWallet/hooks';
import { ICartItem, ICollection } from 'types';
import { waitForTransactionReceipt } from 'viem/actions';
import { useClient, useContractWrite } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

export function useSell(getCurrentWalletNftIds: (collection: ICollection) => Promise<BigInt[]>) {
  const walletInfo = useWalletInfo();
  const client = useClient({ chainId: mainChain.id });
  const { writeContractAsync } = useContractWrite();

  const doSell = async (cartItems: ICartItem[]) => {
    const nftQuantitiesPerCollectionId = groupCartItemsByCollectionId(cartItems);

    const missingApprovalForAtLeastOneCollection = [...nftQuantitiesPerCollectionId.keys()].some(
      (collectionId) => !walletInfo.collectionApprovals.approvals.get(collectionId)
    );

    if (missingApprovalForAtLeastOneCollection) {
      msg.error(`You have to give ApeDAO vault access to your NFTs first!`);
      return false;
    } else {
      const firstNftsParam = (nftQuantitiesPerCollectionId.get(0) ?? []).map((cartItem) =>
        BigInt(cartItem.nft.edition)
      );
      const secondNftsParam = (nftQuantitiesPerCollectionId.get(1) ?? []).map((cartItem) =>
        BigInt(cartItem.nft.edition)
      );

      const hash = await writeContractAsync({
        ...contracts.apeDaoVault,
        functionName: 'mint',

        args: [firstNftsParam, secondNftsParam],
      });
      client && (await waitForTransactionReceipt(client, { hash }));
      return hash;
    }
  };

  const getSuccessMessage = (cartItems: ICartItem[]) =>
    `${cartItems.length} NFT${cartItems.length > 1 ? 's' : ''} successfully sold to ApeDAO vault`;

  const getErrorMessage = (cartItems: ICartItem[]) => `Selling NFT${cartItems.length > 1 ? 's' : ''} failed`;

  return useOnCheckout(doSell, getCurrentWalletNftIds, getSuccessMessage, getErrorMessage);
}
