import { COLLECTIONS } from 'helpers/useNftCollections';
import { Client } from 'types';
import { Address, encodePacked } from 'viem';
import { readContract, simulateContract, writeContract } from 'viem/actions';
import onft721ApeStyleAbi from 'web3/abi/ONFT721ApeStyleAbi';
import proxyONFT721Abi from 'web3/abi/ProxyONFT721Abi';
import { EvmChainName } from 'web3/chainsAndWallets';

async function getBatchLimit(client: Client, chainName: EvmChainName, collectionId: number, toLzChainId: number) {
  const contract = COLLECTIONS.get(collectionId)!.contracts[chainName]!;
  const batchLimit = contract?.lzProxyAddress
    ? await readContract(client, {
        address: contract.lzProxyAddress,
        abi: proxyONFT721Abi,
        functionName: 'dstChainIdToBatchLimit',
        args: [toLzChainId],
      })
    : await readContract(client, {
        address: contract.address,
        abi: onft721ApeStyleAbi,
        functionName: 'dstChainIdToBatchLimit',
        args: [toLzChainId],
      });

  return Number(batchLimit);
}

async function estimateSendFee(
  client: Client,
  chainName: EvmChainName,
  nftIds: string[],
  walletAddress: Address,
  toLzChainId: number
) {
  const collectionId = Number(nftIds[0].split('-')[0]);
  const contract = COLLECTIONS.get(collectionId)!.contracts[chainName]!;

  const estimatedFee = contract?.lzProxyAddress
    ? await readContract(client, {
        address: contract.lzProxyAddress,
        abi: proxyONFT721Abi,
        functionName: 'estimateSendBatchFee',
        args: [
          toLzChainId,
          walletAddress,
          nftIds.map((nft) => BigInt(Number(nftIds[0].split('-')[1]))),
          false,
          encodePacked(['uint16', 'uint256'], [1, 300000n]),
        ],
      })
    : await readContract(client, {
        address: contract.address,
        abi: onft721ApeStyleAbi,
        functionName: 'estimateSendBatchFee',
        args: [
          toLzChainId,
          walletAddress,
          nftIds.map((nft) => BigInt(Number(nftIds[0].split('-')[1]))),
          false,
          encodePacked(['uint16', 'uint256'], [1, 300000n]),
        ],
      });

  const bigNumberValue = (estimatedFee[0] * 5n) / 4n;
  const stringValue = bigNumberValue.toString();
  const firstSixDigits = stringValue.slice(0, 6);

  return BigInt(firstSixDigits + '0'.repeat(stringValue.length - 6));
}

async function sendNfts(
  client: Client,
  chainName: EvmChainName,
  nftIds: string[],
  walletAddress: Address,
  toLzChainId: number
): Promise<`0x${string}`> {
  const collectionId = Number(nftIds[0].split('-')[0]);
  const contract = COLLECTIONS.get(collectionId)!.contracts[chainName]!;
  const estimatedFee = await estimateSendFee(client, chainName, nftIds, walletAddress, toLzChainId);

  const { request } = contract?.lzProxyAddress
    ? await simulateContract(client, {
        address: contract.lzProxyAddress!,
        abi: proxyONFT721Abi,
        functionName: 'sendBatchFrom',
        args: [
          walletAddress,
          toLzChainId,
          walletAddress,
          nftIds.map((nft) => BigInt(Number(nftIds[0].split('-')[1]))),
          walletAddress,
          '0x0000000000000000000000000000000000000000' as Address,
          encodePacked(['uint16', 'uint256'], [1, 300000n]),
        ],
        account: walletAddress,
        value: estimatedFee,
      })
    : await simulateContract(client, {
        address: contract.address,
        abi: proxyONFT721Abi,
        functionName: 'sendBatchFrom',
        args: [
          walletAddress,
          toLzChainId,
          walletAddress,
          nftIds.map((nft) => BigInt(Number(nftIds[0].split('-')[1]))),
          walletAddress,
          '0x0000000000000000000000000000000000000000' as Address,
          encodePacked(['uint16', 'uint256'], [1, 300000n]),
        ],
        account: walletAddress,
        value: estimatedFee,
      });

  return await writeContract(client, request);
}

const layerZeroHelpers = { getBatchLimit, estimateSendFee, sendNfts };
export default layerZeroHelpers;
