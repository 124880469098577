import { Bars3Icon, MoonIcon, SunIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar,
  Typography,
} from '@material-tailwind/react';
import { ReactComponent as MetamaskFoxImage } from 'assets/images/metamask-fox.svg';
import classNames from 'classnames';
import { ApeDaoIcon, ConnectButton } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import 'components/Header/Header.css';
import { SIDEBAR_ANIMATION_DURATION } from 'components/Sidebar/Sidebar';
import { useChainName, useDarkMode, useMetaMask } from 'helpers';
import { formatNumber } from 'helpers/formatHelpers';
import { useIds } from 'helpers/idHelpers';
import { Breakpoint } from 'helpers/useWindowSize';
import { routes } from 'pages';
import { CSSProperties, MouseEvent } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IAssetBalance, IWalletInfo } from 'types';
import getIds from './getIds';
import useHeader from './hooks/useHeader';

export interface IHeaderProps extends IBaseComponentProps {
  walletInfo?: IWalletInfo;
  className?: string;
  blurred?: boolean;
  displayBadgeOnSandwichIcon?: boolean;
  isDevMode: boolean;
  toggleDevMode: () => void;
  navItemContainerStyle?: CSSProperties;
  isWindowWidthSmallerThan: (breakpoint: Breakpoint | number) => boolean;
}

function Header(props: IHeaderProps) {
  const ID = useIds(props.id, getIds);
  const { mobileNavOpen, setMobileNavOpen, badge } = useHeader(props);
  const { addVaultTokenToWallet } = useMetaMask();
  const { darkMode, toggleDarkMode } = useDarkMode();
  const chainName = useChainName();

  const renderNavItem = (id: string, label: string, toRoute: string, openInNewTab: boolean, onClick: () => void) => {
    return (
      <Typography key={id} as="li" className="whitespace-nowrap text-sm xl:text-base">
        <NavLink
          id={(ID as any)[`navItem${id.charAt(0).toUpperCase()}${id.slice(1)}`]}
          className="flex items-center font-semibold uppercase hover:underline focus:underline"
          to={toRoute}
          target={openInNewTab ? '_blank' : undefined}
          onClick={onClick}
        >
          {label}
        </NavLink>
      </Typography>
    );
  };

  const renderBalance = (id: string, balance: IAssetBalance) => {
    return (
      <div id={id} key={id} className="flex flex-row justify-between gap-2">
        <div className="font-bold">{balance.symbol}</div>
        <div className="ml-2 flex-1 text-right">{formatNumber(balance.value ?? 0, balance.decimals, 2)}</div>
      </div>
    );
  };

  const renderBalances = (nativeBalance?: IAssetBalance, apeInBalance?: IAssetBalance) => {
    if (!nativeBalance) {
      return null;
    }

    const innerBalances = (
      <div className="flex flex-col font-mono text-xs">
        {apeInBalance && renderBalance(ID.balanceApeIn, apeInBalance)}
        {renderBalance(ID.balanceIota, nativeBalance)}
      </div>
    );

    return apeInBalance ? (
      <Button
        size="sm"
        className="relative flex h-full flex-row items-center justify-center gap-3 !overflow-visible rounded-lg border border-apedao-black-200 bg-transparent py-1 text-inherit !shadow-none dark:border-apedao-black-500"
        onClick={addVaultTokenToWallet}
      >
        {innerBalances}
        {props.walletInfo?.apeInBalance && <MetamaskFoxImage className="absolute -right-2 -top-2 h-4 w-4" />}
      </Button>
    ) : (
      <div
        className={classNames(
          'relative flex h-full flex-row items-center justify-center gap-3 !overflow-visible rounded-lg border border-apedao-black-200 bg-transparent py-1 text-inherit !shadow-none dark:border-apedao-black-500',
          'px-4'
        )}
      >
        <div className="flex flex-col font-mono text-xs">{innerBalances}</div>
      </div>
    );
  };

  return (
    <Navbar
      className={classNames(
        'header-nav max-w-full rounded-none border-none py-4 text-apedao-black-950 dark:border-b-apedao-black-500 dark:bg-apedao-black-800 dark:text-apedao-black-100 dark:shadow-none dark:backdrop-blur-2xl md:dark:bg-apedao-black-800/60',
        'px-3 sm:px-4 xl:px-0',
        props.className
      )}
      blurred={props.blurred ?? true}
    >
      <div className="flex items-center justify-between gap-3 md:gap-5">
        <Link id={ID.logo} to="/" onClick={() => setMobileNavOpen(false)} className="mr-auto xl:px-4">
          <div className="flex items-center gap-x-2 text-xl sm:text-2xl lg:text-3xl">
            <ApeDaoIcon
              className="h-[1.3em] w-[1.3em]"
              onClick={(event: MouseEvent) => event.detail === 3 && props.toggleDevMode()}
            />
            <div className={classNames('font-bold', { 'text-red-500': props.isDevMode })}>
              {props.isDevMode ? 'ApeDEV' : 'ApeDAO'}
            </div>
          </div>
        </Link>
        <div
          id={ID.navItems}
          className={classNames(
            'pointer-events-none hidden grow justify-start xl:flex',
            {
              'absolute ml-auto mr-auto w-full justify-center': !props.isWindowWidthSmallerThan(1760),
            },
            SIDEBAR_ANIMATION_DURATION
          )}
          style={!props.isWindowWidthSmallerThan(1760) ? props.navItemContainerStyle : {}}
        >
          <ul className="pointer-events-auto mt-4 flex flex-col gap-2 md:mt-0 md:flex-row md:items-center md:gap-4 lg:gap-6">
            {routes
              .filter(
                (route) =>
                  !route.excludeFromNav &&
                  (!route.authRequired || props.walletInfo?.connected) &&
                  (!route.onlyAvailableOnChains || route.onlyAvailableOnChains.includes(chainName))
              )
              .map((route) =>
                renderNavItem(route.id, route.label, route.path, route.openInNewTab ?? false, () =>
                  setMobileNavOpen(false)
                )
              )}
          </ul>
        </div>
        {renderBalances(props.walletInfo?.nativeBalance, props.walletInfo?.apeInBalance)}
        <div
          className={classNames('flex-row justify-items-end whitespace-nowrap text-sm md:flex md:grow-0', {
            hidden: props.walletInfo?.connected,
          })}
        >
          <ConnectButton id={ID.connectBtn} showChain={true} badge={badge} />
        </div>
        <div className="hidden w-12 text-center xl:block">
          <IconButton
            ripple={false}
            variant="text"
            className={classNames('w-8 border-amber-500 text-apedao-black-950 md:block')}
            onClick={toggleDarkMode}
          >
            {darkMode && <SunIcon className="h-6 w-6" />}
            {!darkMode && <MoonIcon className="h-6 w-6" />}
          </IconButton>
        </div>
        <Menu placement="bottom-end">
          <MenuHandler>
            <IconButton
              variant="text"
              className="relative h-8 w-8 bg-transparent xl:hidden"
              ripple={false}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              {mobileNavOpen ? (
                <XMarkIcon strokeWidth={2} className="h-8 w-8 text-apedao-black-950 dark:text-apedao-black-100" />
              ) : (
                <Bars3Icon strokeWidth={2} className="h-8 w-8 text-apedao-black-950 dark:text-apedao-black-100" />
              )}
              {props.displayBadgeOnSandwichIcon && (
                <div className="absolute -right-1 top-0 block h-2.5 w-2.5 rounded-full bg-red-500 md:hidden" />
              )}
            </IconButton>
          </MenuHandler>
          <MenuList className={classNames({ dark: darkMode })}>
            {routes
              .filter((route) => !route.excludeFromNav && (!route.authRequired || props.walletInfo?.connected))
              .map((route) => {
                return (
                  <NavLink to={route.path} key={route.id}>
                    <MenuItem>{route.label}</MenuItem>
                  </NavLink>
                );
              })}
            <hr className="my-3 dark:border-apedao-black-300" />
            <MenuItem className="pr-1 md:mb-0" onClick={toggleDarkMode}>
              <div className="flex w-full flex-row items-center justify-between">
                {darkMode ? 'Light' : 'Dark'} mode {darkMode && <SunIcon className="h-6 w-6" />}
                {!darkMode && <MoonIcon className="h-6 w-6" />}
              </div>
            </MenuItem>
            {props.walletInfo?.connected && (
              <div className="mt-2 md:hidden">
                <ConnectButton id={ID.menuConnectBtn} showChain={true} badge={badge} />
              </div>
            )}
          </MenuList>
        </Menu>
      </div>
    </Navbar>
  );
}

export default Header;
