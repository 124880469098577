import { WalletIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@material-tailwind/react';
import { ReactComponent as EpochZero } from 'assets/images/epochzero.svg';
import { ReactComponent as MagicSeaOctopus } from 'assets/images/magicsea_octopus.svg';
import classNames from 'classnames';
import { ApeDaoIcon } from 'components';
import React from 'react';
import { ILocationFilter, INftFilters } from 'types';

export interface INftFilterLocationProps {
  filters: INftFilters;
}

function NftFilterLocation(props: INftFilterLocationProps) {
  return (
    <div className="flex w-full flex-row justify-center">
      <div className="grid grid-cols-6 justify-center gap-2.5">
        <NftFilterLocationButton filters={props.filters} filterField="wallet">
          <WalletIcon className="h-full w-full text-apedao-black-900" strokeWidth={1.5} />
          <StarIcon className="absolute -right-0.5 bottom-0 h-[55%] w-[55%] text-apedao-black-900" />
        </NftFilterLocationButton>
        <NftFilterLocationButton filters={props.filters} filterField="foreignWallet">
          <WalletIcon className="h-full w-full text-apedao-black-900" strokeWidth={1.5} />
        </NftFilterLocationButton>
        <NftFilterLocationButton filters={props.filters} filterField="vault">
          <ApeDaoIcon className="h-full w-full scale-[150%] fill-apedao-black-900" noBackground={true} />
        </NftFilterLocationButton>
        <NftFilterLocationButton filters={props.filters} filterField="magicSea">
          <MagicSeaOctopus className="h-full w-full fill-apedao-black-900" />
        </NftFilterLocationButton>
        <NftFilterLocationButton filters={props.filters} filterField="epochZero">
          <EpochZero className="h-full w-full fill-apedao-black-900" />
        </NftFilterLocationButton>
        <NftFilterLocationButton filters={props.filters} filterField="l1">
          <div className="flex h-full w-full items-center justify-center align-bottom text-xs font-semibold text-apedao-black-900">
            L1
          </div>
        </NftFilterLocationButton>
      </div>
    </div>
  );
}

function NftFilterLocationButton(props: {
  filters: INftFilters;
  filterField: keyof ILocationFilter;
  children: React.ReactNode;
}) {
  return (
    <IconButton
      variant="filled"
      color="amber"
      className={classNames('h-8 w-8 rounded-full shadow-none transition-opacity hover:shadow-none ', {
        'opacity-30 saturate-0 focus:opacity-30': !props.filters.locationFilter[props.filterField],
      })}
      onClick={() =>
        props.filters.onLocationFilterChange({
          ...props.filters.locationFilter,
          [props.filterField]: !props.filters.locationFilter[props.filterField],
        })
      }
    >
      {props.children}
    </IconButton>
  );
}

export default NftFilterLocation;
