import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';

export interface IChipProps {
  className?: string;
  label: string;
  onClickXIcon?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Chip = (props: IChipProps) => {
  return (
    <div
      className={classNames(
        'h-5 w-fit cursor-default whitespace-nowrap rounded-full border border-apedao-black-100 bg-apedao-black-50 px-2 text-[0.6rem] font-bold text-apedao-black-800 dark:border-apedao-black-500 dark:bg-transparent dark:font-semibold dark:text-apedao-black-300',
        props.className
      )}
    >
      <div className="flex h-5 flex-row items-center justify-center gap-1">
        {props.label}
        {props.onClickXIcon && (
          <div role="button" onClick={props.onClickXIcon}>
            <XMarkIcon className="h-3 w-2 hover:scale-125" viewBox="6 0 12 24" strokeWidth={2} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Chip;
