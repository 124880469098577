import { INumberInputProps } from 'components/NumberInput/NumberInput';
import React from 'react';

function useNumberInput(props: INumberInputProps) {
  const [value, setValue] = React.useState<number | null>(() => props.value ?? 0);

  React.useEffect(() => {
    if (props.visible == null || props.visible) {
      setValue(props.value ?? 0);
    }
  }, [props.value, props.visible]);

  React.useEffect(() => {
    if (props.minValue != null && value != null && value < props.minValue) {
      setValue(props.minValue);
    }

    if (props.maxValue != null && value != null && value > props.maxValue) {
      setValue(props.maxValue);
    }
  }, [props.minValue, props.maxValue, value]);

  const decrease = () => {
    if (value === null) {
      return;
    } else if (props.minValue == null || value > props.minValue) {
      let newValue = value - 1;
      setValue(newValue);
      props.onChange && props.onChange(newValue);
    }
  };

  const increase = () => {
    if (value === null) {
      return;
    } else if (props.maxValue == null || value < props.maxValue) {
      let newValue = value + 1;
      setValue(newValue);
      props.onChange && props.onChange(newValue);
    }
  };

  const setValueSafe = (newValue: number | null) => {
    let safeNewValue = newValue;
    if (newValue !== null) {
      if (props.maxValue && newValue > props.maxValue) {
        safeNewValue = props.maxValue;
      } else if (props.minValue && newValue < props.minValue) {
        safeNewValue = props.minValue;
      }
    }

    setValue(safeNewValue);
    props.onChange && safeNewValue !== null && props.onChange(safeNewValue);
  };

  return {
    value,
    setValue: setValueSafe,
    increase,
    decrease,
  };
}

export default useNumberInput;
