import { useChainName } from 'helpers';
import { useEffect, useState } from 'react';
import { getChainByEvmChainName } from 'web3/chainsAndWallets';

function useApproveRevokeAccess() {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [explorerBaseUrl, setExplorerBaseUrl] = useState<string>();

  const chainName = useChainName();

  useEffect(() => {
    setExplorerBaseUrl(getChainByEvmChainName(chainName)?.blockExplorers?.default.url);
  }, [chainName]);

  return {
    popoverOpen,
    setPopoverOpen,
    isLoading,
    setIsLoading,
    explorerBaseUrl,
  };
}

export default useApproveRevokeAccess;
