import { Button, Option, Select } from '@material-tailwind/react';
import classNames from 'classnames';
import ApproveRevokeAccess from 'components/ApproveRevokeAccess';
import useBridgePanel from 'components/BridgePanel/hooks/useBridgePanel';
import { Spinner } from 'components/index';
import { useLayerZeroBridge, useWindowSize } from 'helpers';
import { formatNumber } from 'helpers/formatHelpers';
import React from 'react';
import { EvmChainName, evmChainNames, getChainByEvmChainName } from 'web3/chainsAndWallets';

export interface IBridgePanelProps {
  className?: string;
  lzBridgeHandler: ReturnType<typeof useLayerZeroBridge>;
  multiSelect?: boolean;
}

function BridgePanel(props: IBridgePanelProps) {
  const {
    approvalRequirementsAreMet,
    insufficientFunds,
    tooManyNftsSelected,
    nativeAssetParams,
    txInProgressForSelectedNfts,
  } = useBridgePanel(props);
  const { isWidthSmallerThan } = useWindowSize();

  const {
    supportedTargetChains,
    targetChain,
    setTargetChain,
    contractApprovals,
    needsContractApproval,
    estimatedFee,
    sendSelectedNfts,
    isSending,
    error,
  } = props.lzBridgeHandler;

  const txInProgress = isSending || txInProgressForSelectedNfts.length > 0;

  const chainDropDown = (
    <Select
      label="Target chain"
      variant="outlined"
      color="gray"
      value={targetChain}
      disabled={txInProgress}
      onChange={(newValue) => {
        setTargetChain(newValue as EvmChainName);
      }}
      selected={(element, index) => {
        return element ? React.cloneElement(element, { disabled: true }) : <div></div>;
      }}
    >
      {evmChainNames.map((chainName) => (
        <Option
          key={chainName}
          value={chainName}
          className={classNames('flex flex-row gap-2 pl-1', {
            hidden: !supportedTargetChains.includes(chainName as EvmChainName),
          })}
        >
          {getChainByEvmChainName(chainName)?.chainIconSrc && (
            <img
              alt={chainName}
              src={getChainByEvmChainName(chainName)?.chainIconSrc}
              className="h-5 w-5 rounded-full"
            />
          )}
          <span>{getChainByEvmChainName(chainName)?.name}</span>
        </Option>
      ))}
    </Select>
  );

  const approvalButton = needsContractApproval ? (
    <div className="h-8 w-8">
      <ApproveRevokeAccess
        collections={props.lzBridgeHandler.collection ? [props.lzBridgeHandler.collection] : []}
        collectionApprovals={contractApprovals}
        variant="dialog"
        operatorType="proxyONFT"
        disabled={txInProgress}
      />
    </div>
  ) : undefined;

  const sendButton = (
    <Button
      size="sm"
      variant="outlined"
      className={classNames('relative flex items-center justify-center gap-2 !overflow-visible whitespace-nowrap', {
        'w-28': props.multiSelect,
      })}
      color="amber"
      onClick={sendSelectedNfts}
      disabled={txInProgress || !approvalRequirementsAreMet || insufficientFunds || tooManyNftsSelected}
    >
      Send{props.multiSelect && ` (${props.lzBridgeHandler.selectedNftIds.length})`}
      {txInProgress && (
        <div className="absolute left-0 right-0 flex justify-center">
          <Spinner className="h-4 w-4 !border-white"></Spinner>
        </div>
      )}
    </Button>
  );

  const estimatedFeeDisplay = !txInProgress ? (
    <span className="flex grow flex-col gap-1 text-xs sm:flex-row">
      <span className="whitespace-nowrap">Estimated fee:</span>{' '}
      <span className="whitespace-nowrap">
        {estimatedFee
          ? `${formatNumber(estimatedFee, nativeAssetParams.decimals, 'auto')} ${nativeAssetParams.symbol}`
          : 'n/a'}
      </span>
    </span>
  ) : (
    <span></span>
  );

  const messageDisplay = (
    <span
      className={classNames('text-xs tracking-wider', {
        'font-semibold text-red-500 dark:font-semibold dark:text-red-500':
          error || insufficientFunds || !approvalRequirementsAreMet || tooManyNftsSelected,
      })}
    >
      {error && 'Error on bridging NFT. Please retry.'}
      {insufficientFunds && 'Insufficient funds to bridge the NFT'}
      {!approvalRequirementsAreMet && 'Please grant access to the collection'}
      {tooManyNftsSelected &&
        `Max ${props.lzBridgeHandler.batchLimit} NFT${props.lzBridgeHandler.batchLimit > 1 ? 's' : ''} can be bridged`}
    </span>
  );

  return (
    <>
      {isWidthSmallerThan('sm') && (
        <div className={classNames(props.className, 'flex flex-col items-end gap-2')}>
          {chainDropDown}
          <div className="flew-row mt-2 flex w-full items-center justify-end gap-3">
            {estimatedFeeDisplay}
            {approvalButton}
            {sendButton}
          </div>
          <div>{messageDisplay}</div>
        </div>
      )}
      {!isWidthSmallerThan('sm') && (
        <div className={classNames(props.className, 'flex flex-col gap-2')}>
          <div className="flex flex-row items-center gap-3">
            {chainDropDown}
            {approvalButton}
            {sendButton}
          </div>
          <div className="flex flex-row items-end justify-between gap-2 text-xs">
            {estimatedFeeDisplay}
            {messageDisplay}
          </div>
        </div>
      )}
    </>
  );
}

export default BridgePanel;
