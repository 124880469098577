import { usePrevious } from '@material-tailwind/react';
import { CART_ITEM_REMOVE_ANIMATION_DURATION } from 'components/CartItem/CartItem';
import { SIDEBAR_ANIMATION_DURATION } from 'components/Sidebar/Sidebar';
import { useWalletInfo } from 'contexts/WalletInfo';
import { msg, useChain, useLayerZeroBridge, useMetaMask, useNftCollections } from 'helpers';
import { groupCartItemsByCollectionId } from 'helpers/nftHelpers';
import { LOCAL_STORAGE_SIDEBAR_POSITION } from 'helpers/storageHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import { ICart, ICartItem, ICollection, ICollectionApprovals, INft, INftFilters, IWalletInfo } from 'types';
import { useLocalStorage } from 'usehooks-ts';
import { IVaultsAndWalletProps } from '../VaultsAndWallet';
import { useNftCollection } from './index';

export interface IUseVaultReturnType {
  walletInfo: IWalletInfo;
  collection: ICollection | undefined;
  isLoading: boolean;
  filters: INftFilters;
  vaultWalletNfts: INft[];
  filteredNfts: INft[];
  cart: ICart;
  collectionApprovals: ICollectionApprovals;
  nftInfo: {
    nft?: INft;
    onShowNftInfo: (nftId?: string) => void;
  };
  sidebar: {
    forceClose: boolean;
    onChangeOpenDecorator: (onChangeOpen: (open: boolean) => void) => (open: boolean) => void;
    position: 'left' | 'right';
    onPositionChange: (position: 'left' | 'right') => void;
    isOpen: boolean;
  };
  favorites: {
    ids: string[];
    setFavorite: (nftId: string, isFavorite: boolean) => void;
  };
  refreshVaultWalletNfts: (setLoading: boolean) => void;
  onCollectionIdChange: (collectionId: number) => void;
  onAddNftsToWallet: () => Promise<void>;
  layerZeroBridgeHandler: ReturnType<typeof useLayerZeroBridge>;
  bridgeSelectionMode: boolean;
  setBridgeSelectionMode: (selectionMode: boolean) => void;
}

const initialCollectionId = 0;
const cartItemRemoveAnimationDuration = Number(CART_ITEM_REMOVE_ANIMATION_DURATION.split('-')[1]);
const sidebarAnimationDuration = Number(SIDEBAR_ANIMATION_DURATION.split('-')[1]);

export interface IUseVaultsAndWalletProps extends IVaultsAndWalletProps {}

function useVaultsAndWallet(props: IUseVaultsAndWalletProps): IUseVaultReturnType {
  const walletInfo = useWalletInfo();
  const { chainName } = useChain();
  const { getCollection } = useNftCollections();
  const { collection, setId: setCollectionId } = useNftCollection(initialCollectionId, getCollection);
  const { addNftToWallet } = useMetaMask();

  const {
    nfts: vaultWalletNfts,
    filteredNfts,
    filters,
    isLoading: vaultWalletIsLoading,
    refresh: refreshVaultWalletNfts,
    getCurrentVaultWalletNftIds,
    favorites,
    setFavorite,
    layerZeroBridgeHandler,
  } = props.vaultWalletHook({ collection });

  const [cartItems, setCartItems] = React.useState<Map<string, ICartItem>>(new Map());
  const [maxRandomQuantityPerCollection, setMaxRandomQuantityPerCollection] = React.useState<Map<number, number>>(
    new Map()
  );

  const [infoDialogNft, setInfoDialogNft] = React.useState<INft>();
  const previousVaultWalletNfts = usePrevious(vaultWalletNfts);

  const [bridgeSelectionMode, setBridgeSelectionMode] = React.useState(false);

  const getCurrentVaultWalletNftIdsArray = useCallback(
    async (collection: ICollection): Promise<BigInt[]> => {
      const holdingsOnChain = await getCurrentVaultWalletNftIds(collection);
      return Array.from(holdingsOnChain.values()).flatMap((list) => list);
    },
    [getCurrentVaultWalletNftIds]
  );

  const [buyWithIota, setBuyWithIota] = useState<boolean>(false);
  const { onCheckout: onCheckoutCart, isLoading: checkoutInProgress } = props.checkoutCartHook(
    getCurrentVaultWalletNftIdsArray
  );

  const [forceCloseSidebar, setForceCloseSidebar] = React.useState<boolean>(false);
  const [sidebarPosition, setSidebarPosition] = useLocalStorage<'left' | 'right'>(
    LOCAL_STORAGE_SIDEBAR_POSITION,
    'left'
  );
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);

  const refreshAccountInfo = walletInfo.refresh;
  useEffect(() => {
    if (props.variant === 'wallet') {
      refreshAccountInfo();
    }
  }, [refreshAccountInfo, cartItems.size, props.variant]);

  const onChangeOpenSidebarDecorator = (onChangeOpen: (open: boolean) => void) => {
    return (open: boolean) => {
      setSidebarOpen(open);
      if (forceCloseSidebar && !open) {
        setForceCloseSidebar(false);
      }
      onChangeOpen(open);
    };
  };

  const onCollectionIdChange = (collectionId: number) => {
    filters.onNameFilterChange('');
    filters.onAttributeFiltersChange(new Map());
    setCollectionId(collectionId);
  };

  const clearCartItems = () => {
    setCartItems(new Map());
  };

  const setLayerZeroBridgeSelectedNftIds = layerZeroBridgeHandler.setSelectedNftIds;
  const onShowNftInfo = useCallback(
    async (nftId?: string) => {
      if (nftId) {
        const collectionId = Number(nftId.split('-')[0]);
        const collection = await getCollection(collectionId);
        const nft = vaultWalletNfts.find((nft) => nft.id === nftId) ?? collection?.nfts.find((nft) => nft.id === nftId);
        if (nft) {
          setInfoDialogNft(nft);
          setLayerZeroBridgeSelectedNftIds(nftId ? [nftId] : []);
        }
      } else {
        setInfoDialogNft(undefined);
      }
    },
    [getCollection, setLayerZeroBridgeSelectedNftIds, vaultWalletNfts]
  );

  const removeFromCart = (nft: INft) => {
    setCartItems((prevNftsInCart) => {
      const nftInCart = prevNftsInCart.get(nft.id);
      if (nftInCart?.toBeRemoved) {
        const newNftsInCart = new Map(prevNftsInCart);
        newNftsInCart.delete(nft.id);
        return newNftsInCart;
      } else {
        return prevNftsInCart;
      }
    });
  };

  const onCartQuantityChange = useCallback(
    (nft: INft, quantity: number) => {
      setCartItems((prevNftsInCart) => {
        const newNftsInCart = new Map(prevNftsInCart);
        if (quantity === 0) {
          const previousQuantity = prevNftsInCart.get(nft.id)?.quantity ?? 0;
          newNftsInCart.set(nft.id, { nft, quantity: previousQuantity, toBeRemoved: true });
          setTimeout(() => removeFromCart(nft), cartItemRemoveAnimationDuration);
        } else {
          newNftsInCart.set(nft.id, { nft, quantity });
        }

        // remove random from cart if all are selected
        if (props.variant === 'vault') {
          const nftQuantitiesByCollectionId = groupCartItemsByCollectionId([...newNftsInCart.values()]);

          const newMaxRandomQuantityPerCollection = new Map<number, number>();
          for (const [collectionId, nftQuantities] of nftQuantitiesByCollectionId.entries()) {
            const nonRandomNfts = nftQuantities
              .map((cartItem) => (cartItem.nft.type === 'nft' ? cartItem.quantity : 0))
              .reduce((a, b) => a + b, 0);

            newMaxRandomQuantityPerCollection.set(collectionId, vaultWalletNfts.length - 1 - nonRandomNfts);

            if (nonRandomNfts >= filteredNfts.length - 1 && newNftsInCart.has(`${collectionId}-random`)) {
              newNftsInCart.delete(`${collectionId}-random`);
              if (nft.type === 'random') {
                msg.warn('All NFTs are selected, random NFT selection is not possible.');
              }
            }
          }

          setMaxRandomQuantityPerCollection(newMaxRandomQuantityPerCollection);
        }

        return newNftsInCart;
      });
    },
    [filteredNfts.length, props.variant, vaultWalletNfts.length]
  );

  const onCartCheckout = async () => {
    let success = true;
    try {
      const checkoutCartResult = await onCheckoutCart(
        [...cartItems.values()],
        props.variant === 'vault' && buyWithIota
      );
      if (checkoutCartResult.success) {
        clearCartItems();
        setForceCloseSidebar(true);
      } else {
        success = false;
        setCartItems((prevNftsInCart) => {
          const newNftsInCart = new Map(prevNftsInCart);
          for (const nftWithErrorCause of checkoutCartResult.errors ?? []) {
            const cartItemWithError = newNftsInCart.get(nftWithErrorCause.nft.id);
            if (cartItemWithError) {
              newNftsInCart.set(nftWithErrorCause.nft.id, {
                ...cartItemWithError,
                checkoutErrorCause: nftWithErrorCause.cause,
              });
            }
          }
          return newNftsInCart;
        });
      }

      return checkoutCartResult;
    } finally {
      walletInfo.refresh();
      setTimeout(
        () => {
          refreshVaultWalletNfts(true);
        },
        success ? sidebarAnimationDuration + 200 : 0
      );
    }
  };

  const onAddNftsToWallet = async () => {
    const nftsToBeAdded = filteredNfts.filter(
      (nft) => nft.location.tag === 'wallet' && nft.location.chain === chainName
    );

    if (filteredNfts.length !== nftsToBeAdded.length) {
      msg.warn('Only NFTs from the current chain can be added to the wallet.');
    }

    await Promise.all(nftsToBeAdded.map((nft) => addNftToWallet(nft)));
  };

  useEffect(() => {
    // if dialog is still open while bridging and the selected nft arrives at another chain
    if (infoDialogNft && vaultWalletNfts !== previousVaultWalletNfts) {
      setInfoDialogNft(vaultWalletNfts.find((nft) => nft.id === infoDialogNft.id));
    }
  }, [getCollection, infoDialogNft, previousVaultWalletNfts, vaultWalletNfts]);

  const setLayerZeroSelectedNftIds = layerZeroBridgeHandler.setSelectedNftIds;
  useEffect(() => {
    if (!infoDialogNft && !bridgeSelectionMode) {
      setLayerZeroSelectedNftIds([]);
    }
  }, [bridgeSelectionMode, infoDialogNft, setLayerZeroSelectedNftIds]);

  return {
    walletInfo: walletInfo,
    collection,
    isLoading: vaultWalletIsLoading || collection == null,
    vaultWalletNfts,
    filteredNfts,
    filters,
    cart: {
      mode: props.variant === 'vault' ? 'buy' : 'sell',
      buyWithIota: props.variant === 'vault' && buyWithIota,
      items: cartItems,
      maxRandomQuantityPerCollection,
      checkoutInProgress: checkoutInProgress,
      onChangeBuyWithIota: setBuyWithIota,
      onQuantityChange: onCartQuantityChange,
      onResetCart: () => clearCartItems(),
      onCheckout: onCartCheckout,
    },
    collectionApprovals: walletInfo.collectionApprovals,
    nftInfo: {
      nft: infoDialogNft,
      onShowNftInfo: onShowNftInfo,
    },
    sidebar: {
      forceClose: forceCloseSidebar,
      onChangeOpenDecorator: onChangeOpenSidebarDecorator,
      position: sidebarPosition,
      onPositionChange: setSidebarPosition,
      isOpen: sidebarOpen,
    },
    favorites: {
      ids: favorites,
      setFavorite,
    },
    refreshVaultWalletNfts,
    onCollectionIdChange,
    onAddNftsToWallet,
    layerZeroBridgeHandler,
    bridgeSelectionMode,
    setBridgeSelectionMode,
  };
}

export default useVaultsAndWallet;
