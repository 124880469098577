import { ThemeProvider } from '@material-tailwind/react';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import classNames from 'classnames';
import { Avatar } from 'components';
import { WalletInfoProvider, useWalletInfo } from 'contexts/WalletInfo';
import { useChainName, useDarkMode } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import { IRoute, routes } from 'pages/routes';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { themes } from 'theme';
import { mainChain } from 'web3/chainsAndWallets';
import './App.css';
import getIds, { appRootId } from './getIds';

function App() {
  const ID = useIds(appRootId, getIds);
  const { darkMode } = useDarkMode();
  const walletInfo = useWalletInfo();
  const chainName = useChainName();
  const theme = darkMode ? themes.dark : themes.light;

  const renderRoute = (route: IRoute) => {
    let element;
    if (route.authRequired && walletInfo.connected) {
      element = <Navigate to="/" replace />;
    } else if (!route.createPageElement) {
      element = <Navigate to="/" replace />;
    } else if (route.onlyAvailableOnChains && !route.onlyAvailableOnChains.includes(chainName)) {
      element = <Navigate to="/" replace />;
    } else {
      element = route.createPageElement(ID[route.id]);
    }

    return <Route key={route.label} path={route.path} element={element} />;
  };

  return (
    <ThemeProvider value={theme}>
      <RainbowKitProvider initialChain={mainChain} theme={theme.rainbowkit} modalSize="compact" avatar={Avatar}>
        <HashRouter>
          <div
            className={classNames('h-[100vh] overflow-hidden', {
              'bg-apedao-black-950 text-apedao-black-100 dark:text-apedao-black-100': darkMode,
            })}
          >
            <WalletInfoProvider refreshIntervalMs={8000}>
              <Routes>
                {routes.map((route) => renderRoute(route))}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </WalletInfoProvider>
          </div>
        </HashRouter>
      </RainbowKitProvider>
    </ThemeProvider>
  );
}

export default App;
