import Big from 'big.js';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import { useCallback, useEffect, useState } from 'react';
import { IBaseCollection, IContract } from 'types';
import { Address } from 'viem';
import { readContract } from 'viem/actions';
import epochZeroNFTMarketAbi from 'web3/abi/EpochZeroNFTMarketAbi';
import { EvmChainName, getClient } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

export interface ICollectionMarketListing {
  collectionId: number;
  nftEdition: number;
  currentAskPrice: Big;
  currentSeller: Address;
}

const useEpochZeroNftMarket = (props?: { fetchOnInit?: boolean }) => {
  const [nftsOnMarket, setNftsOnMarket] = useState<Map<number, ICollectionMarketListing[]>>();

  const getNftsOnMarket = useCallback(async (collection: IBaseCollection): Promise<ICollectionMarketListing[]> => {
    if (!collection.contracts[collection.chain]) {
      return [];
    }

    const client = getClient(collection.chain);
    const data = await readContract(client, {
      ...(contracts.epochZeroNFTMarket as Record<EvmChainName, IContract<typeof epochZeroNFTMarketAbi>>)[
        collection.chain
      ],
      functionName: 'viewAsksByCollection',
      args: [collection.contracts[collection.chain]!.address, 0n, BigInt(collection.totalNumber)],
    });

    return (data[0] ?? []).map((tokenId, idx) => ({
      collectionId: collection.id,
      nftEdition: Number(tokenId),
      currentAskPrice: Big(data[1][idx].price.toString()).div(Big(10).pow(18)),
      currentSeller: data[1][idx].seller,
    }));
  }, []);

  useEffect(() => {
    if ((props?.fetchOnInit ?? true) && nftsOnMarket == null) {
      Promise.all(Array.from(ACTIVE_COLLECTIONS.values()).map(getNftsOnMarket)).then((results) => {
        const nftsOnMarket = new Map<number, ICollectionMarketListing[]>();
        results.forEach((nfts, index) => {
          const collectionId = [...ACTIVE_COLLECTIONS.values()][index].id;
          nftsOnMarket.set(collectionId, nfts);
        });
        setNftsOnMarket(nftsOnMarket);
      });
    }
  }, [getNftsOnMarket, nftsOnMarket, props?.fetchOnInit]);

  return {
    nftsOnMarket,
    getNftsOnMarket,
  };
};

export default useEpochZeroNftMarket;
