import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Button, ButtonGroup, Tooltip } from '@material-tailwind/react';
import { ConnectButton as ConnectButtonRK } from '@rainbow-me/rainbowkit';
import classNames from 'classnames';
import { Image } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useChainName } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import { Badge } from 'types';
import { useChains } from 'wagmi';
import { supportedChainsByName } from 'web3/chainsAndWallets';
import getIds from './getIds';

const widerBadges: Badge[] = ['goldApeDAOSupporter', 'silverApeDAOSupporter'];

export interface IConnectButtonProps extends IBaseComponentProps {
  showChain?: boolean;
  badge?: Badge;
}

const ConnectButton = (props: IConnectButtonProps) => {
  const ID = useIds(props.id, getIds);
  const chains = useChains();
  const chainName = useChainName();

  return (
    <ConnectButtonRK.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

        if (!connected) {
          return (
            <Button id={ID.accountBtn} onClick={openConnectModal} className="w-full px-3 md:px-6">
              Connect Wallet
            </Button>
          );
        }

        if (chain.unsupported) {
          return (
            <Button id={ID.accountBtn} onClick={openChainModal} className="w-full px-3 md:px-6">
              Wrong network
            </Button>
          );
        }

        const accountButton = (
          <Button
            id={ID.accountBtn}
            onClick={openAccountModal}
            className={classNames(
              'relative flex grow flex-row items-center justify-center gap-2 overflow-y-visible whitespace-nowrap px-3 font-semibold tracking-wider text-apedao-black-950 dark:border-opacity-50 dark:text-apedao-black-100 md:px-6'
            )}
            style={{ overflow: 'visible' }}
            variant="outlined"
          >
            <span className={classNames({ '-translate-x-1.5': props.badge && widerBadges.includes(props.badge) })}>
              {account.displayName}
            </span>
            <ChevronDownIcon
              strokeWidth={2}
              className={classNames('h-4 w-4 translate-x-0.5 text-apedao-black-950 dark:text-apedao-black-100', {
                invisible: props.badge,
              })}
            />
            <img
              src={`/images/${props.badge}Badge.webp`}
              className={classNames('absolute right-1 h-11', { hidden: !props.badge })}
              alt="Bronze ApeDAO Supporter"
            />
          </Button>
        );

        return (
          <div className="relative flex">
            {props.showChain && chains.length > 1 ? (
              <ButtonGroup variant="outlined">
                {accountButton}
                <Button
                  id={ID.chainBtn}
                  onClick={openChainModal}
                  variant="outlined"
                  className="px-2 text-apedao-black-950 dark:border-opacity-50 dark:text-apedao-black-100"
                >
                  {/*<LinkIcon className="h-4 w-4"></LinkIcon>*/}
                  <div className="h-4 w-4">
                    <Image
                      alt={chain.name}
                      src={supportedChainsByName[chainName]!.chainIconSrc}
                      className="rounded-full"
                    />
                  </div>
                </Button>
              </ButtonGroup>
            ) : (
              <>{accountButton}</>
            )}
            <Tooltip content="Testnet">
              <div
                className={classNames(
                  'absolute -right-2 -top-2 flex h-4 w-4 items-center justify-center rounded-full border border-red-500 bg-white text-[0.75em] font-medium text-red-500',
                  'dark:border-opacity-50 dark:bg-apedao-black-800 dark:font-semibold dark:text-red-700',
                  { hidden: !chains.find((c) => c.id === chain.id)?.testnet }
                )}
              >
                T
              </div>
            </Tooltip>
          </div>
        );
      }}
    </ConnectButtonRK.Custom>
  );
};

export default ConnectButton;
