import { MouseEvent } from 'react';

function ApeDaoIcon(props: { className?: string; noBackground?: boolean; onClick?: (event: MouseEvent) => void }) {
  return (
    <svg
      preserveAspectRatio="xMidYMid"
      version="1.0"
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={props.className}
    >
      {!props.noBackground && <circle cx="128" cy="128" r="128" fill="#f0c73a" />}
      <g transform="matrix(.1 0 0 -.1 0 256)">
        <path d="m1329 2307c-146-50-235-151-284-322-10-33-19-61-20-63-2-2-31-10-64-18-112-26-208-91-247-167-22-42-30-151-14-202 7-22 15-87 19-145 8-114 25-160 81-224 26-29 30-42 30-89 0-73 31-155 76-201 19-20 38-36 42-36s18-21 31-46c26-48 63-74 120-83 37-7 58-29 48-55-8-20-56-21-109-2-21 7-40 11-43 8s-5-24-5-47c0-50 28-91 76-111 47-20 322-19 369 0 74 31 85 142 24 242-15 24-20 56-23 125-4 100-27 181-72 249-21 32-22 40-14 100 16 114-15 232-87 327-29 38-24 73 11 73 15 0 33-14 53-42 74-104 96-175 95-312-1-89 2-104 27-155 16-31 34-81 41-111s14-61 16-68c5-19 83 39 154 115 128 137 134 286 20 514-33 65-37 79-26 94 25 34 49 13 97-82 61-121 83-210 77-316-6-108-37-177-119-262-60-63-61-66-55-106 15-93 71-157 159-180 50-14 68-39 46-65-14-17-50-12-116 16-36 16-42 16-47 3-12-31-5-86 14-113 37-51 72-60 245-60 171 0 197 6 234 59 22 31 29 105 11 116-5 3-10 14-10 24s-13 36-28 57c-16 22-35 64-42 94s-24 80-38 110c-21 46-26 71-27 155-1 111-21 193-63 265-22 37-26 55-23 101 1 31-3 76-10 100-17 57-97 138-162 165-47 18-48 20-43 54 3 19-1 60-8 90s-11 80-9 110c4 44 0 67-21 115-37 81-99 161-154 196-59 37-136 39-233 6zm283-354c47-3 79-11 82-18 3-8-2-11-15-8-13 4-19 0-19-12 0-14-3-15-15-5-11 9-18 10-29 1-10-9-15-8-20 4-6 16-56 21-56 5 0-5 9-19 21-31 14-14 26-18 42-13 12 4 34 7 49 6s30 3 34 10c11 17 22-18 23-72 1-25-6-55-14-67-21-30-78-63-109-63-35 0-67 51-64 101 2 29-5 45-30 73-18 20-32 48-32 61 0 28 26 47 55 40 11-3 55-8 97-12z" />
        <path d="m1604 1848c-17-27 54-40 76-13 14 17 16 16-33 19-21 2-40-1-43-6z" />
        <path d="m1605 1796c-39-17-57-39-49-60 9-23 19-20 35 11 8 14 32 34 54 44 34 16 36 19 15 18-14 0-39-6-55-13z" />
        <path d="m604 1365c-63-38-114-127-114-202 0-12-14-43-30-70-40-64-63-175-54-263 6-61 4-70-20-108-32-52-33-103-3-157 37-67 66-75 260-75 165 0 168 0 192 25 41 40 33 116-17 171-18 21-18 22 7 65 31 55 31 72-1 114-31 41-64 142-64 195 0 28-11 54-44 103-41 62-59 114-68 199l-3 28z" />
      </g>
    </svg>
  );
}

export default ApeDaoIcon;
