import { AvatarComponent } from '@rainbow-me/rainbowkit';
import { ApeDaoIcon } from 'components';

const Avatar: AvatarComponent = ({ size }) => {
  return (
    <div className={`h-[${size}px] w-[${size}px]`}>
      <ApeDaoIcon className="h-full w-full" />
    </div>
  );
};

export default Avatar;
