import React, { useEffect } from 'react';
import { INftLocation } from 'types';
import { getChainByEvmChainName } from 'web3/chainsAndWallets';

function useNftInfoDialogLocation(props: { location?: INftLocation }) {
  const [explorerLink, setExplorerLink] = React.useState<string>();

  useEffect(() => {
    if (props.location) {
      const baseUrl = getChainByEvmChainName(props.location.chain)?.blockExplorers?.default.url;
      if (baseUrl) {
        setExplorerLink(`${baseUrl}/address/${props.location.address}`);
      }
    } else {
      setExplorerLink(undefined);
    }
  }, [props.location]);

  return {
    explorerLink,
  };
}

export default useNftInfoDialogLocation;
