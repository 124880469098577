import { Tooltip } from '@material-tailwind/react';
import Big from 'big.js';
import classNames from 'classnames';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export interface IStatsPercentageCircleProps {
  className?: string;
  percentage: Big;
  darkMode?: boolean;
}

function StatsPercentageCircle(props: IStatsPercentageCircleProps) {
  return (
    <Tooltip content={props.percentage.toFixed(2) + '%'}>
      <div className="relative">
        <CircularProgressbar
          className={classNames(props.className)}
          strokeWidth={8}
          value={Number(props.percentage.toFixed(0))}
          styles={buildStyles({
            pathTransitionDuration: 0.5,
            pathColor: `rgb(255 193 7 / var(--tw-bg-opacity))`,
            trailColor: props.darkMode ? 'rgb(81, 81, 81)' : '#DDDDDD',
          })}
        />
        <span className="absolute left-0 top-0 flex w-full scale-[0.60] justify-center font-mono">
          {Number(props.percentage.toFixed(0))}
        </span>
      </div>
    </Tooltip>
  );
}

export default StatsPercentageCircle;
