import type { Placement } from '@floating-ui/react';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Dialog,
  DialogBody,
  IconButton,
  Popover,
  PopoverContent,
  PopoverHandler,
  Typography,
} from '@material-tailwind/react';
import classNames from 'classnames';
import { Link, Spinner } from 'components';
import { formatHelpers } from 'helpers';
import { IBaseCollection, ICollectionApprovals } from 'types';
import useApproveRevokeAccess from './hooks/useApproveRevokeAccess';

export interface IApproveRevokeAccessProps {
  className?: string;
  classNameIconButton?: string;
  collections: IBaseCollection[];
  collectionApprovals: ICollectionApprovals;
  variant: 'popover' | 'panel' | 'dialog';
  operatorType: 'apeDaoVault' | 'proxyONFT';
  placement?: Placement;
  disabled?: boolean;
}

const ApproveRevokeAccess = (props: IApproveRevokeAccessProps) => {
  const { popoverOpen, setPopoverOpen, isLoading, setIsLoading, explorerBaseUrl } = useApproveRevokeAccess();

  const accessApproved =
    props.collections && props.collections.some((c) => props.collectionApprovals.approvals.get(c.id));

  const operatorName = props.operatorType === 'apeDaoVault' ? 'ApeDAO vault' : 'Proxy ONFT';
  const operationActionDescription =
    props.operatorType === 'apeDaoVault' ? 'sell your NFTs to the ApeDAO vault' : 'bridge your NFTs to another chain';

  const content = (
    <div className={classNames('flex max-w-[20rem] flex-col gap-3')}>
      <Typography
        className={classNames('text-center text-sm font-semibold', { 'text-apedao-black': !accessApproved || true })}
      >
        {operatorName} access is currently {accessApproved ? 'approved' : 'revoked'}
        <br />
        for {formatHelpers.createTextualEnumeration(props.collections.map((c) => c.name + 's'))}
      </Typography>
      <Typography className="text-apedao-black text-center text-xs font-normal leading-relaxed">
        Contract address:{' '}
        {explorerBaseUrl ? (
          <Link href={`${explorerBaseUrl}/address/${props.collectionApprovals.contractAddress}`}>
            {props.collectionApprovals.contractAddress}
          </Link>
        ) : (
          props.collectionApprovals.contractAddress
        )}
      </Typography>
      <Typography className="text-apedao-black text-center text-xs font-normal leading-relaxed">
        In order to {operationActionDescription}, you must first give it full access to your collection(s). You can{' '}
        <span className="underline">and should</span> revoke this access at any time.
      </Typography>
      <Button
        className="w-full"
        variant="filled"
        color={accessApproved ? 'amber' : 'red'}
        onClick={() => {
          if (props.collections) {
            setIsLoading(true);
            props.collectionApprovals.setApprovalForCollections(props.collections, !accessApproved).finally(() => {
              setIsLoading(false);
              setPopoverOpen(false);
            });
          }
        }}
      >
        {accessApproved ? 'Revoke' : 'Approve'} Access
        {isLoading && <Spinner className="absolute ml-4 h-4 w-4 !border-white"></Spinner>}
      </Button>
    </div>
  );

  const iconButton = (props.variant === 'popover' || props.variant === 'dialog') && (
    <IconButton
      variant={popoverOpen && props.variant === 'popover' ? 'filled' : 'outlined'}
      color={accessApproved ? 'red' : 'gray'}
      className={classNames('h-8 w-8 rounded-full hover:shadow-none', props.classNameIconButton)}
      disabled={props.disabled}
      onClick={() => setPopoverOpen(!popoverOpen)}
    >
      {accessApproved ? (
        <LockOpenIcon className={classNames('h-4 w-4', { 'dark:text-red-500': !popoverOpen })} strokeWidth={1.5} />
      ) : (
        <LockClosedIcon className="h-4 w-4" strokeWidth={1.5} />
      )}
    </IconButton>
  );

  let component = props.variant === 'popover' && (
    <Popover placement={props.placement ?? 'bottom-end'} open={popoverOpen} handler={setPopoverOpen}>
      <PopoverHandler>{iconButton}</PopoverHandler>
      <PopoverContent className={classNames(props.className, 'max-w-[20rem]')}>{content}</PopoverContent>
    </Popover>
  );

  component =
    props.variant === 'dialog' ? (
      <>
        {iconButton}
        <Dialog
          open={popoverOpen}
          handler={() => setPopoverOpen(!popoverOpen)}
          className="w-[20rem] min-w-0 max-w-none"
        >
          <DialogBody>{content}</DialogBody>
        </Dialog>
      </>
    ) : (
      component
    );

  component =
    props.variant === 'panel' ? (
      <div className={classNames(props.className, 'rounded-md border border-gray-500 bg-amber-50 p-2')}>{content}</div>
    ) : (
      component
    );

  return component || <></>;
};

export default ApproveRevokeAccess;
