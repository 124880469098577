import { IconButton, Switch, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import { ReactComponent as BridgeImage } from 'assets/images/bridge.svg';
import { ReactComponent as MetamaskFoxImage } from 'assets/images/metamask-fox.svg';
import classNames from 'classnames';
import { BridgePanel, NftInfoDialog, NftList, PageTemplate, Sidebar } from 'components';
import ApproveRevokeAccess from 'components/ApproveRevokeAccess';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { INftSelectionHandle } from 'components/NftList/NftList';
import { IPageTemplateSidebarProps } from 'components/PageTemplate/PageTemplate';
import { useChainName, useLayerZeroBridge } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import React, { useMemo } from 'react';
import { isDesktop } from 'react-device-detect';
import { IBaseCollection, ICartItem, ICheckoutCartResult, ICollection, INft, INftFilters } from 'types';
import { useResizeObserver } from 'usehooks-ts';
import { EvmChainName, mainChain } from 'web3/chainsAndWallets';
import './VaultAndWallet.css';
import getIds from './getIds';
import { useVaultsAndWallet } from './hooks';

export type UseVaultWalletHook = (props: { collection?: ICollection }) => {
  nfts: INft[];
  filteredNfts: INft[];
  filters: INftFilters;
  isLoading: boolean;
  refresh: (setLoading: boolean) => void;
  getCurrentVaultWalletNftIds: (collections: ICollection) => Promise<Map<EvmChainName, BigInt[]>>;
  favorites: string[];
  setFavorite: (nftId: string, favorite: boolean) => void;
  layerZeroBridgeHandler: ReturnType<typeof useLayerZeroBridge>;
};

export type UseCheckoutCartHook = (getCurrentVaultWalletNftIds: (collection: ICollection) => Promise<BigInt[]>) => {
  onCheckout: (cartItems: ICartItem[], buyWithIota?: boolean) => Promise<ICheckoutCartResult>;
  isLoading: boolean;
};

export interface IVaultsAndWalletProps extends IBaseComponentProps {
  collections: IBaseCollection[];
  variant: 'vault' | 'wallet';
  vaultWalletHook: UseVaultWalletHook;
  checkoutCartHook: UseCheckoutCartHook;
}

function VaultsAndWallet(props: IVaultsAndWalletProps) {
  const ID = useIds(props.id, getIds);
  const {
    walletInfo,
    collection,
    isLoading,
    vaultWalletNfts,
    cart,
    collectionApprovals,
    filters,
    filteredNfts,
    nftInfo,
    sidebar: sidebarState,
    favorites,
    onCollectionIdChange,
    onAddNftsToWallet,
    layerZeroBridgeHandler,
    bridgeSelectionMode,
    setBridgeSelectionMode,
  } = useVaultsAndWallet(props);

  const chainName = useChainName();

  const bridgePanelRef = React.useRef<HTMLDivElement>(null);
  const { height: bridgePanelHeight } = useResizeObserver({ ref: bridgePanelRef });

  const layerZeroSelectionHandle: INftSelectionHandle = useMemo(() => {
    return {
      selectedNftIds: layerZeroBridgeHandler.selectedNftIds,
      isSelectable: (nft: INft) => nft.location.tag === 'wallet' && nft.location.chain === chainName,
      onResetSelection: layerZeroBridgeHandler.resetNftIdSelection,
      onToggleSelection: layerZeroBridgeHandler.toggleNftIdSelection,
    };
  }, [
    chainName,
    layerZeroBridgeHandler.resetNftIdSelection,
    layerZeroBridgeHandler.selectedNftIds,
    layerZeroBridgeHandler.toggleNftIdSelection,
  ]);

  const bridgeIcon = useMemo(() => <BridgeImage className="h-full w-full" />, []);

  const sidebar = (onChangeOpen: (open: boolean) => void) => (
    <Sidebar
      id={ID.sidebar}
      walletInfo={walletInfo}
      collectionAttributes={collection?.attributes ?? new Map()}
      filters={filters}
      vaultWalletNfts={vaultWalletNfts}
      cart={collection?.featureCart ? cart : undefined}
      collectionApprovals={collectionApprovals}
      position={sidebarState.position}
      isLoading={isLoading}
      forceClose={sidebarState.forceClose}
      onChangeOpen={sidebarState.onChangeOpenDecorator(onChangeOpen)}
      onShowNftInfo={nftInfo.onShowNftInfo}
      onSwapSidebarPosition={() => sidebarState.onPositionChange(sidebarState.position === 'left' ? 'right' : 'left')}
    />
  );

  const sidebarProps: IPageTemplateSidebarProps = {
    render: sidebar,
    widthCollapsed: 65,
    widthExpanded: 288 + 65,
  };

  return (
    <>
      <PageTemplate
        walletInfo={walletInfo}
        sidebarLeft={sidebarState.position === 'left' ? sidebarProps : undefined}
        sidebarRight={sidebarState.position === 'right' ? sidebarProps : undefined}
        scrollToTopButton={true}
        isLoading={isLoading}
      >
        <div className="apedao-tabed-panel mx-2 flex flex-col py-5 duration-500 sm:mx-5 sm:py-8 lg:mx-10 lg:py-10">
          <Tabs id={ID.tabs} value={collection?.id ?? 0}>
            <TabsHeader className="relative z-10 ml-auto mr-auto w-[18rem] items-center justify-center rounded-b-none border bg-transparent lg:w-[24rem]">
              {props.collections.map((c) => (
                <Tab
                  key={`collection_${c.id}`}
                  value={c.id}
                  onClick={() => onCollectionIdChange(c.id)}
                  className="whitespace-nowrap text-base"
                >
                  <div className={classNames('flex flex-col items-center gap-2')}>
                    <img className="h-6 w-6 rounded-full" src={c.icon} alt={c.name} />
                    <span className="text-xs lg:text-sm">{c.name}s</span>
                  </div>
                </Tab>
              ))}
              <div className="absolute -left-[1px] -right-[1px] bottom-0 top-5 flex translate-y-full flex-row items-center justify-center gap-4 rounded-b-lg border dark:border-apedao-black-900">
                <div className="absolute -bottom-7 h-7 w-32 select-none whitespace-nowrap rounded-b-md border-x border-b pt-2 text-center text-sm leading-none dark:border-apedao-black-900 dark:text-apedao-black-400">
                  {isLoading
                    ? '??? / ???'
                    : `${filteredNfts.length - filteredNfts.filter((nft) => nft.type === 'random').length} / ${
                        collection?.totalNumber
                      }`}
                </div>

                <ToolbarSwitch
                  id="switch_gallery_mode"
                  label="Gallery"
                  checked={filters.galleryMode}
                  onChange={() => filters.onSetGalleryMode(!filters.galleryMode)}
                  disabled={bridgeSelectionMode}
                />

                {props.variant === 'wallet' && collection?.contracts.iotaEvm?.lzProxyAddress != null && (
                  <>
                    <span>|</span>
                    <ToolbarSwitch
                      id="switch_bridge"
                      label="Bridge"
                      checked={bridgeSelectionMode}
                      onChange={() => setBridgeSelectionMode(!bridgeSelectionMode)}
                      disabled={filters.galleryMode}
                    />
                  </>
                )}

                {props.variant === 'wallet' && collection?.featureCart && chainName === mainChain.chainName && (
                  <>
                    <span>|</span>
                    <ApproveRevokeAccess
                      variant="dialog"
                      collections={collection ? [collection] : []}
                      collectionApprovals={collectionApprovals}
                      operatorType="apeDaoVault"
                      classNameIconButton="border-apedao-black-200 dark:border-apedao-black-600"
                    />
                  </>
                )}

                {props.variant === 'wallet' && isDesktop && (
                  <>
                    <span className="hidden md:block">|</span>
                    <IconButton
                      variant="outlined"
                      color="gray"
                      className="hidden h-8 w-8 rounded-full border-apedao-black-200 hover:shadow-none focus:ring-0 dark:border-apedao-black-600 md:block"
                      onClick={onAddNftsToWallet}
                      disabled={filteredNfts.length === 0 || filters.galleryMode}
                    >
                      <MetamaskFoxImage className="h-4 w-4" />
                    </IconButton>
                  </>
                )}
              </div>
            </TabsHeader>

            <TabsBody className="translate-y-12 overflow-visible sm:translate-y-14">
              {props.collections.map((c) => (
                <TabPanel
                  key={`collection_${c.id}`}
                  value={c.id}
                  className="px-4 pb-2 transition-all duration-300"
                  style={bridgeSelectionMode ? { translateY: 32 } : { translateY: -1 * (bridgePanelHeight ?? 0) }}
                >
                  <>
                    <div
                      ref={bridgePanelRef}
                      className={classNames(
                        '-top-12 right-0 mb-6 flex w-full flex-row justify-center transition-all duration-300 sm:mb-8',
                        { 'pointer-events-none opacity-0': !bridgeSelectionMode },
                        { 'sticky z-20 opacity-100': bridgeSelectionMode }
                      )}
                    >
                      <BridgePanel
                        lzBridgeHandler={layerZeroBridgeHandler}
                        className="w-[28rem] rounded-lg border border-apedao-black-100 bg-white bg-white/60 p-4 backdrop-blur-2xl dark:border-apedao-black-800 dark:bg-apedao-black-700/60"
                        multiSelect={true}
                      />
                    </div>
                    {collection?.id === c.id && (
                      <NftList
                        id={`collection_${c.id}`}
                        mode={bridgeSelectionMode ? 'select' : 'cart'}
                        page={props.variant}
                        nfts={isLoading ? [] : filteredNfts}
                        totalNumber={collection.totalNumber}
                        cart={cart}
                        isLoading={isLoading}
                        favoriteIds={favorites.ids}
                        emptyListMessage={
                          walletInfo.connected
                            ? collection?.emptyListMessage
                            : 'Connect your wallet to eventually see your precious ape family'
                        }
                        displayLocation={filters.galleryMode}
                        onSetFavorite={favorites.setFavorite}
                        onShowNftInfo={nftInfo.onShowNftInfo}
                        hideNftInfoButtons={bridgeSelectionMode}
                        selectedIcon={bridgeIcon}
                        selectionHandle={bridgeSelectionMode ? layerZeroSelectionHandle : undefined}
                      />
                    )}
                  </>
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </PageTemplate>

      <NftInfoDialog
        id={ID.nftInfoDialog}
        nft={nftInfo.nft}
        mode={props.variant === 'vault' ? 'buy' : 'sell'}
        display={nftInfo.nft != null}
        isFavorite={nftInfo.nft ? favorites.ids.includes(nftInfo.nft.id) : false}
        isInCart={nftInfo.nft ? cart.items.has(nftInfo.nft.id) : false}
        lzBridgeHandler={layerZeroBridgeHandler}
        onDisplay={() => nftInfo.onShowNftInfo(undefined)}
        onSetFavorite={(isFavorite) => nftInfo.nft && favorites.setFavorite(nftInfo.nft.id, isFavorite)}
      />
    </>
  );
}

function ToolbarSwitch(props: {
  id: string;
  label: string;
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
}) {
  return (
    <div className="flex scale-75 cursor-pointer flex-col items-center gap-2">
      <Switch id={props.id} checked={props.checked} onChange={props.onChange} disabled={props.disabled} />
      <span
        className={classNames('whitespace-nowrap pt-0.5 text-sm leading-none dark:font-light', {
          'opacity-60': props.disabled,
        })}
        onClick={() => !props.disabled && props.onChange}
      >
        {props.label}
      </span>
    </div>
  );
}

export default VaultsAndWallet;
