import { msg, useApeDaoVaultInfo } from 'helpers/index';
import { useCallback } from 'react';
import { INft } from 'types';
import { useWalletClient } from 'wagmi';
import { EvmChainName } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

const useMetaMask = () => {
  const { data: walletClient } = useWalletClient();
  const { info: apeDaoVaultInfo } = useApeDaoVaultInfo();

  const addVaultTokenToWallet = useCallback(async () => {
    if (walletClient && apeDaoVaultInfo) {
      try {
        await walletClient.watchAsset({
          type: 'ERC20',
          options: {
            address: contracts.apeDaoVault.address,
            symbol: apeDaoVaultInfo.symbol,
            decimals: apeDaoVaultInfo.decimals,
          },
        });
      } catch (error) {
        console.log(error);
        msg.error("Couldn't add vault token to wallet.");
      }
    }
  }, [apeDaoVaultInfo, walletClient]);

  const addNftToWallet = useCallback(
    async (nft: INft) => {
      const contract = nft.collection.contracts[nft.location.chain as EvmChainName];
      if (walletClient && contract) {
        try {
          await walletClient.request({
            method: 'wallet_watchAsset' as any,
            params: {
              type: 'ERC721', // can also be 'ERC1155'
              options: {
                address: contract.address,
                tokenId: String(nft.edition),
              },
            },
          } as any);
        } catch (error) {
          console.error(error);
          msg.error("Couldn't add NFT to wallet.");
        }
      }
    },
    [walletClient]
  );

  return {
    addVaultTokenToWallet,
    addNftToWallet,
  };
};

export default useMetaMask;
