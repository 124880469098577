import Big from 'big.js';
import { IHolding } from 'components/StatsMyApeHoldings/StatsMyApeHoldings';
import { useWalletInfo } from 'contexts/WalletInfo';
import { msg, useMagicSeaLPAndFarmHoldings } from 'helpers';
import { CollectionKey, IStats } from 'helpers/backendApi/stats_v1';
import { httpGet } from 'helpers/fetch';
import { isDevMode } from 'helpers/storageHelpers';
import { ACTIVE_COLLECTIONS, COLLECTIONS } from 'helpers/useNftCollections';
import useWalletNftHoldingsActiveCollections from 'helpers/useWalletNftHoldingsActiveCollections';
import useWindowSize from 'helpers/useWindowSize';
import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { Address } from 'viem';

const statsURL = isDevMode() ? 'http://localhost:8000/v1/stats' : 'https://backend.apedao.finance/v1/stats';

const collectionIdToStatsMap = new Map<number, CollectionKey>([
  [0, 'ogApe'],
  [1, 'lilApe'],
  [2, 'banana'],
]);

const emptyNftHoldingStats: IHolding[] = [...ACTIVE_COLLECTIONS.keys()].map((collectionId) => {
  const baseCollection = COLLECTIONS.get(collectionId)!;
  return {
    name: baseCollection.name + 's',
    type: 'nft',
    count: Big(0),
    inAPEIn: Big(0),
    inWIOTA: Big(0),
    inUSD: Big(0),
  };
});
const emptyApesInLpOrFarmHoldingStats: IHolding = {
  name: 'APEin',
  type: 'token',
  count: Big(0),
  inAPEIn: Big(0),
  inWIOTA: Big(0),
  inUSD: Big(0),
};

function useStats() {
  const walletInfo = useWalletInfo();

  const [initAddress, setInitAddress] = useState<Address>();
  const [stats, setStats] = useState<IStats | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    nftHoldings,
    refresh: refreshNftHoldings,
    isLoading: nftHoldingsLoading,
    isError: nftHoldingsError,
  } = useWalletNftHoldingsActiveCollections({ address: walletInfo?.address });

  const {
    holdings: holdingsInLpOrFarm,
    refresh: refreshHoldingsInLpOrFarm,
    isLoading: holdingsInLpOrFarmLoading,
    isError: holdingsInLpOrFarmError,
  } = useMagicSeaLPAndFarmHoldings({
    address: walletInfo?.address,
  });

  const [holdingStats, setHoldingStats] = useState<IHolding[]>([
    ...emptyNftHoldingStats,
    emptyApesInLpOrFarmHoldingStats,
  ]);

  const { isWidthSmallerThan } = useWindowSize();

  const currentAddress = walletInfo?.address ?? null;
  const apeInHoldings = walletInfo.connected
    ? Big(walletInfo.apeInBalance?.value.toString() ?? 0)
        .div(10 ** (walletInfo.apeInBalance?.decimals ?? 1))
        .toString()
    : '0';

  const refreshHoldingStats = useCallback(
    async (stats: IStats) => {
      if (!nftHoldings) {
        setHoldingStats([]);
      } else {
        try {
          const apeInHoldingsInLpOrFarm = (holdingsInLpOrFarm ?? []).find((holding) => holding.symbol === 'APEin');
          const apeInInLpOrFarm = apeInHoldingsInLpOrFarm ? apeInHoldingsInLpOrFarm.value : Big(0);

          let newHoldingStats = createHoldingStats(stats, nftHoldings, Big(apeInHoldings), apeInInLpOrFarm);
          newHoldingStats = nftHoldingsError ? clearHoldingStats(newHoldingStats) : newHoldingStats;
          setHoldingStats(newHoldingStats);
        } catch (e) {
          console.error(e);
          msg.error('Failed to fetch stats data. Please try again later.');
        }
      }
    },
    [holdingsInLpOrFarm, nftHoldings, apeInHoldings, nftHoldingsError]
  );

  const refreshStats = useCallback(async (setLoadingFlag: boolean) => {
    let finished = false; // just to prevent flickering on refresh
    try {
      setLoadingFlag && setTimeout(() => !finished && setIsLoading(true), 300);
      const statsResponse = await httpGet<IStats>(statsURL);
      const currentStats: IStats | undefined = statsResponse.parsedBody;
      setStats(currentStats);
    } catch (e) {
      console.error(e);
      setError(true);
      msg.error('Failed to fetch stats data. Please try again later.');
    } finally {
      finished = true;
      setLoadingFlag && setIsLoading(false);
    }
  }, []);

  useInterval(async () => {
    await refreshStats(false);
    await Promise.all([refreshNftHoldings(), refreshHoldingsInLpOrFarm()]);
  }, 60000);

  useEffect(() => {
    const doIt = async () => {
      if (initAddress !== currentAddress) {
        setInitAddress(currentAddress ?? undefined);
        await refreshStats(true);
      }
    };

    doIt().catch(() => console.log('Failed to fetch collections'));
  }, [currentAddress, initAddress, refreshStats]);

  useEffect(() => {
    const doIt = async () => {
      if (stats && nftHoldings && holdingsInLpOrFarm) {
        await refreshHoldingStats(stats);
      }
    };

    doIt().catch(() => console.log('Failed to fetch collections'));
  }, [holdingsInLpOrFarm, nftHoldings, refreshHoldingStats, stats]);

  return {
    stats,
    statsLoading: isLoading,
    statsError: error,
    holdingStats: holdingStats,
    holdingStatsLoading: nftHoldingsLoading || holdingsInLpOrFarmLoading,
    holdingStatsError: nftHoldingsError || holdingsInLpOrFarmError,
    isWidthSmallerThan,
  };
}

function createHoldingStats(
  stats: IStats,
  nftHoldings: Map<number, BigInt[]>,
  apeInHoldings: Big.Big,
  apeInInLpOrFarm: Big.Big
) {
  let newHoldingStats: IHolding[] = [...ACTIVE_COLLECTIONS.keys()].map((collectionId) => {
    const baseCollection = COLLECTIONS.get(collectionId)!;
    const collectionHolding = nftHoldings.get(collectionId) ?? [];
    const collectionChainIsIota = baseCollection.chain === 'iotaEvm';

    const priceInAPEin = baseCollection.price ? Big(baseCollection.price ?? 0) : null;

    const assetStatsKey = collectionIdToStatsMap.get(collectionId);
    const priceInWIOTA = priceInAPEin
      ? priceInAPEin.mul(stats.prices.APEin_wIOTA)
      : assetStatsKey && collectionChainIsIota
      ? Big(stats.assetStats[assetStatsKey].floorPrice!.wIOTA ?? 0)
      : Big(0);

    return {
      name: COLLECTIONS.get(collectionId)?.name + 's' ?? '',
      type: 'nft',
      count: Big(collectionHolding.length),
      inAPEIn: priceInAPEin ? priceInAPEin.mul(collectionHolding.length) : Big(0),
      inWIOTA: priceInWIOTA.mul(collectionHolding.length),
      inUSD: priceInWIOTA.mul(stats.prices.IOTA_USD).mul(collectionHolding.length),
    };
  });

  const totalApeInHoldings = apeInHoldings.add(apeInInLpOrFarm);

  if (apeInHoldings.gt(0)) {
    newHoldingStats = [
      ...newHoldingStats,
      {
        name: 'APEin',
        note: apeInInLpOrFarm.gt(0) ? 'inkl. LP' : '',
        count: Big(0),
        type: 'token',
        inAPEIn: totalApeInHoldings,
        inWIOTA: totalApeInHoldings.mul(stats.prices.APEin_wIOTA),
        inUSD: totalApeInHoldings.mul(stats.prices.APEin_USD),
      },
    ];
  }

  return newHoldingStats;
}

function clearHoldingStats(holdings: IHolding[]) {
  return holdings.map((holding) => {
    return {
      ...holding,
      inAPEIn: Big(0),
      inSMR: Big(0),
      inUSD: Big(0),
    };
  });
}

export default useStats;
