import { Address } from 'viem';

const addThousandsDelimiter = (value: string, delimiter: string): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};

const formatNumber = (
  value: bigint | number,
  assetDecimals: number,
  formatDecimals: number | 'auto' = 2,
  thousandDelimiter = '.'
): string => {
  const valueStr = typeof value === 'bigint' ? value.toString() : String(value);
  const integerPart = (assetDecimals > 0 ? valueStr.slice(0, -assetDecimals) : valueStr) || '0';
  const formattedIntegerPart = addThousandsDelimiter(integerPart, thousandDelimiter);
  let decimalPart = assetDecimals > 0 ? valueStr.slice(-assetDecimals).padStart(assetDecimals, '0') : '0';
  const formatDecimalsNum = formatDecimals === 'auto' ? assetDecimals : formatDecimals;
  decimalPart = formatDecimals === 0 ? '' : decimalPart.slice(0, formatDecimalsNum).padEnd(formatDecimalsNum, '0');
  decimalPart = formatDecimals === 'auto' ? decimalPart.replace(/0+$/, '') : decimalPart;

  return `${formattedIntegerPart}${decimalPart.length > 0 ? `,${decimalPart}` : ''}`;
};

const formatBig = (
  value: Big,
  formatDecimals: number = 2,
  thousandDelimiter = '.',
  shortenOnGtMillion = false
): string => {
  const theValue = value.gt(1000000) && shortenOnGtMillion ? value.div(1000000) : value;
  const valueStr = theValue.toFixed(formatDecimals, 1);
  const postfix = value.gt(1000000) && shortenOnGtMillion ? 'M' : '';

  if (formatDecimals === 0) {
    return addThousandsDelimiter(valueStr, thousandDelimiter) + `${postfix}`;
  } else {
    const parts = valueStr.split('.');
    const integerPart = parts[0];
    const formattedIntegerPart = addThousandsDelimiter(integerPart, thousandDelimiter);
    const decimalPart = parts[1] || '';

    return `${formattedIntegerPart},${decimalPart} ${postfix}`;
  }
};

const createTextualEnumeration = (items: string[]): string => {
  const strings: string[] = [...items];

  let result: string;
  if (strings.length > 1) {
    const lastString = strings.pop();
    result = `${strings.join(', ')} and ${lastString}`;
  } else {
    result = strings[0];
  }

  return result;
};

const shortenAddress = (address: Address, length: number = 4): string => {
  return `${address.slice(0, length + 2)}...${address.slice(-length)}`;
};

const shortenHash = (hash: `0x${string}`, length: number = 4): string => {
  return shortenAddress(hash, length);
};

export { createTextualEnumeration, formatBig, formatNumber, shortenAddress, shortenHash };
