import { useEffect, useState } from 'react';

import { useReadContracts } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

export interface IApeDaoVaultInfo {
  symbol: string;
  decimals: number;
  mintFeePercentage: bigint;
  targetFeePercentage: bigint;
  randomFeePercentage: bigint;
}

function useApeDaoVaultInfo() {
  const [info, setInfo] = useState<IApeDaoVaultInfo>();
  const { data, error } = useReadContracts({
    contracts: [
      { ...contracts.apeDaoVault, functionName: 'symbol', chainId: mainChain.id },
      { ...contracts.apeDaoVault, functionName: 'decimals', chainId: mainChain.id },
      { ...contracts.apeDaoVault, functionName: 'mintFee', chainId: mainChain.id },
      { ...contracts.apeDaoVault, functionName: 'targetRedeemFee', chainId: mainChain.id },
      { ...contracts.apeDaoVault, functionName: 'randomRedeemFee', chainId: mainChain.id },
    ],
  });

  useEffect(() => {
    if (error) {
      console.error('Error fetching ApeDaoVault info', error);
    } else if (data) {
      setInfo({
        symbol: data[0].result!,
        decimals: data[1].result!,
        mintFeePercentage: data[2].result!,
        targetFeePercentage: data[3].result!,
        randomFeePercentage: data[4].result!,
      });
    }
  }, [data, error]);

  return {
    info: info,
  };
}

export default useApeDaoVaultInfo;
