import Big from 'big.js';
import { IHoldingAmounts, IMyApeHoldingsProps } from 'components/StatsMyApeHoldings/StatsMyApeHoldings';
import { useEffect, useState } from 'react';

function useStatsMyApeHoldings(props: IMyApeHoldingsProps) {
  const [total, setTotal] = useState<IHoldingAmounts>();

  useEffect(() => {
    setTotal(
      props.holdings.reduce(
        (acc, holding) => {
          acc.inAPEIn = acc.inAPEIn.add(holding.inAPEIn);
          acc.inWIOTA = acc.inWIOTA.add(holding.inWIOTA);
          acc.inUSD = acc.inUSD.add(holding.inUSD);
          acc.count = acc.count.add(holding.count);
          return acc;
        },
        { inAPEIn: Big(0), inWIOTA: Big(0), inUSD: Big(0), count: Big(0) }
      )
    );
  }, [props.holdings]);

  return {
    total,
  };
}

export default useStatsMyApeHoldings;
