import { CheckIcon, HeartIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react';
import RandomImage from 'assets/images/random.svg';
import classNames from 'classnames';
import { FadeInOut, Image, NftChainIcon, NumberInput } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import NftListItemLocationIcon from 'components/NftListItem/NftListItemLocationIcon';
import { ReactElement, memo, useMemo } from 'react';
import { LazyComponentProps, LazyLoadComponent } from 'react-lazy-load-image-component';
import { INft } from 'types';
import useNftListItem from './hooks/useNftListItem';

export interface INftListItemProps extends IBaseComponentProps, LazyComponentProps {
  className?: string;
  mode: 'cart' | 'select';
  page: 'vault' | 'wallet';
  nft: INft;
  totalNumber: number;
  quantity: number;
  toBeRemoved: boolean;
  maxQuantity: number;
  lazyLoad: boolean;
  isFavorite: boolean;
  isSelected: boolean;
  selectedIcon?: ReactElement;
  isSelectable?: boolean;
  displayLocation: boolean;
  hideInfoButton: boolean;
  onSetFavorite: (nftId: string, isFavorite: boolean) => void;
  onSelect?: (nft: INft) => void;
  onQuantityChange: (nft: INft, quantity: number) => void;
  onImageLoaded?: () => void;
  onShowNftInfo?: (nftId: string) => void;
}

function NftListItem(props: INftListItemProps) {
  const { inCart, canBeAddedToCart, imgLoaded, setImgLoaded, handleImageClick } = useNftListItem(props);
  const isSelectable = props.mode === 'cart' ? canBeAddedToCart : props.isSelectable ?? false;

  const placeholder = useMemo(
    () => <div className={classNames(props.className, 'h-[330px]')}></div>,
    [props.className]
  );

  const handleImageLoaded = () => {
    setImgLoaded(true);
    props.onImageLoaded && props.onImageLoaded();
  };

  return (
    <>
      <LazyLoadComponent visibleByDefault={!props.lazyLoad} delayTime={200} placeholder={placeholder}>
        <Card
          id={props.id}
          className={classNames(
            'scale-100 rounded-md transition-transform duration-200 ease-in-out hover:scale-[1.04] hover:shadow-lg',
            {
              'bg-gradient-to-br from-amber-50 via-amber-100 to-amber-50': inCart,
            },
            {
              'dark:bg-gradient-to-br dark:from-apedao-black-300 dark:via-apedao-black-600 dark:to-apedao-black-400':
                inCart,
            },
            props.className
          )}
        >
          <CardHeader floated={false} shadow={false} color="transparent" className="m-0 overflow-hidden rounded-md">
            <div className="relative aspect-square overflow-hidden p-2">
              <Image
                src={props.nft.image ?? RandomImage}
                lowResPlaceholderSrc={props.nft.lowResImage}
                blurLowRes="blur-md"
                alt={props.nft?.id ?? 'n/a'}
                className={classNames(
                  'aspect-square w-full rounded-md object-cover object-center dark:border-apedao-black-500',
                  { 'bg-white dark:bg-apedao-black-800': props.nft.type !== 'random' },
                  { 'bg-yellow-600 p-4': props.nft.type === 'random' },
                  { 'cursor-pointer': isSelectable }
                )}
                lazyLoad={!props.lazyLoad}
                onLoad={handleImageLoaded}
                onError={handleImageLoaded}
                onClick={isSelectable ? handleImageClick : undefined}
              />
              <FadeInOut isVisible={inCart} maxOpacity="opacity-80">
                <div
                  className="absolute right-0 top-0 mr-5 mt-5 flex h-[12%] min-h-[1.5rem] w-[12%] min-w-[1.5rem] rounded-full border border-apedao-black-800 bg-white p-1"
                  onClick={isSelectable ? handleImageClick : undefined}
                >
                  <ShoppingCartIcon className="h-full w-full text-apedao-black-800 opacity-100" strokeWidth={1.5} />
                </div>
              </FadeInOut>
              <FadeInOut isVisible={props.isSelected} maxOpacity="opacity-80">
                <div
                  className="absolute right-0 top-0 mr-5 mt-5 flex h-[12%] min-h-[1.5rem] w-[12%] min-w-[1.5rem] rounded-full border border-apedao-black-800 bg-white p-1"
                  onClick={isSelectable ? handleImageClick : undefined}
                >
                  {props.selectedIcon ?? (
                    <CheckIcon className="h-full w-full text-apedao-black-800 opacity-100" strokeWidth={1.5} />
                  )}
                </div>
              </FadeInOut>
              <FadeInOut isVisible={imgLoaded && props.nft.type !== 'random' && !inCart && props.displayLocation}>
                <div className="absolute right-0 top-0 mr-5 mt-5 flex h-[12%] min-h-[1.5rem] w-[12%] min-w-[1.5rem] rounded-full border border-red-300 bg-white/75 p-1">
                  <NftListItemLocationIcon nft={props.nft} />
                </div>
              </FadeInOut>
              {props.nft.type !== 'random' && props.onShowNftInfo != null && (
                <FadeInOut isVisible={imgLoaded && !props.hideInfoButton}>
                  <div
                    className={classNames(
                      'absolute bottom-0.5 right-0.5 grid h-[1.75rem] w-[1.75rem] cursor-pointer place-items-center rounded-full border-2 bg-gray-500 text-xs font-medium text-white sm:h-[1.5rem] sm:w-[1.5rem]',
                      { 'border-white dark:border-apedao-black-800 dark:dark:text-apedao-black-50 ': !inCart },
                      {
                        'border-amber-50 dark:border-apedao-black-400 dark:bg-apedao-black-200 dark:text-apedao-black-950':
                          inCart,
                      }
                    )}
                    onClick={() => props.onShowNftInfo!(props.nft.id)}
                  >
                    i
                  </div>
                </FadeInOut>
              )}
            </div>
          </CardHeader>
          <CardBody className="px-3 py-1">
            <div className={classNames('mb-2 grid grid-cols-8 items-center')}>
              <div
                className={classNames(
                  'col-start-1 flex origin-left scale-[0.75] flex-col items-start justify-start transition-opacity sm:scale-100',
                  {
                    'opacity-0 sm:opacity-100': props.nft.type === 'random' && inCart,
                  },
                  {
                    'opacity-100': props.nft.type === 'random' && !inCart,
                  }
                )}
              >
                <Typography className="whitespace-nowrap text-sm font-medium uppercase">ID</Typography>
                <div className="flex flex-row items-center gap-2">
                  <Typography className="m-0 whitespace-nowrap text-xs uppercase">
                    {props.nft.type === 'random' ? '???' : props.nft.name}
                  </Typography>
                  {props.nft.type !== 'random' && <NftChainIcon location={props.nft.location} />}
                  <div className="realtive h-4 w-4">
                    <button
                      className="absolute z-10 h-8 w-8 -translate-x-2 -translate-y-2"
                      onClick={() => props.onSetFavorite(props.nft.id, !props.isFavorite)}
                    />
                    <FadeInOut
                      isVisible={props.nft.type !== 'random' && props.onShowNftInfo != null && props.isFavorite}
                    >
                      <HeartIconSolid className="absolute h-4 w-4 text-red-400 dark:text-red-500" />
                    </FadeInOut>
                    <FadeInOut
                      isVisible={props.nft.type !== 'random' && props.onShowNftInfo != null && !props.isFavorite}
                    >
                      <HeartIcon className="absolute h-4 w-4 text-apedao-black-200 dark:text-apedao-black-600" />
                    </FadeInOut>
                  </div>
                </div>
              </div>

              {props.nft.type === 'random' && (
                <div className={classNames('col-start-2 col-end-8', { 'col-span-8 col-start-1': inCart })}>
                  <NumberInput
                    value={props.quantity}
                    minValue={props.quantity === 0 ? 0 : 1}
                    maxValue={Math.min(props.maxQuantity, 99)}
                    visible={props.quantity > 0}
                    onChange={(newQuantity) => props.onQuantityChange(props.nft, newQuantity)}
                    className="mx-auto"
                  />
                </div>
              )}

              <div
                className={classNames(
                  'col-start-8 flex origin-right scale-[0.75] flex-col items-end justify-end transition-opacity sm:scale-100',
                  {
                    'opacity-0 sm:opacity-100': props.nft.type === 'random' && inCart,
                  },
                  {
                    'opacity-100': props.nft.type === 'random' && !inCart,
                  }
                )}
              >
                <Typography className="whitespace-nowrap text-right text-sm font-medium uppercase">Rank</Typography>
                <Typography className="m-0 whitespace-nowrap text-right text-xs uppercase">
                  {props.nft.type === 'random'
                    ? '???'
                    : `${props.nft.rank <= 0 ? '???' : props.nft.rank}/${props.totalNumber}`}
                </Typography>
              </div>
            </div>
          </CardBody>
        </Card>
      </LazyLoadComponent>
    </>
  );
}

export default memo(NftListItem);
