import { useCallback, useEffect, useState } from 'react';
import { ISidebarProps } from '../Sidebar';

export type SidebarContent = 'filter' | 'cart';

function useSidebar(props: ISidebarProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState<SidebarContent | undefined>();
  const onChangeOpen = props.onChangeOpen;
  const onSwapPosition = props.onSwapSidebarPosition;

  const open = useCallback(
    (content: SidebarContent) => {
      setIsOpen(true);
      setContent(content);
      onChangeOpen && onChangeOpen(true);
    },
    [onChangeOpen]
  );

  const close = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => !isOpen && setContent(undefined), 500);
    onChangeOpen && onChangeOpen(false);
  }, [isOpen, onChangeOpen]);

  const swapPosition = useCallback(() => {
    close();
    onSwapPosition && onSwapPosition();
  }, [close, onSwapPosition]);

  useEffect(() => {
    if (props.forceClose) {
      close();
    }
  }, [close, props.forceClose]);

  return {
    isOpen,
    content,
    open,
    close,
    swapPosition,
  };
}

export default useSidebar;
