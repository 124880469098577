export type CollectionKey = 'ogApe' | 'lilApe' | 'banana' | 'blob';
export type TokenKey = 'apeIn';

export const assetStatsKeyNameMapping: Record<CollectionKey | TokenKey, string> = {
  ogApe: 'OG Apes',
  lilApe: "Lil' Apes",
  banana: 'Bananas',
  apeIn: 'APEin',
  blob: 'Blobs',
};

export type StatsAssets = Record<CollectionKey | TokenKey, Omit<IAssetStats, 'key'>>;

export interface IPrices {
  SMR_USD: string;
  IOTA_USD: string;
  APEin_wIOTA: string;
  APEin_USD: string;
  geckoterminal: {
    smrEvm: Record<string, { SMR: string; USD: string }>;
    iotaEvm: Record<string, { wIOTA: string; USD: string }>;
  };
}

export interface IStats {
  timestamp: number;
  prices: IPrices;
  assetStats: StatsAssets;
  treasuryStats: ITreasuryStats;
}

export interface IAssetStats {
  key: CollectionKey | TokenKey;
  totalSupply: string;
  onEvm: string;
  inVault: string;
  inCirculation: string;
  locked: string;
  holders: string;
  votingPower: string;
  floorPrice?: {
    wIOTA?: string;
    SMR?: string;
    USD: string;
  };
  marketCapInUSD: {
    L1?: string;
    evm?: string;
  };
}

export interface ITreasureStatsItem {
  unlocked: string;
  locked: string;
  // unlockedNfts: Record<string, string[]>;
  // lockedNfts: Record<string, string[]>;
  unlockedNativeTokens: Record<string, string>;
  lockedNativeTokens: Record<string, string>;
}

export interface ITreasuryTokenHoldings {
  inWallets: Record<string, string>;
  inLPsAndFarms: Record<string, string>;
  inStakingPools: Record<string, string>;
}

export interface ITreasuryStats {
  iotaL1: ITreasureStatsItem;
  smrL1: ITreasureStatsItem;
  iotaEvm: ITreasuryTokenHoldings;
  smrEvm: ITreasuryTokenHoldings;
}
