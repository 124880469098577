import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useIds } from 'helpers/idHelpers';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import VaultsAndWallet from 'pages/VaultsAndWallet';
import getIds from './getIds';
import { useBuy, useVaults } from './hooks';

export interface IVaultsProps extends IBaseComponentProps {}

function Vaults(props: IVaultsProps) {
  const ID = useIds(props.id, getIds);

  return (
    <VaultsAndWallet
      id={ID.vaultsAndWallet}
      variant="vault"
      collections={ACTIVE_COLLECTIONS.filter((c) => c.featureVault)}
      vaultWalletHook={useVaults}
      checkoutCartHook={useBuy}
    />
  );
}

export default Vaults;
