import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Image, NumberInput } from 'components';
import { formatNumber } from 'helpers/formatHelpers';
import React from 'react';
import { CheckoutCartItemErrorCause, INft } from 'types';
import useCartItem from './hooks/useCartItem';

export const CART_ITEM_REMOVE_ANIMATION_DURATION = 'duration-500';

export interface ICartItemProps {
  id: string;
  nft: INft;
  image: string | React.JSX.Element;
  name: string;
  quantity: number;
  maxQuantity: number;
  mode: 'buy' | 'sell';
  isLoading?: boolean;
  toBeRemoved?: boolean;
  checkoutErrorCause?: CheckoutCartItemErrorCause;
  onRemoveClick: () => void;
  onQuantityChange?: (quantity: number) => void;
  onShowNftInfo?: (nftId: string) => void;
}

function CartItem(props: ICartItemProps) {
  const { isFirstRender, priceAndFee } = useCartItem(props, 500);
  const { price, fee, feePercentage } = priceAndFee;

  return (
    <div
      key={props.id}
      className={classNames(
        'relative overflow-hidden transition-all',
        CART_ITEM_REMOVE_ANIMATION_DURATION,
        {
          '-translate-y-full opacity-0': props.toBeRemoved,
        },
        {
          'opacity-0': isFirstRender,
        },
        {
          'opacity-100': !isFirstRender && !props.toBeRemoved,
        }
      )}
    >
      {props.checkoutErrorCause != null && (
        <div className="absolute flex h-full w-full items-center justify-center">
          <div className="top-0 z-[21] -rotate-6 rounded-md border-[4px] border-red-500 bg-white/50 px-3 py-1 font-extrabold uppercase tracking-wider text-red-500 opacity-90 backdrop-blur-xl">
            {props.checkoutErrorCause}
          </div>
        </div>
      )}
      <div
        className={classNames('flex h-[4rem] items-center justify-between break-all md:h-[5rem]', {
          grayscale: props.checkoutErrorCause != null,
        })}
      >
        <div
          className="absolute left-[0.25rem] z-20 h-[3.5rem] w-[3.5rem] md:left-[0.5rem] md:h-[5rem] md:w-[5rem]"
          onClick={() => props.onShowNftInfo && props.nft.type !== 'random' && props.onShowNftInfo(props.nft.id)}
        >
          {typeof props.image === 'string' ? (
            <Image
              className="cursor-pointer rounded-md bg-white object-cover dark:border-apedao-black-500 dark:bg-apedao-black-800"
              src={props.image}
              alt={props.nft.name}
            />
          ) : (
            props.image
          )}
        </div>
        <div className="relative z-10 inline-flex max-h-[4rem] w-full items-center overflow-hidden rounded-md border border-apedao-black-100 pl-[4.25rem] shadow-sm dark:border-apedao-black-500 dark:bg-apedao-black-600 md:pl-[5.75rem] ">
          <div className="flex h-[4rem] grow flex-col justify-between pb-1.5 pt-1">
            <div className="text-sm font-semibold">{props.name}</div>
            {props?.nft.type === 'random' && (
              <NumberInput
                value={props.quantity}
                onChange={props.onQuantityChange}
                minValue={props.quantity === 0 ? 0 : 1}
                maxValue={Math.min(props.maxQuantity, 99)}
              />
            )}
          </div>
          <div className="self-start">
            {!props.isLoading && (
              <XMarkIcon
                className="m-1 h-4 w-4 cursor-pointer self-start"
                onClick={props.onRemoveClick}
                strokeWidth={2}
              />
            )}
            {props.isLoading && (
              <ArrowPathIcon
                className="m-1 h-4 w-4 animate-spin cursor-pointer self-start"
                onClick={props.onRemoveClick}
                strokeWidth={2}
              />
            )}
            <div className="absolute -bottom-[1rem] -right-[1rem] mb-[1rem] mr-[1rem] flex origin-bottom-right scale-[0.8] flex-col items-end overflow-hidden leading-tight">
              <span className="pr-2 text-[0.6rem]">{fee > 0 && `incl. ${feePercentage}% fee`}</span>
              <div className="inline min-w-[5rem] rounded-tl-md bg-green-400 px-2 pb-0.5 text-end text-white dark:bg-green-600">
                <span className="align-sub text-[0.6rem] font-normal">{props.nft.collection.symbol}</span>
                <span className="ml-1 align-sub font-medium ">{formatNumber(price, 0, 0)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
