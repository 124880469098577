import { BackspaceIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Checkbox,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Switch,
  Typography,
} from '@material-tailwind/react';
import classNames from 'classnames';
import { FadeInOut } from 'components';
import Chip from 'components/Chip';
import NftFilterChain from 'components/NftFilter/NftFilterChain';
import NftFilterLocation from 'components/NftFilter/NftFilterLocation';
import { useIds } from 'helpers/idHelpers';
import { CollectionAttributes, INft, INftFilters } from 'types';
import { IBaseComponentProps } from '../BaseComponentProps';
import getIds from '../Sidebar/getIds';
import './NftFilter.css';
import useNftFilter from './hooks/useNftFilter';

export interface INftFilterProps extends IBaseComponentProps {
  collectionAttributes: CollectionAttributes;
  filters: INftFilters;
  vaultWalletNfts: INft[];
  isLoading: boolean;
}

function NftFilter(props: INftFilterProps) {
  const ID = useIds(props.id, getIds);
  const {
    attributeFilters,
    expandedAttribute,
    setExpandedAttribute,
    handleAttributeFilterChange,
    handleClearFilter,
    filteredTraits,
    evmChainNamesInWallet,
  } = useNftFilter(props);

  return (
    <div id={ID.root} className="flex min-h-0 flex-col gap-2">
      <Input
        icon={
          <IconButton variant="text" className="h-5 w-5" onClick={() => props.filters.onNameFilterChange('')}>
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </IconButton>
        }
        label="Search for ID"
        color="gray"
        value={props.filters.nameFilter}
        onChange={(event) => props.filters.onNameFilterChange(event.target.value)}
        disabled={props.isLoading}
      />
      <div className="overflow-y-auto rounded-none px-2">
        {[...props.collectionAttributes.entries()].sort().map(([attributeName, traits]) => (
          <Accordion key={attributeName} open={expandedAttribute === attributeName} disabled={props.isLoading}>
            <AccordionHeader
              onClick={() => setExpandedAttribute(expandedAttribute === attributeName ? null : attributeName)}
              className={classNames(
                'nft_filter_hidden_collapse_icon border-apedao-black-100 py-1 pl-2 pr-0 dark:border-apedao-black-500'
              )}
            >
              <div className={classNames('flex w-full flex-col items-start')}>
                <div className={classNames('flex w-full flex-row items-center justify-start gap-2')}>
                  <ChevronDownIcon
                    strokeWidth={2}
                    className={classNames('mx-auto h-3 w-3 flex-grow-0 transition-transform', {
                      'rotate-180': expandedAttribute === attributeName,
                    })}
                  />
                  <Typography
                    className={classNames('mr-auto flex-grow font-normal', {
                      'font-semibold': expandedAttribute === attributeName,
                    })}
                  >
                    {attributeName}
                  </Typography>
                  <FadeInOut isVisible={attributeFilters.get(attributeName) != null}>
                    <div
                      role="button"
                      className="flex-grow-0"
                      onClick={(e) => {
                        handleClearFilter(false, attributeName);
                        e.stopPropagation();
                      }}
                    >
                      <BackspaceIcon className="h-5 w-5 text-amber-500" />
                    </div>
                  </FadeInOut>
                </div>
                <FadeInOut
                  isVisible={attributeFilters.get(attributeName) != null && expandedAttribute !== attributeName}
                  duration="slow"
                  animateFadeOut={false}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className={classNames(
                      'mt-1 flex max-h-fit w-full cursor-default flex-row flex-wrap gap-1 overflow-hidden transition-opacity duration-500 ease-out'
                    )}
                  >
                    {[...(attributeFilters.get(attributeName) ?? [])].sort().map((trait) => (
                      <Chip
                        key={trait}
                        label={trait}
                        onClickXIcon={(e) => {
                          handleAttributeFilterChange(attributeName, trait, false);
                          e.stopPropagation();
                        }}
                      />
                    ))}
                  </div>
                </FadeInOut>
              </div>
            </AccordionHeader>
            <AccordionBody className="border-b border-apedao-black-100 px-0 py-2 dark:border-apedao-black-500">
              <List className="my-0 p-0">
                {[...traits.keys()].sort().map((traitName) => {
                  const isChecked = attributeFilters.get(attributeName)?.has(traitName) ?? false;
                  const anyInAttributeChecked = (attributeFilters.get(attributeName)?.size ?? 0) > 0;
                  const count = filteredTraits.get(attributeName)?.get(traitName) ?? 0;
                  return (
                    <ListItem
                      key={traitName}
                      className="x-2 py-1 text-sm hover:bg-transparent focus:bg-transparent active:bg-transparent"
                      onClick={() => handleAttributeFilterChange(attributeName, traitName, !isChecked)}
                      ripple={false}
                      disabled={count === 0}
                    >
                      <ListItemPrefix className=" ">
                        <Checkbox
                          containerProps={{ className: 'p-0' }}
                          className="before:hover:opacity-0"
                          ripple={false}
                          disabled={false}
                          checked={isChecked}
                          onChange={() => handleAttributeFilterChange(attributeName, traitName, !isChecked)}
                        />
                      </ListItemPrefix>
                      {traitName}
                      <ListItemSuffix>
                        <Chip
                          label={`${isChecked || !anyInAttributeChecked || count === 0 ? '' : '+'}${count}`}
                          className="cursor-pointer"
                        />
                      </ListItemSuffix>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionBody>
          </Accordion>
        ))}
      </div>
      <FadeInOut isVisible={attributeFilters.size > 0}>
        <div className="h-16 w-full text-right">
          <Button
            variant="outlined"
            size="sm"
            className="active mt-4 w-full focus:ring-0"
            onClick={() => handleClearFilter(true, 'all')}
            disabled={props.filters.nameFilter == null && attributeFilters.size === 0}
          >
            <div className="flex flex-row items-center justify-center gap-2">
              <span>Clear all</span>
              <BackspaceIcon className="h-5 w-5" />
            </div>
          </Button>
        </div>
      </FadeInOut>
      <div className="mt-2 flex flex-row justify-end gap-4 px-2">
        <Typography>only favorites</Typography>
        <Switch
          id="onlyFavoritesSwitch"
          defaultChecked={props.filters.onlyFavorites}
          onChange={() => props.filters.onOnlyFavoritesChange(!props.filters.onlyFavorites)}
          disabled={props.isLoading}
        />
      </div>
      <FadeInOut
        isVisible={!props.isLoading && (props.filters.locationFilterEnabled || evmChainNamesInWallet.length > 1)}
      >
        <div className="mt-2 flex flex-col justify-center gap-4 rounded-lg border border-apedao-black-200 py-3 dark:border-apedao-black-600">
          {props.filters.locationFilterEnabled && <NftFilterLocation filters={props.filters} />}
          {evmChainNamesInWallet.length > 1 && (
            <NftFilterChain filters={props.filters} evmChainNames={evmChainNamesInWallet} />
          )}
        </div>
      </FadeInOut>
    </div>
  );
}

export default NftFilter;
