import { INftListItemProps } from 'components/NftListItem/NftListItem';
import React from 'react';
import { mainChain } from 'web3/chainsAndWallets';

function useNftListItem(props: INftListItemProps) {
  const [imgLoaded, setImgLoaded] = React.useState(false);

  const handleImageClick = () => {
    if (props.mode === 'cart') {
      if (props.quantity === 0 || props.toBeRemoved) {
        props.onQuantityChange(props.nft, 1);
      } else {
        props.onQuantityChange(props.nft, 0);
      }
    } else if (props.mode === 'select') {
      props.onSelect && props.onSelect(props.nft);
    }
  };

  const inCart = props.quantity > 0 && !props.toBeRemoved;
  const canBeAddedToCart =
    props.nft.collection.featureCart &&
    (props.nft.location.tag === props.page || props.nft.type === 'random') &&
    (props.nft.location.chain === mainChain.chainName || props.nft.type === 'random');

  return {
    inCart,
    canBeAddedToCart,
    imgLoaded,
    setImgLoaded,
    handleImageClick,
  };
}

export default useNftListItem;
