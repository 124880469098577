import breakpoints from '@material-tailwind/react/theme/base/breakpoints';
import { useWindowSize as useHooksUseWindowSize } from 'usehooks-ts';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

function useWindowSize() {
  const { width, height } = useHooksUseWindowSize();

  function isWidthSmallerThan(breakpoint: Breakpoint | number) {
    const value =
      typeof breakpoint === 'number' ? breakpoint : parseInt((breakpoints as any)[breakpoint].replace('px', ''));
    return width < value;
  }

  return {
    width,
    height,
    isWidthSmallerThan,
  };
}

export default useWindowSize;
