import Big from 'big.js';
import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import useStatsMarketCap from './hooks/useStatsMarketCap';

export interface IStatsMarketCapProps {
  stats: IStats;
  isDarkMode: boolean;
  mobileView: boolean;
}

export function StatsMarketCap(props: IStatsMarketCapProps) {
  const data = useStatsMarketCap(props);

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="ApeDAO Market Cap (USD)">
      <div className="flex flex-col gap-3 sm:flex-row sm:gap-5 md:gap-6">
        {!props.mobileView && (
          <div className="flex grow flex-col justify-end gap-1 pr-4">
            {data.assets.map((mc) => (
              <AssetDisplay key={mc.name} name={mc.name} />
            ))}
          </div>
        )}
        <div className="flex flex-col items-end gap-2 sm:gap-1">
          {data.assets.map((mc) => (
            <div key={mc.name} className="flex w-full flex-row sm:gap-2">
              {props.mobileView && <AssetDisplay key={mc.name} name={mc.name} />}
              <div className="flex w-full flex-row sm:gap-2">
                <div className="grow text-end font-mono">{mc.total.gt(Big(0)) ? formatBig(mc.total, 0) : '-'}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <hr className="my-2 sm:my-1" />
        <div className="flex flex-row justify-between font-semibold">
          <span>Total</span>
          <span className="font-mono ">{formatBig(data.total, 0)}</span>
        </div>
      </div>
    </StatsCard>
  );
}

export default StatsMarketCap;
