import { useWalletInfo } from 'contexts/WalletInfo';
import { useWalletNftHoldings } from 'helpers';
import { useVaultWalletBaseHook } from 'pages/VaultsAndWallet/hooks/useVaultWalletBaseHook';
import { ICollection } from 'types';

export function useWallet(props: { collection?: ICollection }) {
  const walletInfo = useWalletInfo();

  const { getCurrentHoldings, getCurrentBalances } = useWalletNftHoldings({
    address: walletInfo.address,
    collection: props.collection,
    refreshOnInit: false,
  });

  return useVaultWalletBaseHook({
    mode: 'wallet',
    getCurrentBalances: getCurrentBalances,
    getCurrentHoldings: getCurrentHoldings,
    addRandom: false,
    collection: props.collection,
  });
}
