import { SESSION_STORAGE_DEV_MODE } from 'helpers/storageHelpers';
import { useCallback } from 'react';
import { useSessionStorage } from 'usehooks-ts';

const useDarkMode = () => {
  const [devMode, setDevMode] = useSessionStorage<boolean>(SESSION_STORAGE_DEV_MODE, false);

  const toggleDevMode = useCallback(() => {
    setDevMode(!devMode);
    window.location.reload();
  }, [devMode, setDevMode]);

  return {
    devMode,
    toggleDevMode,
  };
};

export default useDarkMode;
