import { WalletIcon } from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/24/solid';
import { ReactComponent as EpochZero } from 'assets/images/epochzero.svg';
import { ReactComponent as MagicSeaOctopus } from 'assets/images/magicsea_octopus.svg';
import { ApeDaoIcon } from 'components';
import { INft } from 'types';

function NftListItemLocationIcon(props: { nft: INft }) {
  return props.nft.location.tag === 'L1' ? (
    <div className="flex h-full w-full translate-y-[0.05em] select-none items-center justify-center text-xs font-semibold text-red-400/80">
      L1
    </div>
  ) : props.nft.location.tag === 'magicSeaMarket' ? (
    <MagicSeaOctopus className="h-full w-full fill-red-400/80" />
  ) : props.nft.location.tag === 'epochZeroMarket' ? (
    <EpochZero className="h-full w-full fill-red-400/80" />
  ) : props.nft.location.tag === 'vault' ? (
    <ApeDaoIcon className="location-ape-image h-full w-full scale-125 fill-red-400/80" noBackground={true} />
  ) : props.nft.location.tag === 'wallet' ? (
    <div className="relative h-full w-full">
      <WalletIcon className="absolute left-0 top-0 h-full w-full text-red-400/80" strokeWidth={1.5} />
      <StarIcon className="absolute -right-0.5 bottom-0 h-[55%] w-[55%] text-red-400/80" />
    </div>
  ) : (
    <WalletIcon className="h-full w-full text-red-400/80" strokeWidth={1.5} />
  );
}

export default NftListItemLocationIcon;
