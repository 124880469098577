import { msg, useChainName, useNftContractAbstraction } from 'helpers';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import React, { useCallback, useEffect } from 'react';
import { IBaseCollection, ICollectionApprovals } from 'types';
import { Address } from 'viem';

const useNftCollectionApprovals = (props: {
  operator?: Address;
  accountAddress?: Address;
}): ICollectionApprovals & { refreshApprovals: () => Promise<void> } => {
  const chainName = useChainName();
  const { getApproval, setApproval } = useNftContractAbstraction();

  const [approvals, setApprovals] = React.useState<Map<number, boolean>>(new Map());
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchApproval = useCallback(
    async (collection: IBaseCollection): Promise<boolean> => {
      try {
        return props.accountAddress && props.operator
          ? await getApproval(chainName, collection, props.accountAddress, props.operator)
          : false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [props.accountAddress, props.operator, getApproval, chainName]
  );

  const refreshApprovals = useCallback(async () => {
    setIsLoading(true);
    try {
      const approvals = new Map<number, boolean>();
      for (const collection of ACTIVE_COLLECTIONS.values()) {
        if (collection.featureCart && collection.contracts[chainName]) {
          approvals.set(collection.id, await fetchApproval(collection!));
        }
      }
      setApprovals(approvals);
    } finally {
      setIsLoading(false);
    }
  }, [chainName, fetchApproval]);

  const setApprovalForCollections = useCallback(
    async (collections: IBaseCollection[], approve: boolean) => {
      if (props.operator) {
        for (const collection of collections) {
          try {
            await setApproval(chainName, collection, props.operator, approve);
            setApprovals((approvals) => new Map(approvals).set(collection.id, approve));
            msg.success(`Successfully ${approve ? 'approved' : 'revoked'} access to ${collection.name} for ApeDAO!`);
          } catch (error) {
            console.error(error);
            msg.error(`Failed to ${approve ? 'approve' : 'revoke'} access to ${collection.name} for ApeDAO!`);
          }
        }
      }
    },
    [chainName, props.operator, setApproval]
  );

  useEffect(() => {
    refreshApprovals().catch((error) => {
      console.error(error);
      msg.error('Failed to check collection approvals!');
    });
  }, [refreshApprovals]);

  return {
    contractAddress: props.operator ?? '0x',
    approvals,
    setApprovalForCollections,
    refreshApprovals,
    isLoading,
  };
};

export default useNftCollectionApprovals;
