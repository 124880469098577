import { msg, useChainName, useNftCollectionApprovals } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { Badge, IAssetBalance, IContract, IWalletInfo } from 'types';
import { useInterval } from 'usehooks-ts';
import { Address, erc721Abi } from 'viem';
import { readContract } from 'viem/actions';
import { useAccount, useBalance, useClient, useReadContract, useReadContracts } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

const INITIAL_WALLET_INFO: IWalletInfo = {
  connected: false,
  collectionApprovals: {
    contractAddress: contracts.apeDaoVault.address,
    approvals: new Map(),
    setApprovalForCollections: async () => {},
    isLoading: false,
  },
  badges: [],
  refresh: () => {},
};

export const WalletInfoContext = React.createContext({
  state: INITIAL_WALLET_INFO,
});

const WalletInfoProvider = (props: { children: React.ReactNode; refreshIntervalMs: number }) => {
  const [walletInfo, setWalletInfo] = useState<IWalletInfo>(INITIAL_WALLET_INFO);
  const chainName = useChainName();
  const { address, isConnected } = useAccount();
  const client = useClient({ chainId: mainChain.id });

  const { data: nativeBalance, refetch: refetchNativeBalance } = useBalance({
    address: address,
  });

  const { data: apeInBalance, refetch: refetchApeInBalance } = useReadContract({
    ...contracts.apeDaoVault,
    functionName: 'balanceOf',
    args: [address!],
  });

  const { data: apeInTokenInfo } = useReadContracts({
    contracts: [
      { ...contracts.apeDaoVault, functionName: 'symbol', chainId: mainChain.id },
      { ...contracts.apeDaoVault, functionName: 'decimals', chainId: mainChain.id },
    ],
  });

  const {
    approvals,
    setApprovalForCollections,
    refreshApprovals,
    isLoading: approvalsAreLoading,
  } = useNftCollectionApprovals({
    operator: contracts.apeDaoVault.address,
    accountAddress: address,
  });

  const refreshAccountInfo = useCallback(() => {
    Promise.all([refetchNativeBalance(), refetchApeInBalance(), refreshApprovals()]).catch(() => {
      msg.error('Error fetching balances. Please consider to refresh the page.');
    });
  }, [refetchApeInBalance, refetchNativeBalance, refreshApprovals]);

  useInterval(async () => {
    if (address != null) {
      refetchNativeBalance().catch((error) => console.log(error));
      refetchApeInBalance().catch((error) => console.log(error));
      refreshApprovals().catch((error) => console.log(error));
    }
  }, props.refreshIntervalMs);

  const hasPositiveBalance = useCallback(
    async (address?: Address, contract?: IContract<typeof erc721Abi>) => {
      if (address && contract && client) {
        const balance = await readContract(client, { ...contract, functionName: 'balanceOf', args: [address] });
        return balance > 0;
      }
      return false;
    },
    [client]
  );

  useEffect(() => {
    const doIt = async () => {
      const bronzeApeDAOSupporter = await hasPositiveBalance(address, contracts.apeDaoBronzeSupporter);
      const silverApeDAOSupporter = await hasPositiveBalance(address, contracts.apeDaoSilverSupporter);
      const goldApeDAOSupporter = await hasPositiveBalance(address, contracts.apeDaoGoldSupporter);
      const badges: Badge[] = [
        bronzeApeDAOSupporter ? 'bronzeApeDAOSupporter' : undefined,
        silverApeDAOSupporter ? 'silverApeDAOSupporter' : undefined,
        goldApeDAOSupporter ? 'goldApeDAOSupporter' : undefined,
      ].filter((badge) => badge != null) as Badge[];

      const apeInAssetBalance: IAssetBalance | undefined =
        apeInBalance != null && apeInTokenInfo != null
          ? { value: apeInBalance, symbol: apeInTokenInfo[0].result!, decimals: apeInTokenInfo[1].result! }
          : undefined;

      setWalletInfo({
        connected: isConnected ?? false,
        address,
        nativeBalance: nativeBalance,
        apeInBalance: apeInAssetBalance,
        collectionApprovals: {
          contractAddress: contracts.apeDaoVault.address,
          approvals,
          setApprovalForCollections,
          isLoading: approvalsAreLoading,
        },
        badges,
        refresh: refreshAccountInfo,
      });
    };

    doIt().catch((error) => console.log(error));
  }, [
    address,
    apeInBalance,
    apeInTokenInfo,
    approvals,
    approvalsAreLoading,
    chainName,
    hasPositiveBalance,
    isConnected,
    nativeBalance,
    refreshAccountInfo,
    setApprovalForCollections,
  ]);

  return <WalletInfoContext.Provider value={{ state: walletInfo }}>{props.children}</WalletInfoContext.Provider>;
};

export default WalletInfoProvider;
