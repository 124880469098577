import Big from 'big.js';
import { assetStatsKeyNameMapping } from 'helpers/backendApi/stats_v1';
import React, { useEffect } from 'react';
import { IStatsVotingPowerProps } from '../StatsVotingPower';

export interface IStatsVotingPowerAssetData {
  total: Big;
  assets: {
    name: string;
    value: Big;
    percentage: Big;
  }[];
}

function useStatsVotingPower(props: IStatsVotingPowerProps) {
  const [data, setData] = React.useState<IStatsVotingPowerAssetData>();

  useEffect(() => {
    const assetStats = props.stats.assetStats;

    const totalVotingPower = Big(assetStats.ogApe.votingPower ?? 0)
      .add(Big(assetStats.lilApe.votingPower ?? 0))
      .add(Big(assetStats.apeIn.votingPower ?? 0));

    setData({
      total: totalVotingPower,
      assets: [
        {
          name: assetStatsKeyNameMapping.ogApe,
          value: Big(assetStats.ogApe.votingPower),
          percentage: Big(assetStats.ogApe.votingPower ?? 0)
            .div(totalVotingPower)
            .mul(100),
        },
        {
          name: assetStatsKeyNameMapping.lilApe,
          value: Big(assetStats.lilApe.votingPower),
          percentage: Big(assetStats.lilApe.votingPower ?? 0)
            .div(totalVotingPower)
            .mul(100),
        },
        {
          name: assetStatsKeyNameMapping.apeIn,
          value: Big(assetStats.apeIn.votingPower),
          percentage: Big(assetStats.apeIn.votingPower ?? 0)
            .div(totalVotingPower)
            .mul(100),
        },
      ],
    });
  }, [props.stats.assetStats]);

  return {
    data: data ?? undefined,
  };
}

export default useStatsVotingPower;
