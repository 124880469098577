import { LOCAL_STORAGE_DARK_MODE } from 'helpers/storageHelpers';
import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useLocalStorage<boolean>(LOCAL_STORAGE_DARK_MODE, true);

  const toggleDarkMode = useCallback(() => {
    setDarkMode(!darkMode);
  }, [darkMode, setDarkMode]);

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (bodyElement) {
      bodyElement.classList.toggle('dark', darkMode);
    }
  }, [darkMode]);

  return {
    darkMode,
    toggleDarkMode,
  };
};

export default useDarkMode;
