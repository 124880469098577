import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FadeInOut } from 'components/index';
import useNumberInput from './hooks/useNumberInput';
import './NumberInput.css';

export interface INumberInputProps {
  className?: string;
  value: number;
  minValue?: number;
  maxValue?: number;
  visible?: boolean;
  onChange?: (value: number) => void;
}

function NumberInput(props: INumberInputProps) {
  const { value, setValue, increase, decrease } = useNumberInput(props);
  const valueNullSafe = value ?? 0;
  const visible = props.visible ?? true;
  const increaseDisabled = !visible || (props.maxValue != null && valueNullSafe >= props.maxValue);
  const decreaseDisabled = !visible || (props.minValue != null && valueNullSafe <= props.minValue);

  return (
    <FadeInOut isVisible={visible}>
      <div
        className={classNames(
          'flex max-w-max flex-row items-center rounded-full border border-apedao-black-100 bg-white dark:border-apedao-black-300 dark:bg-apedao-black-800',
          props.className
        )}
      >
        <MinusSmallIcon
          className={classNames(
            'h-7 w-7 rounded-full border-2 border-white bg-apedao-black-100 font-bold text-apedao-black-950 dark:bg-apedao-black-200 md:my-0.5 md:ml-0.5 md:h-5 md:w-5 md:border-0',
            { 'pointer-events-none cursor-default': !visible },
            { 'opacity-40': decreaseDisabled }
          )}
          onClick={decrease}
        />
        <input
          type="number"
          min={props.minValue ?? -999}
          max={props.maxValue ?? 999}
          className={classNames(
            'number-input mx-0 bg-white text-center text-sm selection:bg-transparent focus:outline-none dark:bg-apedao-black-800 dark:text-apedao-black-100',
            {
              'pointer-events-none cursor-default': !visible,
            }
          )}
          value={value ?? undefined}
          readOnly={!visible}
          onChange={(e) => setValue(e.target.value === '' ? null : parseInt(e.target.value, 10))}
          onBlur={(e) => setValue(e.target.value ? parseInt(e.target.value) : 0)}
        />
        <PlusSmallIcon
          className={classNames(
            'text-apedao-black-95 h-7 w-7 rounded-full border-2 border-white bg-apedao-black-100 font-bold text-apedao-black-950 dark:bg-apedao-black-200 md:my-0.5 md:mr-0.5 md:h-5 md:w-5 md:border-0',
            { 'pointer-events-none cursor-default': !visible },
            { 'opacity-40': increaseDisabled }
          )}
          onClick={increase}
        />
      </div>
    </FadeInOut>
  );
}

export default NumberInput;
