import { Migration, OriginalHome, Wallet } from 'pages';
import getIds from 'pages/App/getIds';
import Home from 'pages/Home';
import Leaderboard from 'pages/Leaderboard';
import Stats from 'pages/Stats';
import Vaults from 'pages/Vaults';
import React from 'react';
import { EvmChainName } from 'web3/chainsAndWallets';

export interface IRoute {
  id: keyof Omit<ReturnType<typeof getIds>, 'root' | 'header'>;
  label: string;
  path: string;
  authRequired: boolean;
  excludeFromNav?: boolean;
  createPageElement?: (id: string) => React.JSX.Element;
  openInNewTab?: boolean;
  onlyAvailableOnChains?: EvmChainName[];
}

export const routes: IRoute[] = [
  {
    id: 'home',
    label: 'Home',
    path: '/',
    excludeFromNav: true,
    createPageElement: (id: string) => <Home id={id} />,
    authRequired: false,
  },
  {
    id: 'original_home',
    label: 'Original Home',
    path: '/og-home',
    excludeFromNav: true,
    createPageElement: (id: string) => <OriginalHome id={id} />,
    authRequired: false,
  },
  {
    id: 'stats',
    label: 'Stats',
    path: '/stats',
    createPageElement: (id: string) => <Stats id={id} />,
    authRequired: false,
  },
  {
    id: 'leaderboard',
    label: 'Leaderboard',
    path: '/leaderboard',
    createPageElement: (id: string) => <Leaderboard id={id} />,
    authRequired: false,
  },
  {
    id: 'marketplace',
    label: 'Marketplace',
    path: '/marketplace',
    createPageElement: (id: string) => <Vaults id={id} />,
    authRequired: false,
    // onlyAvailableOnChains: [mainChain.chainName],
  },
  {
    id: 'wallet',
    label: 'My NFTs',
    path: '/wallet',
    createPageElement: (id: string) => <Wallet id={id} />,
    authRequired: false,
  },
  // {
  //   id: 'governance',
  //   label: 'Governance',
  //   path: 'https://gov.apedao.finance',
  //   openInNewTab: true,
  //   authRequired: false,
  // },
  {
    id: 'migration',
    label: 'Migration',
    path: '/migration',
    excludeFromNav: true,
    createPageElement: (id: string) => <Migration id={id} />,
    authRequired: false,
  },
];
