import { useRcpBucket } from 'contexts/RcpBucket';
import { useCallback } from 'react';
import { IAssetAmount } from 'types';
import { readContract } from 'viem/actions';
import { useClient } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import { APEIN_DECIMALS, IOTA_DECIMALS } from 'web3/constants';
import contracts from 'web3/contracts';

const useMagicSea = () => {
  const client = useClient({ chainId: mainChain.id });
  const { throttleRequest } = useRcpBucket();

  const convertToIotaAmount = useCallback(
    async (apeInAmount: number): Promise<IAssetAmount & { error?: string }> => {
      if (client) {
        try {
          await throttleRequest();
          const prices = await readContract(client, {
            ...contracts.magicSeaRouter,
            functionName: 'getAmountsIn',
            args: [
              BigInt(apeInAmount) * BigInt(Math.pow(10, APEIN_DECIMALS)),
              [contracts.tokenShimmer.address, contracts.apeDaoVault.address],
            ],
          });

          return { amount: prices[0], decimals: IOTA_DECIMALS };
        } catch (error) {
          console.error(error);
          const errorCode = (error as any)?.cause?.data?.args[0];
          return {
            amount: 0n,
            decimals: IOTA_DECIMALS,
            error:
              errorCode === 'ds-math-sub-underflow'
                ? 'Insufficient liquidity in LP'
                : 'Failed to fetch amount in wIOTA',
          };
        }
      }

      return { amount: 0n, decimals: APEIN_DECIMALS, error: 'No public client available.' };
    },
    [client, throttleRequest]
  );

  return {
    convertToIotaAmount: convertToIotaAmount,
  };
};

export default useMagicSea;
