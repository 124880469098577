import { ILeaderboardEntry, ILeaderboardResponse } from 'helpers/backendApi/leaderboard_v1';
import { httpGet } from 'helpers/fetch';
import { isDevMode } from 'helpers/storageHelpers';
import { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

const leaderboardURL = isDevMode()
  ? 'http://localhost:8000/v1/leaderboard'
  : 'https://backend.apedao.finance/v1/leaderboard';

function useLeaderboard() {
  const [entries, setEntries] = useState<ILeaderboardEntry[][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const updateEntries = () => {
    setIsLoading(true);
    httpGet<ILeaderboardResponse>(leaderboardURL)
      .then((response) => {
        const leaderboardEntries = response.parsedBody?.current.leaderboard ?? [];
        const groupedByPoints = leaderboardEntries.reduce((acc, entry) => {
          const points = parseInt(entry.points);
          if (!acc.has(points)) {
            acc.set(points, []);
          }
          acc.get(points)?.push(entry);
          return acc;
        }, new Map<number, ILeaderboardEntry[]>());

        const sortedPoints = Array.from(groupedByPoints.keys()).sort((a, b) => b - a);
        const sortedPointsWithoutZero = sortedPoints.filter((points) => points !== 0);
        const newEntries = sortedPointsWithoutZero.map((points) => groupedByPoints.get(points)!);

        setEntries(newEntries);
      })
      .catch(() => {
        setEntries([]);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateEntries();
  }, []);

  useInterval(() => {
    updateEntries();
  }, 30 * 60 * 1000);

  return {
    isLoading,
    error,
    leaderboardEntries: entries,
  };
}

export default useLeaderboard;
