import { IHeaderProps } from 'components/Header/Header';
import React from 'react';
import { Badge } from 'types';

function useHeader(props: IHeaderProps) {
  const [mobileNavOpen, setMobileNavOpen] = React.useState<boolean>(false);
  const [badge, setBadge] = React.useState<Badge>();

  React.useEffect(() => {
    if (props.walletInfo?.connected) {
      setMobileNavOpen(false);
    }
  }, [props.walletInfo?.connected]);

  React.useEffect(() => {
    if (props.walletInfo?.badges) {
      if (props.walletInfo?.badges.includes('goldApeDAOSupporter')) {
        setBadge('goldApeDAOSupporter');
      } else if (props.walletInfo?.badges.includes('silverApeDAOSupporter')) {
        setBadge('silverApeDAOSupporter');
      } else if (props.walletInfo?.badges.includes('bronzeApeDAOSupporter')) {
        setBadge('bronzeApeDAOSupporter');
      } else {
        setBadge(undefined);
      }
    }
  }, [props.walletInfo?.badges]);

  return {
    mobileNavOpen,
    setMobileNavOpen,
    badge,
  };
}

export default useHeader;
