import Big from 'big.js';
import React, { useEffect } from 'react';
import { IStatsPricesProps } from '../StatsPrices';

export interface IStatsPricesData {
  name: string;
  usd: Big;
  wiota?: Big;
  smr?: Big;
  usdDecimals: number;
  wiotaDecimals?: number;
  smrDecimals?: number;
  isToken?: boolean;
}

function useStatsPrices(props: IStatsPricesProps) {
  const [data, setData] = React.useState<(IStatsPricesData | 'delimiter')[]>();

  useEffect(() => {
    setData([
      {
        name: 'IOTA',
        usd: Big(props.stats.prices.IOTA_USD),
        usdDecimals: 6,
        wiota: Big(0),
        wiotaDecimals: 2,
        isToken: true,
      },
      // {
      //   name: 'SMR',
      //   usd: Big(props.stats.prices.SMR_USD),
      //   usdDecimals: 6,
      //   wiota: Big(0),
      //   wiotaDecimals: 2,
      //   isToken: true,
      // },
      {
        name: 'APEin',
        usd: Big(props.stats.prices.APEin_USD),
        usdDecimals: 6,
        wiota: Big(props.stats.prices.APEin_wIOTA),
        wiotaDecimals: 6,
        isToken: true,
      },
      'delimiter',
      {
        name: 'Floor OG Ape',
        usd: Big(props.stats.assetStats.ogApe.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        wiota: Big(props.stats.assetStats.ogApe.floorPrice?.wIOTA ?? 0),
        wiotaDecimals: 2,
      },
      {
        name: "Floor Lil' Ape",
        usd: Big(props.stats.assetStats.lilApe.floorPrice?.USD ?? 0),
        usdDecimals: 2,
        wiota: Big(props.stats.assetStats.lilApe.floorPrice?.wIOTA ?? 0),
        wiotaDecimals: 2,
      },
      // {
      //   name: 'Floor Banana',
      //   usd: Big(props.stats.assetStats.banana.floorPrice?.USD ?? 0),
      //   usdDecimals: 2,
      //   smr: Big(props.stats.assetStats.banana.floorPrice?.SMR ?? 0),
      //   smrDecimals: 2,
      // },
      // {
      //   name: 'Floor Blob',
      //   usd: Big(props.stats.assetStats.blob.floorPrice?.USD ?? 0),
      //   usdDecimals: 2,
      //   smr: Big(props.stats.assetStats.blob.floorPrice?.SMR ?? 0),
      //   smrDecimals: 2,
      // },
    ]);
  }, [
    // props.stats.assetStats.banana.floorPrice?.SMR,
    // props.stats.assetStats.banana.floorPrice?.USD,
    // props.stats.assetStats.blob.floorPrice?.SMR,
    // props.stats.assetStats.blob.floorPrice?.USD,
    props.stats.assetStats.lilApe.floorPrice?.SMR,
    props.stats.assetStats.lilApe.floorPrice?.USD,
    props.stats.assetStats.ogApe.floorPrice?.SMR,
    props.stats.assetStats.ogApe.floorPrice?.USD,
    props.stats.prices.APEin_wIOTA,
    props.stats.prices.APEin_USD,
    props.stats.prices.IOTA_USD,
    props.stats.prices.SMR_USD,
    props.stats.assetStats.ogApe.floorPrice?.wIOTA,
    props.stats.assetStats.lilApe.floorPrice?.wIOTA,
  ]);

  return data;
}

export default useStatsPrices;
