import React from 'react';

type IWithRoot = { root: string };
export type IDs<T extends string> = Record<T, string> & IWithRoot;

export function createIds<T extends string>(componentId: string, ...elementKeys: T[]): IDs<T> {
  const idsObject: any = {};

  idsObject['root'] = componentId;
  for (const element of elementKeys) {
    idsObject[element] = `${componentId === '' ? '' : componentId + '_'}${element}`;
  }

  return idsObject as IDs<T>;
}

export function useIds<T extends string>(componentId: string, getIds: (root: string) => IDs<T>): IDs<T> {
  const ids = React.useRef(getIds(componentId));
  return ids.current;
}
