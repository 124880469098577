import Big from 'big.js';
import { Spinner, StatsCard } from 'components';
import AssetDisplay from 'components/AssetDisplay';
import useStatsMyApeHoldings from 'components/StatsMyApeHoldings/hooks/useStatsMyApeHoldings';
import { formatBig } from 'helpers/formatHelpers';
import React from 'react';

export interface IHoldingAmounts {
  count: Big;
  inAPEIn: Big;
  inWIOTA: Big;
  inUSD: Big;
}

export interface IHolding extends IHoldingAmounts {
  name: string;
  note?: string;
  type: 'nft' | 'token';
}

export interface IMyApeHoldingsProps {
  holdings: IHolding[];
  loading: boolean;
  error: boolean;
  isDarkMode: boolean;
  mobileView: boolean;
  walletConnected: boolean;
}

const minAmountToBeDisplayed = Big(0.1);

export function StatsMyApeHoldings(props: IMyApeHoldingsProps) {
  const { total } = useStatsMyApeHoldings(props);

  const renderColumn = React.useCallback(
    (
      header: string,
      amountSupplier: (holding: IHolding) => Big,
      decimals: number,
      totalField: keyof IHoldingAmounts | undefined
    ) => {
      return (
        props.holdings && (
          <div className="flex flex-col items-end gap-2 sm:gap-1">
            <span className="whitespace-nowrap font-bold">{header}</span>
            {props.holdings
              .filter((holding) => (props.mobileView ? Big(amountSupplier(holding).toFixed(decimals)).gt(0) : true))
              .map((holding) => (
                <div key={holding.name} className="flex w-full flex-row whitespace-nowrap sm:gap-2">
                  {props.mobileView && <AssetDisplay key={holding.name} name={holding.name} note={holding.note} />}
                  <div className="flex w-full flex-row sm:gap-2">
                    <div className="grow text-end font-mono">
                      {props.loading ? (
                        <Spinner className="h-4 w-4" />
                      ) : amountSupplier(holding).gt(minAmountToBeDisplayed) ? (
                        formatBig(amountSupplier(holding), decimals)
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {totalField != null && (
              <div className="mt-2 flex w-full flex-row whitespace-nowrap sm:gap-2">
                <div className="flex w-full flex-row font-bold sm:gap-2">
                  {props.mobileView && <span>Total</span>}
                  <div className="grow text-end font-mono">
                    {props.loading ? (
                      <Spinner className="h-4 w-4" />
                    ) : (
                      formatBig(total != null ? Big(total[totalField]) : Big(0), decimals)
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      );
    },
    [props.holdings, props.loading, props.mobileView, total]
  );

  const statsPresent = Big(total?.inUSD.toFixed(2) ?? Big(0)).gt(0);
  const totalGtZero = (key: keyof IHoldingAmounts) => Big(total?.[key] ?? Big(0)).gt(0);

  return (
    <StatsCard title="My Ape Holdings" error={props.error}>
      {(!statsPresent || props.error) && !props.loading && (
        <div>
          {props.error
            ? 'Error on loading data from chain. Please try again later.'
            : props.walletConnected
            ? 'Soon Wukong III is still waiting for your offerings'
            : 'Connect wallet to see your personal stats'}
        </div>
      )}
      {!statsPresent && props.mobileView && props.loading && (
        <div className="flex flex-row justify-between">
          <span>Loading...</span>
          <Spinner className="h-4 w-4" />
        </div>
      )}
      {(statsPresent || (!props.mobileView && props.loading)) && !props.error && (
        <div className="relative flex flex-col gap-3 sm:flex-row sm:gap-5 md:gap-6">
          {!props.mobileView && (
            <>
              <div className="flex grow flex-col justify-end gap-2 pr-4 sm:gap-1">
                {props.holdings.map((holding) => (
                  <AssetDisplay key={holding.name} name={holding.name} note={holding.note} />
                ))}
                <span className="mt-2 font-bold">Total</span>
              </div>
              <hr className="absolute bottom-0 w-full -translate-y-[1.8rem]" />
            </>
          )}
          {(!props.mobileView || totalGtZero('count')) &&
            renderColumn('Count', (holding) => Big(holding.count ?? 0), 0, undefined)}
          {totalGtZero('count') && props.mobileView && <hr />}
          {renderColumn('APEin', (holding) => holding.inAPEIn, 0, 'inAPEIn')}
          {props.mobileView && <hr />}
          {renderColumn('wIOTA', (holding) => holding.inWIOTA, 0, 'inWIOTA')}
          {props.mobileView && <hr />}
          {renderColumn('USD', (holding) => holding.inUSD, 2, 'inUSD')}
        </div>
      )}
    </StatsCard>
  );
}

export default StatsMyApeHoldings;
