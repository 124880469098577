import { useWalletInfo } from 'contexts/WalletInfo';
import { msg } from 'helpers';
import { IClubInfo, IClubMemberRequest, IClubMemberResponse } from 'helpers/backendApi/club_v1';
import { httpGet, httpPost } from 'helpers/fetch';
import { isDevMode } from 'helpers/storageHelpers';
import { useCallback, useEffect, useState } from 'react';
import { useSignMessage } from 'wagmi';

const clubURL = isDevMode() ? 'http://localhost:8000/v1/club' : 'https://backend.apedao.finance/v1/club';

function useHome() {
  const [clubInfo, setClubInfo] = useState<IClubInfo | null>(null);
  const [walletIsMember, setWalletIsMember] = useState<boolean>(false);
  const [numberOfChains, setNumberOfChains] = useState<number>(0);

  const walletInfo = useWalletInfo();
  const { signMessage } = useSignMessage();

  const refreshClubInfo = useCallback(async () => {
    const response = await httpGet<IClubInfo>(`${clubURL}`);
    if (response.ok && response.parsedBody) {
      setClubInfo(response.parsedBody);
      setWalletIsMember(response.parsedBody.members.some((member) => member.address === walletInfo.address));

      const chainSet = new Set<string>();
      response.parsedBody.members.forEach((member) => {
        member.chains.forEach((chain) => {
          chainSet.add(chain);
        });
      });
      setNumberOfChains(chainSet.size);
    } else {
      setClubInfo(null);
    }
  }, [walletInfo.address]);

  useEffect(() => {
    refreshClubInfo().catch(() => {
      msg.error('Failed to fetch club info');
    });
  }, [refreshClubInfo]);

  const joinLeaveClub = useCallback(
    async (message: IClubMemberRequest['message']) => {
      signMessage(
        { message: message },
        {
          onSuccess: async (signature) => {
            try {
              const response = await httpPost<IClubMemberResponse>(clubURL, {
                address: walletInfo.address,
                message: message,
                signature: signature,
              });

              if (response.parsedBody && response.parsedBody.success) {
                await refreshClubInfo();
                msg.success(`You successfully ${message === 'join_club' ? 'joined' : 'left'} the club`);
              } else {
                msg.error(`Failed to ${message === 'join_club' ? 'join' : 'leave'} the club`);
              }
            } catch (e) {
              msg.error(`Failed to ${message === 'join_club' ? 'join' : 'leave'} the club`);
            }
          },
          onError: () => {
            msg.error('Failed to sign the message');
          },
        }
      );
    },
    [refreshClubInfo, signMessage, walletInfo.address]
  );

  const joinClub = useCallback(async () => {
    await joinLeaveClub('join_club');
  }, [joinLeaveClub]);

  const leaveClub = useCallback(async () => {
    await joinLeaveClub('leave_club');
  }, [joinLeaveClub]);

  return {
    walletInfo,
    walletIsMember,
    clubInfo,
    numberOfChains,
    joinClub,
    leaveClub,
  };
}

export default useHome;
