import { IconButton } from '@material-tailwind/react';
import classNames from 'classnames';
import { INftFilters } from 'types';
import { EvmChainName, getChainByEvmChainName } from 'web3/chainsAndWallets';

export interface INftFilterLocationProps {
  filters: INftFilters;
  evmChainNames: EvmChainName[];
}

function NftFilterChain(props: INftFilterLocationProps) {
  return (
    <div className="flex w-full flex-row justify-center gap-2.5">
      {props.evmChainNames.map((evmChainName) => (
        <NftFilterChainButton filters={props.filters} evmChainName={evmChainName} />
      ))}
    </div>
  );
}

function NftFilterChainButton(props: { filters: INftFilters; evmChainName: EvmChainName }) {
  return (
    <IconButton
      variant="text"
      color="amber"
      className={classNames(
        'relative h-8 w-8 origin-top-left overflow-clip rounded-full shadow-none transition-opacity hover:shadow-none',
        {
          'opacity-30 saturate-0 focus:opacity-30': !props.filters.chainFilter[props.evmChainName],
        }
      )}
      onClick={() =>
        props.filters.onChainFilterChange({
          ...props.filters.chainFilter,
          [props.evmChainName]: !props.filters.chainFilter[props.evmChainName],
        })
      }
    >
      <div className="absolute left-0 top-0 h-8 w-8 -translate-x-1/2 -translate-y-1/2 rounded-full">
        <img alt={props.evmChainName} src={getChainByEvmChainName(props.evmChainName)?.chainIconSrc} />
      </div>
    </IconButton>
  );
}

export default NftFilterChain;
