import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useIds } from 'helpers/idHelpers';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import VaultsAndWallet from 'pages/VaultsAndWallet';
import getIds from './getIds';
import { useSell, useWallet } from './hooks';

interface IWalletProps extends IBaseComponentProps {}

function Wallet(props: IWalletProps) {
  const ID = useIds(props.id, getIds);

  return (
    <VaultsAndWallet
      id={ID.root}
      variant="wallet"
      collections={ACTIVE_COLLECTIONS.filter((c) => c.featureWallet)}
      vaultWalletHook={useWallet}
      checkoutCartHook={useSell}
    />
  );
}

export default Wallet;
