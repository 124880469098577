import { Link } from 'components';
import React from 'react';
import { toast } from 'react-toastify';

const hash = (text: string) => {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const chr = text.charCodeAt(i);
    hash = ((hash << 5) - hash + chr) | 0;
  }

  return hash;
};

interface MessageOptions {
  autoClose?: boolean;
  closeOnClick?: boolean;
}

const showMessage = (
  content: string | React.ReactNode,
  type: 'info' | 'success' | 'warn' | 'error' = 'success',
  options?: MessageOptions
) => {
  const autoClose = options?.autoClose ?? true;
  const className = '!text-apedao-black-950 !bg-white dark:!bg-apedao-black-800 dark:!text-apedao-black-100';

  if (typeof content === 'string') {
    toast[type](content, {
      autoClose: autoClose ? 5000 : false,
      toastId: hash(content),
      closeOnClick: options?.closeOnClick ?? true,
      className,
    });
  } else if (React.isValidElement(content)) {
    toast[type](content, {
      autoClose: autoClose ? 5000 : false,
      toastId: content.props.id,
      closeOnClick: options?.closeOnClick ?? true,
      className,
    });
  }
};

const msg = {
  info: (content: string | React.ReactNode, options?: MessageOptions) => showMessage(content, 'info', options),
  success: (content: string | React.ReactNode, options?: MessageOptions) => showMessage(content, 'success', options),
  warn: (content: string | React.ReactNode, options?: MessageOptions) => showMessage(content, 'warn', options),
  error: (content: string | React.ReactNode, options?: MessageOptions) => showMessage(content, 'error', options),
  txHashInfo: (text: string, explorerLink: string) => {
    msg.info(
      <div className="flex flex-col">
        {text}
        <Link href={`${explorerLink}`}>Show on Explorer</Link>
      </div>,
      { autoClose: false, closeOnClick: false }
    );
  },
};

export default msg;
