import { Button, Typography } from '@material-tailwind/react';
import Big from 'big.js';

import classNames from 'classnames';
import { ConnectButton, PageTemplate, RotatingImages, SocialIcons } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useDarkMode } from 'helpers';
import { formatBig } from 'helpers/formatHelpers';
import { shuffleArray } from 'helpers/randomHelpers';
import links from 'links';
import useHome from 'pages/Home/hooks';
import React, { useRef } from 'react';

export interface IClubProps extends IBaseComponentProps {}

function Home(props: IClubProps) {
  const { walletInfo, walletIsMember, clubInfo, numberOfChains, joinClub, leaveClub } = useHome();
  const { darkMode } = useDarkMode();

  const randomizedHomeImages = useRef(
    shuffleArray([
      '/images/home_1.webp',
      '/images/home_2.webp',
      '/images/home_3.webp',
      '/images/home_4.webp',
      '/images/home_5.webp',
      '/images/home_6.webp',
      '/images/home_7.webp',
      '/images/home_8.webp',
    ])
  );

  return (
    <PageTemplate walletInfo={walletInfo} centerChildren={true}>
      <div className="mx-7 my-14 flex max-w-[100vw] flex-col sm:mx-10 md:mx-20 md:my-20 md:max-w-[1024px]">
        <div className="flex flex-col items-center gap-5 border-b border-apedao-black-950 dark:border-apedao-black-800 lg:flex-row lg:items-end lg:gap-16">
          <div className="flex flex-col gap-5 lg:w-1/2 lg:py-10">
            <Typography variant="h5" className="uppercase">
              Investment-Focused Phantom Liquidity Hub
            </Typography>
            <Typography variant="h1" className="uppercase">
              ApeDAO
            </Typography>
            <Typography variant="h6">
              As an ApeDAO participant, you’re part of a dynamic, decentralized organization dedicated to maximizing
              returns and rewarding its community.
            </Typography>
            {!walletIsMember && walletInfo.connected && (
              <Button variant={darkMode ? 'outlined' : 'filled'} className="mt-4 w-fit" onClick={joinClub}>
                Join Club
              </Button>
            )}
            {walletIsMember && walletInfo.connected && (
              <Button variant={darkMode ? 'outlined' : 'filled'} className="mt-4 w-fit" onClick={leaveClub}>
                Leave Club
              </Button>
            )}
            {!walletInfo.connected && (
              <div className="w-fit">
                <ConnectButton id="connasdf" showChain={false} />
              </div>
            )}
            <SocialIcons className="gap-2" />
          </div>
          <RotatingImages
            imageURLs={randomizedHomeImages.current}
            imageRotationTimeMs={6000}
            numberOfTiles={10}
            className="max-w-[500px] lg:w-1/2 lg:min-w-[400px]"
          />
        </div>

        <div className="flex w-full flex-col items-center justify-between border-b border-apedao-black-950 py-8 dark:border-apedao-black-800 lg:flex-row">
          <ClubStats value={`$ ${formatBig(Big(clubInfo?.tvlUSDTotal ?? '0'), 0)}`} label="Boosted Ape TVL" />
          <ClubStats value={(clubInfo?.members.length ?? 0) + ''} label="Wallets" />
          <ClubStats value={numberOfChains + ''} label="Chains" />
          <ClubStats value="1" label="Partner" />
        </div>

        <HomeArea title="Why ApeDAO?">
          <div className="mb-4 text-center sm:w-3/4 lg:mb-0">
            We are an investment-focused DAO and a "phantom" liquidity hub, designed to empower users through
            partnership-driven rewards and innovative growth opportunities.
          </div>
          <div className="grid w-full grid-cols-1 gap-8 sm:w-3/4 md:w-full md:grid-cols-3">
            <div className="border-l border-amber-500 py-2 pl-4">
              <span className="font-semibold text-amber-500">Invest Smarter: </span>
              <br className="hidden md:inline lg:hidden" />
              ApeDAO leverages the collective power of its members to negotiate partnerships and secure investment
              opportunities that drive sustainable growth.
            </div>
            <div className="border-l border-amber-500 py-2 pl-4">
              <span className="font-semibold text-amber-500">Boosted Rewards: </span>
              <br className="hidden md:inline lg:hidden" />
              By engaging with our partner protocols, ApeDAO participants unlock enhanced yields and exclusive
              incentives.
            </div>
            <div className="border-l border-amber-500 py-2 pl-4">
              <span className="font-semibold text-amber-500">No Hidden Fees: </span>
              <br className="hidden md:inline lg:hidden" />
              ApeDAO doesn't take a cut. All rewards flow directly to the community, ensuring maximum benefit for each
              participant.
            </div>
          </div>
        </HomeArea>

        <HomeArea title="How it Works">
          <div className="flex flex-col items-start gap-6 sm:w-3/4 lg:gap-10">
            <ClubTutorialItem
              title="Join the DAO"
              description="By holding an Ape NFT, you become an official member of ApeDAO, gaining access to our investment treasury and liquidity rewards."
            />
            <ClubTutorialItem
              title="Boost Your Earnings"
              description="Engage with partner protocols, and ApeDAO will negotiate better yields and unique rewards for the community."
            />
            <ClubTutorialItem
              title="Earn More"
              description="As ApeDAO grows, so do your opportunities to earn and participate in governance."
            />
          </div>
        </HomeArea>

        <HomeArea title="Connect Your Wallet & Get Started">
          <div className="flex flex-col items-start gap-6 sm:w-3/4 lg:gap-10">
            <ClubTutorialItem
              numbering={1}
              title="Sign a message"
              description="...to verify your wallet and begin tracking your positions across Web3."
            />
            <ClubTutorialItem
              numbering={2}
              title="Boost your rewards"
              description="...by investing in partner protocols and maximizing your returns."
            />
            <ClubTutorialItem
              numbering={3}
              title="Earn More with ApeDAO"
              description="...by growing your stake in the future of decentralized finance."
            />
          </div>
        </HomeArea>

        <HomeArea title="Join the movement today">
          <div className="text-center">
            Start earning with ApeDAO - where your NFT opens the door to DeFi's most exciting opportunities.
          </div>
        </HomeArea>

        <HomeArea title="Still have questions?" lastItem={true}>
          <div className="flex flex-col items-center gap-8">
            <div className="text-center">
              Feel free to join our Discord server and chat with fellow degens or boost your knowledge reading the docs.
            </div>
            <div className="flex flex-row gap-6">
              <a href={links.discord} target="_blank" rel="noreferrer">
                <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit">
                  Join Discord
                </Button>
              </a>
              <a href={links.docs} target="_blank" rel="noreferrer">
                <Button variant={darkMode ? 'outlined' : 'filled'} className="w-fit">
                  Learn More
                </Button>
              </a>
            </div>
          </div>
        </HomeArea>
      </div>
    </PageTemplate>
  );
}

function HomeArea(props: { title?: string; lastItem?: boolean; children: React.ReactNode }) {
  return (
    <div
      className={classNames(
        'flex flex-col items-center gap-10 border-apedao-black-950 pt-12 dark:border-apedao-black-800 lg:gap-12 lg:pt-20',
        { 'border-b pb-12 lg:pb-20': !props.lastItem }
      )}
    >
      {props.title && <span className="text-2xl font-semibold sm:text-3xl">{props.title}</span>}
      {props.children}
    </div>
  );
}

function ClubStats(props: { value: string; label: string }) {
  return (
    <div className="grid grid-cols-2 items-center gap-4 lg:flex lg:flex-row lg:justify-end">
      <span className="whitespace-nowrap text-end text-[1.4rem] font-semibold text-amber-500 sm:text-[1.8rem]">
        {props.value}
      </span>
      <span className="whitespace-nowrap">{props.label}</span>
    </div>
  );
}

function ClubTutorialItem(props: { numbering?: number; title: string; description: string }) {
  return (
    <div className="flex flex-row gap-4 sm:gap-6">
      {props.numbering && <span className="font-mono text-xl text-amber-500">{props.numbering}</span>}
      <div
        className={classNames('flex flex-col gap-3 pl-0 sm:border-l sm:pl-6', {
          'border-amber-500': !props.numbering || true,
        })}
      >
        <span className={classNames('text-lg font-medium sm:text-xl', { 'text-amber-500': !props.numbering || true })}>
          {props.title}
        </span>
        <span>{props.description}</span>
      </div>
    </div>
  );
}

export default Home;
