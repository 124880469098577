import classNames from 'classnames';

const tokenImages = {
  APEin: 'apein.svg',
  BiTC: 'BiTC.svg',
  DEEPR: 'deepr.png',
  ETH: 'eth.svg',
  IOTA: 'iota.png',
  INU: 'inu.svg',
  LUM: 'lum.svg',
  MLUM: 'mlum.svg',
  sIOTA: 'siota.svg',
  wIOTA: 'wiota.svg',
  SMR: 'smr.png',
  SOON: 'soon.png',
  sSOON: 'ssoon.svg',
  RUST: 'rust.svg',
  'USDC.e': 'USDC.e.svg',
  USDT: 'usdt.svg',
  WBTC: 'wbtc.svg',
  WEN: 'wen.svg',
  WETH: 'wETH.svg',
  'OG Apes': 'ogApe.png',
  "Lil' Apes": 'lilApe.png',
  Bananas: 'banana.png',
  Blobs: 'blob.png',
  $ANTE: 'ante.svg',
  FOMO: 'fomo.svg',
};

export interface ITokenDisplayProps {
  name: keyof typeof tokenImages | string;
  note?: string;
  showIcon?: boolean;
  className?: string;
}

function AssetDisplay(props: ITokenDisplayProps) {
  return (
    <div className={classNames('flex flex-row gap-2 whitespace-nowrap ', props.className)}>
      {(props.showIcon == null || props.showIcon) &&
        ((tokenImages as any)[props.name] ? (
          <img
            alt={props.name}
            className="h-[1.25rem] w-[1.25rem] rounded-full"
            src={`/images/assets/${(tokenImages as any)[props.name]}`}
          />
        ) : (
          <div className="flex h-[1.25rem] w-[1.25rem] items-center justify-center rounded-full border text-[0.75em]">
            ?
          </div>
        ))}
      <div className="flex gap-1">
        <span>{props.name}</span>
        <span className="scale-75 opacity-50">{props.note}</span>
      </div>
    </div>
  );
}

export default AssetDisplay;
