import { useWalletInfo } from 'contexts/WalletInfo';
import { msg } from 'helpers';
import { calculateTotalPriceAndFees, isApeInFundsSufficient, isIotaFundsSufficient } from 'helpers/cartHelpers';
import { groupCartItemsByCollectionId } from 'helpers/nftHelpers';
import useMagicSea from 'helpers/useMagicSea';
import { useOnCheckout } from 'pages/VaultsAndWallet/hooks';
import { ICartItem, ICollection } from 'types';
import { readContract, waitForTransactionReceipt } from 'viem/actions';
import { useClient, useWriteContract } from 'wagmi';
import { mainChain } from 'web3/chainsAndWallets';
import contracts from 'web3/contracts';

export function useBuy(getCurrentVaultNftIds: (collection: ICollection) => Promise<BigInt[]>) {
  const walletInfo = useWalletInfo();
  const magicSea = useMagicSea();
  const client = useClient({ chainId: mainChain.id });

  const { writeContractAsync: writeAsyncApeDAOVault } = useWriteContract();
  const { writeContractAsync: writeAsyncApeDAOMarket } = useWriteContract();
  const { writeContractAsync: writeAsyncIotaErc20 } = useWriteContract();

  const doBuyWithApeIn = async (
    firstNftsParam: bigint[],
    firstRandomNftsQuantity: bigint,
    secondNftsParam: bigint[],
    secondRandomNftsQuantity: bigint
  ) => {
    const hash = await writeAsyncApeDAOVault({
      ...contracts.apeDaoVault,
      functionName: 'redeem',
      args: [
        BigInt(firstNftsParam.length) + firstRandomNftsQuantity,
        BigInt(secondNftsParam.length) + secondRandomNftsQuantity,
        firstNftsParam,
        secondNftsParam,
      ],
    });
    client && (await waitForTransactionReceipt(client, { hash }));
    return hash;
  };

  const doBuyWithIota = async (
    firstNftsParam: bigint[],
    firstRandomNftsQuantity: bigint,
    secondNftsParam: bigint[],
    secondRandomNftsQuantity: bigint,
    totalPrice: bigint
  ) => {
    if (walletInfo.address == null || !client) {
      msg.error('You have to be connected to buy with wIOTA!');
      return false;
    }

    const allowanceAmount = await readContract(client, {
      ...contracts.tokenShimmer,
      functionName: 'allowance',
      args: [walletInfo.address, contracts.apeDaoMarket.address],
    });

    if (allowanceAmount < totalPrice) {
      const hash = await writeAsyncIotaErc20({
        ...contracts.tokenShimmer,
        functionName: 'approve',
        args: [contracts.apeDaoMarket.address, totalPrice],
      });
      await waitForTransactionReceipt(client, { hash });
    }

    const hash = await writeAsyncApeDAOMarket({
      ...contracts.apeDaoMarket,
      functionName: 'buyAndRedeemIOTA',
      args: [
        BigInt(firstNftsParam.length) + firstRandomNftsQuantity,
        BigInt(secondNftsParam.length) + secondRandomNftsQuantity,
        firstNftsParam,
        secondNftsParam,
        totalPrice,
        [contracts.tokenShimmer.address, contracts.apeDaoVault.address],
        walletInfo.address!,
      ],
    });
    await waitForTransactionReceipt(client, { hash });
    return hash;
  };

  const doBuy = async (cartItems: ICartItem[], buyWithIota?: boolean): Promise<`0x${string}` | false> => {
    const { nfts: firstNftsParam, randomQuantity: firstRandomNftsQuantity } = getNftsAndRandomQuantityForCollection(
      cartItems,
      0
    );

    const { nfts: secondNftsParam, randomQuantity: secondRandomNftsQuantity } = getNftsAndRandomQuantityForCollection(
      cartItems,
      1
    );

    const totalPriceAndFees = calculateTotalPriceAndFees(cartItems, 'buy');

    let txHash: `0x${string}` | false = false;
    if (buyWithIota) {
      const totalIotaPrice = await magicSea.convertToIotaAmount(totalPriceAndFees.price);
      if (walletInfo.nativeBalance == null || !isIotaFundsSufficient(walletInfo.nativeBalance, totalIotaPrice)) {
        msg.error(`Insufficient funds in wallet!`);
        return false;
      }

      txHash = await doBuyWithIota(
        firstNftsParam,
        firstRandomNftsQuantity,
        secondNftsParam,
        secondRandomNftsQuantity,
        totalIotaPrice.amount
      );
    } else {
      if (
        walletInfo.apeInBalance == null ||
        !isApeInFundsSufficient(walletInfo.apeInBalance, totalPriceAndFees.price)
      ) {
        msg.error(`Insufficient funds in wallet!`);
        return false;
      }

      txHash = await doBuyWithApeIn(firstNftsParam, firstRandomNftsQuantity, secondNftsParam, secondRandomNftsQuantity);
    }

    return txHash;
  };

  const getSuccessMessage = (cartItems: ICartItem[]) => {
    const totalNumberOfNfts = getTotalNumberOfNfts(cartItems);
    return `${totalNumberOfNfts} NFT${totalNumberOfNfts > 1 ? 's' : ''} successfully bought from ApeDAO vault`;
  };

  const getErrorMessage = (cartItems: ICartItem[]) => {
    const totalNumberOfNfts = getTotalNumberOfNfts(cartItems);
    return `Buying NFT${totalNumberOfNfts > 1 ? 's' : ''} failed`;
  };

  return useOnCheckout(doBuy, getCurrentVaultNftIds, getSuccessMessage, getErrorMessage);
}

function getTotalNumberOfNfts(cartItems: ICartItem[]): number {
  return cartItems.reduce((acc, cartItem) => {
    return acc + cartItem.quantity;
  }, 0);
}

function getNftsAndRandomQuantityForCollection(
  cartItems: ICartItem[],
  collectionId: number
): { nfts: bigint[]; randomQuantity: bigint } {
  const nftQuantitiesPerCollectionId = groupCartItemsByCollectionId(cartItems);
  const nftQuantitiesOfCollection = nftQuantitiesPerCollectionId.get(collectionId) ?? [];

  const nfts = nftQuantitiesOfCollection
    .filter((cartItem) => cartItem.nft.type === 'nft')
    .map((cartItem) => BigInt(cartItem.nft.edition));

  const randomQuantity =
    nftQuantitiesOfCollection
      .filter((cartItem) => cartItem.nft.type === 'random')
      .map((cartItem) => cartItem.quantity)[0] ?? 0;

  return { nfts, randomQuantity: BigInt(randomQuantity) };
}
