import Big from 'big.js';
import { ITreasuryStats } from 'helpers/backendApi/stats_v1';
import React, { useEffect } from 'react';
import { IStatsTreasuryProps } from '../StatsTreasury';

type Network = 'smrEvm' | 'iotaEvm';

const symbolFix: Record<string, string> = {
  soon: 'SOON',
};

const geckoterminalMapping: Record<string, string> = {
  soon: 'sSOON',
  wIOTA: 'IOTA',
  sIOTA: 'IOTA',
};

const nativeTokensConsideredToBeStakedIfLocked: string[] = ['soon'];

export interface IStatsTreasuryToken {
  symbol: string;
  amountInWallet: Big;
  amountInLPAndFarming: Big;
  amountStaked: Big;
  amountTotal: Big;
  amountInUSD: Big;
}

function useStatsTreasury(props: IStatsTreasuryProps) {
  const [data, setData] = React.useState<{ tokens: IStatsTreasuryToken[]; totalUSD: Big }>();

  const getPriceInUSD = React.useCallback(
    (network: Network, symbol: string) => {
      if (symbol === 'IOTA') {
        return Big(props.stats.prices.IOTA_USD);
      } else if (symbol === 'SMR') {
        return Big(props.stats.prices.SMR_USD);
      } else {
        const price = Object.keys(props.stats.prices.geckoterminal[network]).find(
          (key) => key.toUpperCase() === symbol.toUpperCase()
        );
        return price ? Big(props.stats.prices.geckoterminal[network][price].USD) : Big(0);
      }
    },
    [props.stats.prices.IOTA_USD, props.stats.prices.SMR_USD, props.stats.prices.geckoterminal]
  );

  useEffect(() => {
    const tStats: ITreasuryStats = props.stats.treasuryStats;

    const iotaL1Staked = filterStakedL1Tokens(tStats.iotaL1.lockedNativeTokens);
    const smrL1Staked = filterStakedL1Tokens(tStats.smrL1.lockedNativeTokens);

    let tokenMap: Map<string, IStatsTreasuryToken> = new Map();
    tokenMap = addTreasuryTokens(tokenMap, { IOTA: tStats.iotaL1.unlocked }, 'wallet', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, { SMR: tStats.smrL1.unlocked }, 'wallet', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.iotaL1.unlockedNativeTokens ?? {}, 'wallet', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.smrL1.unlockedNativeTokens ?? {}, 'wallet', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.iotaEvm.inWallets, 'wallet', getPriceInUSD, 'iotaEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.iotaEvm.inLPsAndFarms, 'lp', getPriceInUSD, 'iotaEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.iotaEvm.inStakingPools, 'staking', getPriceInUSD, 'iotaEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.smrEvm.inWallets, 'wallet', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.smrEvm.inLPsAndFarms, 'lp', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, tStats.smrEvm.inStakingPools, 'staking', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, iotaL1Staked, 'staking', getPriceInUSD, 'smrEvm');
    tokenMap = addTreasuryTokens(tokenMap, smrL1Staked, 'staking', getPriceInUSD, 'smrEvm');
    const tokens = [...tokenMap.values()].sort(compareFn);
    const totalUSD = tokens.reduce((acc, token) => acc.add(token.amountInUSD), Big(0));

    setData({ tokens: tokens, totalUSD: totalUSD });
  }, [getPriceInUSD, props.stats.treasuryStats]);

  return data;
}

function filterStakedL1Tokens(tokens: Record<string, string>): Record<string, string> {
  return Object.keys(tokens)
    .filter((entry) => {
      return nativeTokensConsideredToBeStakedIfLocked.includes(entry);
    })
    .reduce((obj: any, key) => {
      obj[key] = tokens[key];
      return obj;
    }, {});
}

function addTreasuryTokens(
  tokenMap: Map<string, IStatsTreasuryToken>,
  tokens: Record<string, string>,
  status: 'wallet' | 'lp' | 'staking',
  getPriceInUSD: (network: Network, symbol: string) => Big,
  usePriceOfNetwork: Network
): Map<string, IStatsTreasuryToken> {
  const newTreasuryTokens: Map<string, IStatsTreasuryToken> = new Map(tokenMap);
  Object.keys(tokens).forEach((apiSymbol) => {
    const symbol = symbolFix[apiSymbol] ? symbolFix[apiSymbol]! : apiSymbol;
    const amount = Big(tokens[apiSymbol]);
    const amountInUSD = getPriceInUSD(usePriceOfNetwork, geckoterminalMapping[apiSymbol] ?? symbol).mul(amount);

    if (newTreasuryTokens.has(symbol)) {
      const token = newTreasuryTokens.get(symbol)!;
      token.amountInWallet = status === 'wallet' ? token.amountInWallet.add(amount) : token.amountInWallet;
      token.amountInLPAndFarming =
        status === 'lp' ? token.amountInLPAndFarming.add(amount) : token.amountInLPAndFarming;
      token.amountStaked = status === 'staking' ? token.amountStaked.add(amount) : token.amountStaked;
      token.amountTotal = token.amountTotal.add(amount);
      token.amountInUSD = token.amountInUSD.add(amountInUSD);
    } else {
      newTreasuryTokens.set(symbol, {
        symbol: symbol,
        amountInWallet: status === 'wallet' ? amount : Big(0),
        amountInLPAndFarming: status === 'lp' ? amount : Big(0),
        amountStaked: status === 'staking' ? amount : Big(0),
        amountTotal: amount,
        amountInUSD: amountInUSD,
      });
    }
  });

  return newTreasuryTokens;
}

const mainAssetOrder = ['IOTA', 'sIOTA', 'wIOTA', 'SMR'];

function compareFn(a: IStatsTreasuryToken, b: IStatsTreasuryToken) {
  if (mainAssetOrder.includes(a.symbol) && mainAssetOrder.includes(b.symbol)) {
    return mainAssetOrder.indexOf(a.symbol) - mainAssetOrder.indexOf(b.symbol);
  } else if (mainAssetOrder.includes(a.symbol)) {
    return -1;
  } else if (mainAssetOrder.includes(b.symbol)) {
    return 1;
  } else {
    return a.symbol.localeCompare(b.symbol);
  }
}

export default useStatsTreasury;
