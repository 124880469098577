import { useMemo } from 'react';
import { useChainId } from 'wagmi';
import { EvmChainName, IExtendedChain, supportedChainsById } from 'web3/chainsAndWallets';

const useChain = (): { chain?: IExtendedChain; chainName?: EvmChainName } => {
  const chainId = useChainId();
  const chain = useMemo(() => supportedChainsById[chainId], [chainId]);

  return { chain, chainName: chain?.chainName };
};

export default useChain;
