import { IImageProps } from 'components/Image/Image';
import React, { useEffect } from 'react';

function useImage(props: IImageProps) {
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const [lowResImgLoaded, setLowResImgLoaded] = React.useState(false);
  const [dropLowResImage, setDropLowResImage] = React.useState(false);
  const [imgError, setImgError] = React.useState(false);
  const [lowResImgError, setLowResImgError] = React.useState(false);

  useEffect(() => {
    if (imgLoaded) {
      setTimeout(() => setDropLowResImage(true), 500);
    }
  }, [imgLoaded]);

  useEffect(() => {
    if (imgError) {
      props.onError && props.onError();
    }
  }, [imgError, props]);

  const showImage = imgLoaded;
  const showLowResImage = lowResImgLoaded && !imgLoaded;
  const showErrorImage = !showImage && imgError && (!props.lowResPlaceholderSrc || lowResImgError || lowResImgLoaded);

  return {
    showImage,
    showLowResImage,
    showErrorImage,
    dropLowResImage,
    onImgLoaded: () => setImgLoaded(true),
    onLowResImgLoaded: () => setLowResImgLoaded(true),
    onImgError: () => setImgError(true),
    onLowResImgError: () => setLowResImgError(true),
  };
}

export default useImage;
