import { isDevMode } from 'helpers/storageHelpers';

const links = {
  discord: 'https://discord.gg/WDxt6xZDDh',
  twitter: 'https://twitter.com/0xApeDAO',
  twitterAlphaRho: 'https://twitter.com/0xAlphaRho',
  medium: 'https://0xApeDAO.medium.com/',
  telegram: 'https://t.me/iotapes',
  github: 'https://github.com/iotapes',
  docs: 'https://docs.apedao.finance',
  magicSea: 'https://app.magicsea.finance',
  magicSeaOgApesMarket:
    'https://app.magicsea.finance/nfts/collections/:8822/0xC70516C114003202C6b6cD37972B8d9f5e7D05E3',
  magicSeaLilApesMarket:
    'https://app.magicsea.finance/nfts/collections/:8822/0xE96661680Dde025d112cfB915071FB3B8f7d1fd0',
  magicSeaLP: 'https://app.magicsea.finance/liquidityv2/manual/:8822/add/0x1ca7c9eaa3a06a9d9add5ef1f43647fa7ee9007c',
  magicSeaFarm: 'https://shimmersea.finance/farms/detail/0xf187872D281C001552687479c8b92A0D45f0463F',
  accumulator: 'https://app.accumulator.finance/vaults/shimmer-shimmer-apein',
  epochZeroNFTMarket: 'https://nft.epochzero.io',
  layerZeroExplorer: isDevMode() ? 'https://testnet.layerzeroscan.com' : 'https://layerzeroscan.com',
  safe: 'https://safe.iotaledger.net/',
};

export default links;
