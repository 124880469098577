import { CloudArrowDownIcon, HeartIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Typography,
} from '@material-tailwind/react';
import classNames from 'classnames';
import { BridgePanel, FadeInOut, Image, NftChainIcon, NftInfoDialogLocation } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import useNftInfoDialog from 'components/NftInfoDialog/hooks/useNftInfoDialog';
import { useChainName, useLayerZeroBridge } from 'helpers';
import { formatNumber } from 'helpers/formatHelpers';
import { useIds } from 'helpers/idHelpers';
import { INft } from 'types';
import { getChainByEvmChainName } from 'web3/chainsAndWallets';
import './NftInfoDialog.css';
import getIds from './getIds';

export interface NftInfoDialogProps extends IBaseComponentProps {
  nft?: INft;
  mode: 'buy' | 'sell';
  display: boolean;
  isFavorite: boolean;
  isInCart: boolean;
  lzBridgeHandler?: ReturnType<typeof useLayerZeroBridge>;
  onDisplay: () => void;
  onSetFavorite: (favorite: boolean) => void;
}

function DetailsHeaderBar(props: { title: string }) {
  return (
    <div className="flex flex-row justify-center rounded-md border border-apedao-black-100 bg-apedao-black-100 py-0 text-xs font-semibold tracking-widest dark:border-apedao-black-500 dark:bg-apedao-black-500 dark:font-normal">
      {props.title}
    </div>
  );
}

function NftInfoDialog(props: NftInfoDialogProps) {
  const ID = useIds(props.id, getIds);
  const { priceDetails, showPriceInfo, switchToNetworkOfNft, nftLocation } = useNftInfoDialog(props);
  const chainName = useChainName();

  if (props.nft == null || !props.display) {
    return <div></div>;
  }

  const onBridgeState = props.lzBridgeHandler?.nftsOnBridge[props.nft.id];

  return (
    <Dialog
      open={props.display}
      handler={props.onDisplay}
      className="w-[90vw] min-w-[90vw] max-w-[90vw] sm:w-3/4 sm:min-w-fit sm:max-w-[60rem]"
      size="lg"
      dismiss={{ outsidePress: false }}
    >
      <DialogHeader className="p3 min-h-[68px] justify-between md:min-h-[80px] md:p-4">
        <div className="flex items-center gap-3">
          <div className="-mt-px flex flex-col">
            <div className="flex flex-row items-center gap-1.5 sm:gap-2">
              <Typography className="text-sm font-medium md:text-lg">{props.nft.name}</Typography>
              {nftLocation && (
                <span className="origin-left -translate-y-[0.1em] scale-100 md:-translate-y-[0.05em] md:scale-110">
                  <NftChainIcon location={nftLocation} tooltip={false} />
                </span>
              )}
            </div>
            <Typography className="text-xs font-normal opacity-80 md:text-sm">
              Rank {props.nft.rank <= 0 ? '???' : props.nft.rank}/{props.nft.collection.totalNumber}
            </Typography>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <IconButton variant="text" size="sm" onClick={props.onDisplay} className="text-inherit opacity-80">
            <XMarkIcon className="h-7 w-7" />
          </IconButton>
        </div>
      </DialogHeader>
      <DialogBody
        id={ID.body}
        divider={true}
        className="grid max-h-[calc(90vh-140px)] grid-cols-1 items-start gap-4 overflow-y-auto overflow-x-clip md:max-h-[calc(90vh-160px)] lg:grid-cols-2"
      >
        <Image
          src={`${props.nft.hqImage}`}
          lowResPlaceholderSrc={props.nft.image}
          className="mx-auto aspect-square max-w-[30rem] rounded-md bg-white dark:border-apedao-black-500 dark:bg-apedao-black-800"
          alt={props.nft.name}
        />
        <div className="flex h-full flex-col gap-1.5 md:gap-2.5">
          <div className="flex flex-col gap-4">
            <DetailsHeaderBar title="Traits" />
            <div className="grid grid-cols-2 gap-1.5 md:gap-2.5">
              {[...props.nft.nftAttributes.entries()].map(([attributeName, traitName]) => (
                <div
                  key={attributeName}
                  className={classNames(
                    'relative flex h-fit grow flex-col justify-center rounded-md border border-apedao-black-100 bg-gradient-to-br from-white via-amber-50 to-white p-1.5 text-sm shadow-sm md:p-3',
                    'dark:border-apedao-black-500 dark:bg-none'
                  )}
                >
                  <span className="h-[1rem] whitespace-nowrap text-[0.7em] font-bold uppercase tracking-wider opacity-80">
                    {attributeName}
                  </span>
                  <div className="h-[1rem] whitespace-nowrap text-[0.8em] font-extrabold uppercase tracking-wider text-amber-800">
                    {traitName}
                  </div>
                  <span className="absolute right-2 top-1 text-[0.75em] font-bold opacity-60 dark:opacity-50">
                    {props.nft?.collection.attributes.get(attributeName)?.get(traitName)}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <FadeInOut
            isVisible={
              (nftLocation?.tag === 'wallet' || onBridgeState != null) &&
              props.lzBridgeHandler != null &&
              props.lzBridgeHandler.supportedTargetChains.length > 0 &&
              !props.isInCart
            }
          >
            <div className="mt-3 flex flex-col gap-4">
              <DetailsHeaderBar title="Bridge" />
              {chainName === props.nft.location.chain || onBridgeState != null ? (
                props.lzBridgeHandler && <BridgePanel lzBridgeHandler={props.lzBridgeHandler} />
              ) : (
                <div className="text-sm">
                  <Button
                    size="sm"
                    className="px-1 py-1"
                    variant="text"
                    onClick={() => {
                      props.nft && switchToNetworkOfNft(props.nft);
                    }}
                  >
                    Connect
                  </Button>{' '}
                  to "{getChainByEvmChainName(props.nft.location.chain)?.name}" to send this NFT to another chain.
                </div>
              )}
            </div>
          </FadeInOut>
          <FadeInOut isVisible={nftLocation?.tag != null && onBridgeState == null} unmountOnExit={true}>
            <div className="mt-2 flex grow flex-col justify-end">
              <NftInfoDialogLocation nft={{ ...props.nft, location: nftLocation! }} />
            </div>
          </FadeInOut>
        </div>
      </DialogBody>
      <DialogFooter className="min-h-[72px] items-end p-3 md:min-h-[80px] md:p-4">
        <div className="flex w-full flex-row items-end justify-end gap-4">
          {props.nft.originalImage && (
            <a href={props.nft.originalImage} target="_blank" rel="noopener noreferrer">
              <Button
                size="sm"
                variant="outlined"
                className="flex items-center gap-2 border-apedao-black-950 text-apedao-black-950 !shadow-none dark:border-apedao-black-400 dark:text-apedao-black-400"
              >
                <CloudArrowDownIcon className="h-5 w-5" />
                HQ Art
              </Button>
            </a>
          )}
          <IconButton variant="text" className="mr-auto" onClick={() => props.onSetFavorite(!props.isFavorite)}>
            {props.isFavorite ? (
              <HeartIconSolid className="h-6 w-6 text-red-500" />
            ) : (
              <HeartIcon className="h-6 w-6 text-red-500" />
            )}
          </IconButton>
          {showPriceInfo && (
            <>
              <Typography variant="h6">Price:</Typography>
              <div className="flex flex-col items-end">
                {priceDetails.fee > 0 && (
                  <span className="pr-2 text-[0.6rem]">
                    {priceDetails.fee > 0 && `incl. ${priceDetails.feePercentage}% fee`}
                  </span>
                )}
                <div className="inline min-w-[5rem] rounded-br-md rounded-tl-md bg-green-400 px-2 pb-0.5 text-end text-white dark:bg-green-600">
                  <span className="align-sub text-[0.6rem] font-normal">{priceDetails.symbol}</span>
                  <span className="ml-1 align-sub font-medium ">{formatNumber(priceDetails.price, 0, 0)}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogFooter>
    </Dialog>
  );
}

export default NftInfoDialog;
