import {
  AccordionStylesType,
  ButtonGroupStyleTypes,
  ButtonStyleTypes,
  CardStylesType,
  CheckboxStylesType,
  DialogBodyStylesType,
  DialogFooterStylesType,
  DialogHeaderStylesType,
  DialogStylesType,
  IconButtonStyleTypes,
  InputStylesType,
  ListStylesType,
  MenuStylesType,
  PopoverStylesType,
  SelectStylesType,
  SwitchButtonStylesType,
  TabPanelStylesType,
  TabsHeaderStylesType,
} from '@material-tailwind/react';
import { TabStylesType } from '@material-tailwind/react/theme/components/tabs/tab';
import { Theme, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';

export interface IThemes {
  light: ITheme;
  dark: ITheme;
}

export interface ITheme {
  accordion: AccordionStylesType;
  button: ButtonStyleTypes;
  buttonGroup: ButtonGroupStyleTypes;
  card: CardStylesType;
  checkbox: CheckboxStylesType;
  dialog: DialogStylesType;
  dialogHeader: DialogHeaderStylesType;
  dialogBody: DialogBodyStylesType;
  dialogFooter: DialogFooterStylesType;
  iconButton: IconButtonStyleTypes;
  input: InputStylesType;
  list: ListStylesType;
  menu: MenuStylesType;
  popover: PopoverStylesType;
  select: SelectStylesType;
  switch: SwitchButtonStylesType;
  tabPanel: TabPanelStylesType;
  tab: TabStylesType;
  tabsHeader: TabsHeaderStylesType;
  rainbowkit: Theme;
}

const light: ITheme = {
  accordion: {
    styles: {
      base: {
        container: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
        header: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
        body: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
      },
    },
  },
  button: {
    defaultProps: {
      color: 'amber',
    },
    styles: {
      base: {
        initial: {
          opacityDisabled: 'dark:disabled:opacity-40',
          focusRing: 'dark:focus:ring-opacity-10',
        },
      },
    },
  },
  buttonGroup: {
    defaultProps: {
      color: 'amber',
    },
    styles: {
      base: {
        initial: {
          opacityDisabled: 'dark:disabled:opacity-40',
          focusRing: 'dark:focus:ring-opacity-10',
        },
      },
    },
  },
  card: {
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
          backgroundColor: 'bg-white dark:bg-apedao-black-800',
        },
      },
    },
  },
  checkbox: {
    defaultProps: {
      color: 'amber',
    },
  },
  dialog: {
    styles: {
      base: {
        container: {
          initial: {
            backgroundColor: 'bg-white text-apedao-black-950',
          },
        },
      },
    },
  },
  dialogHeader: {
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
        },
      },
    },
  },
  dialogBody: {
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
          borderColor: '!border-y-apedao-black-200 dark:!border-y-apedao-black-500',
        },
      },
    },
  },
  dialogFooter: {
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
        },
      },
    },
  },
  iconButton: {
    defaultProps: {
      color: 'amber',
    },
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
        },
      },
    },
  },
  input: {
    styles: {
      base: {
        input: {
          initial: {
            inheritTextColor: '!text-inherit dark:!text-inherit',
          },
        },
        label: {
          initial: {
            light: '!text-apedao-black-500 dark:!text-apedao-black-300',
          },
        },
      },
    },
  },
  list: {
    styles: {
      base: {
        list: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
        item: {
          initial: {
            inheritTextColor: 'hover:text-inherit active:text-inherit focus:text-inherit',
          },
        },
      },
    },
  },
  menu: {
    styles: {
      base: {
        menu: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
      },
    },
  },
  popover: {
    styles: {
      base: {
        initial: {
          backgroundColor: 'bg-amber-50',
          textColor: 'text-apedao-black-950',
          borderColor: 'border-apedao-black-300',
        },
      },
    },
  },
  select: {
    defaultProps: {
      color: 'amber',
    },
    styles: {
      base: {
        label: {
          inheritTextColor: 'text-inherit dark:text-apedao-black-300',
          borderColor: 'before:dark:border-apedao-black-500 after:dark:border-apedao-black-500',
        },
        menu: {
          inheritTextColor: 'text-inherit dark:text-inherit',
          backgroundColor: 'bg-white dark:bg-apedao-black-800',
          borderColor: 'dark:border-apedao-black-500',
        },
        select: {
          borderColor: 'dark:border-b-apedao-black-500 dark:border-x-apedao-black-500',
          inheritTextColor: 'text-inherit dark:text-inherit',
          disabled:
            'disabled:border-1 disabled:opacity-80 [&_svg]:disabled:opacity-30 disabled:dark:opacity-50 [&_svg]:disabled:dark:opacity-50',
        },
        arrow: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
        option: {
          initial: {
            backgroundColor: 'hover:bg-apedao-100 dark:hover:bg-apedao-black-700 dark:text-inherit',
          },
          active: {
            inheritTextColor: 'text-inherit dark:text-inherit',
            backgroundColor:
              'bg-amber-100 focus:bg-amber-200 hover:bg-amber-200 dark:bg-apedao-black-600 dark:hover:bg-apedao-black-500 dark:focus:bg-apedao-black-500',
          },
        },
      },
      variants: {
        outlined: {
          base: {
            label: {
              before: {
                disabled: 'peer-disabled:before:border-1',
              },
              after: {
                disabled: 'peer-disabled:after:border-1',
              },
            },
          },
          states: {
            close: {
              select: {
                borderColor: 'dark:border-apedao-black-500',
              },
            },
            withValue: {
              label: {
                disabled: 'peer-disabled:opacity-70 peer-disabled:dark:opacity-50',
              },
            },
          },
        },
        static: {},
        standard: {},
      },
    },
  },
  switch: {
    defaultProps: {
      color: 'amber',
    },
  },
  tabPanel: {
    styles: {
      base: {
        initial: {
          inheritTextColor: 'text-inherit dark:text-inherit',
        },
      },
    },
  },
  tab: {
    styles: {
      base: {
        tab: {
          initial: {
            inheritTextColor: 'text-inherit dark:text-inherit',
          },
        },
        indicator: {
          initial: {
            backgroundColor: 'bg-white dark:bg-apedao-black-800/60',
          },
        },
      },
    },
  },
  tabsHeader: {
    styles: {
      base: {
        initial: {
          borderColor: 'dark:border-apedao-black-800/60',
        },
      },
    },
  },
  rainbowkit: merge(
    lightTheme({
      accentColor: '#f1c83a',
      accentColorForeground: '#333333',
      borderRadius: 'small',
      overlayBlur: 'small',
    }),
    {
      blurs: {
        // modalOverlay: '...',
      },
      colors: {
        // accentColor: '...',
        // accentColorForeground: '...',
        // actionButtonBorder: '...',
        // actionButtonBorderMobile: '...',
        // actionButtonSecondaryBackground: '...',
        // closeButton: '...',
        // closeButtonBackground: '...',
        connectButtonBackground: 'rgba(0, 0, 0, 0)',
        // connectButtonBackgroundError: '...',
        // connectButtonInnerBackground: '...',
        // connectButtonText: '...',
        // connectButtonTextError: '...',
        // connectionIndicator: '...',
        // downloadBottomCardBackground: '...',
        // downloadTopCardBackground: '...',
        // error: '...',
        // generalBorder: '...',
        // generalBorderDim: '...',
        // menuItemBackground: '...',
        // modalBackdrop: '...',
        // modalBackground: '...',
        // modalBorder: '...',
        modalText: '#121212',
        // modalTextDim: '...',
        modalTextSecondary: '#383838',
        // profileAction: '...',
        // profileActionHover: '...',
        // profileForeground: '...',
        // selectedOptionBorder: '...',
        // standby: '...',
      },
      fonts: {
        // body: '...',
        body: 'Exo',
      },
      radii: {
        // actionButton: '...',
        // connectButton: '...',
        // menuButton: '...',
        // modal: '...',
        // modalMobile: '...',
      },
      shadows: {
        connectButton: '',
        // dialog: '...',
        // profileDetailsAction: '...',
        // selectedOption: '...',
        // selectedWallet: '...',
        // walletLogo: '...',
      },
    } as Theme
  ),
};

const dark: ITheme = {
  ...light,
  dialog: {
    styles: {
      base: {
        container: {
          initial: {
            backgroundColor: 'bg-apedao-black-800',
            textColor: 'text-apedao-black-100',
          },
        },
      },
    },
  },
  menu: {
    styles: {
      base: {
        menu: {
          initial: {
            backgroundColor: 'bg-apedao-black-600',
            textColor: 'text-apedao-black-100',
            borderColor: 'border-apedao-black-300',
          },
        },
      },
    },
  },
  popover: {
    styles: {
      base: {
        initial: {
          backgroundColor: 'bg-apedao-black-600',
          textColor: 'text-apedao-black-100',
          borderColor: 'border-apedao-black-300',
        },
      },
    },
  },
  rainbowkit: merge(
    darkTheme({
      accentColor: '#f1c83a',
      accentColorForeground: '#333333',
      borderRadius: 'small',
      overlayBlur: 'small',
    }),
    {
      blurs: {
        // modalOverlay: '...',
      },
      colors: {
        // accentColor: '...',
        // accentColorForeground: '...',
        // actionButtonBorder: '...',
        // actionButtonBorderMobile: '...',
        // actionButtonSecondaryBackground: '...',
        closeButton: '#a4a4a4',
        closeButtonBackground: '#515151',
        connectButtonBackground: 'rgba(0, 0, 0, 0)',
        // connectButtonBackgroundError: '...',
        // connectButtonInnerBackground: '...',
        // connectButtonText: '...',
        // connectButtonTextError: '...',
        // connectionIndicator: '...',
        // downloadBottomCardBackground: '...',
        // downloadTopCardBackground: '...',
        // error: '...',
        // generalBorder: '...',
        // generalBorderDim: '...',
        // menuItemBackground: '...',
        // modalBackdrop: '...',
        modalBackground: '#383838',
        // modalBorder: '...',
        modalText: '#e3e3e3',
        // modalTextDim: '...',
        modalTextSecondary: '#a4a4a4',
        // profileAction: '...',
        // profileActionHover: '...',
        // profileForeground: '...',
        // selectedOptionBorder: '...',
        // standby: '...',
      },
      fonts: {
        // body: '...',
        body: 'Exo',
      },
      radii: {
        // actionButton: '...',
        // connectButton: '...',
        // menuButton: '...',
        // modal: '...',
        // modalMobile: '...',
      },
      shadows: {
        connectButton: '',
        // dialog: '...',
        // profileDetailsAction: '...',
        // selectedOption: '...',
        // selectedWallet: '...',
        // walletLogo: '...',
      },
    } as Theme
  ),
};

export const themes: IThemes = {
  light,
  dark,
};
