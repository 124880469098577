import { RcpBucketContext } from 'contexts/RcpBucket/RcpBucketProvider';
import React, { useCallback } from 'react';

const useRcpBucket = () => {
  const { state: bucket } = React.useContext(RcpBucketContext);

  const throttleRequest = useCallback(async () => {
    await bucket.throttle(1);
  }, [bucket]);

  return {
    throttleRequest,
  };
};

export default useRcpBucket;
