import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import StatsPercentageCircle from '../StatsPercentageCircle';
import useStatsVotingPower from './hooks/useStatsVotingPower';

export interface IStatsVotingPowerProps {
  stats: IStats;
  isDarkMode: boolean;
}

export function StatsVotingPower(props: IStatsVotingPowerProps) {
  const { data } = useStatsVotingPower(props);

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="Active Voting Power">
      <div className="flex flex-col gap-2 sm:gap-1">
        {data?.assets.map((vp) => (
          <div key={vp.name} className="flex flex-row justify-end gap-2">
            <AssetDisplay name={vp.name} className="grow" />
            <div className="font-mono">{formatBig(vp.value, 0)}</div>
            <div className="h-5 w-5 justify-self-end sm:h-6 sm:w-6">
              <StatsPercentageCircle percentage={vp.percentage} darkMode={props.isDarkMode} />
            </div>
          </div>
        ))}
      </div>
      <hr className="my-1" />
      <div className="mr-7 md:mr-8">
        <div className="flex flex-row justify-between font-semibold">
          <span>Total</span>
          <span className="font-mono ">{formatBig(data.total, 0)}</span>
        </div>
      </div>
    </StatsCard>
  );
}

export default StatsVotingPower;
