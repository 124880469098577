import Big from 'big.js';
import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import StatsPercentageCircle from '../StatsPercentageCircle';
import useStatsDistributionLiquidity from './hooks/useStatsDistributionLiquidity';

export interface IStatsDistributionLiquidityProps {
  stats: IStats;
  isDarkMode: boolean;
  mobileView: boolean;
}

export function StatsDistributionLiquidity(props: IStatsDistributionLiquidityProps) {
  const data = useStatsDistributionLiquidity(props);

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="Distribution and Liquidity">
      <div className="flex flex-col gap-3 text-sm sm:flex-row sm:gap-5 md:gap-6 md:text-base">
        <div className="hidden grow flex-col justify-end gap-2 pr-4 sm:flex md:gap-1">
          {data.map((dl) => (
            <AssetDisplay key={dl.name} name={dl.name} />
          ))}
        </div>
        {!props.mobileView && (
          <div className="flex flex-col items-end gap-2 md:gap-1">
            <span className="whitespace-nowrap font-bold">Total</span>
            {data.map((dl) => (
              <div key={dl.name} className="flex w-full flex-shrink-0 flex-row gap-2">
                <span className="grow text-end font-mono">{formatBig(dl.total, 0)}</span>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col items-end gap-2 md:gap-1">
          <div className="relative flex flex-row gap-1">
            <span className="whitespace-nowrap font-bold">Locked</span>
            <span className="absolute -right-2 -top-1.5 text-[0.7em]">*</span>
          </div>
          {data.map((dl) => (
            <div key={dl.name} className="flex w-full flex-row sm:gap-2">
              {props.mobileView && <AssetDisplay name={dl.name} />}
              <div className="grow text-end font-mono">{formatBig(dl.locked, 0)}</div>
              {props.mobileView && (
                <div className="mr-2 text-end font-mono opacity-50">/{Big(dl.total).toFixed(0, 1)}</div>
              )}
              <div className="h-5 w-5 justify-self-end md:h-6 md:w-6">
                <StatsPercentageCircle percentage={dl.lockedPercentage} darkMode={props.isDarkMode} />
              </div>
            </div>
          ))}
          {props.mobileView && (
            <div className="sd:hidden mt-4 w-full text-end text-[0.75em]">
              * NFT on the market and APEin in liquidity pool
            </div>
          )}
        </div>
        {props.mobileView && <hr />}
        <div className="flex flex-col items-end gap-2 md:gap-1">
          <span className="whitespace-nowrap font-bold">In Circulation</span>
          {data.map((dl) => (
            <div key={dl.name} className="flex w-full flex-shrink-0 flex-row items-center sm:gap-2">
              {props.mobileView && <AssetDisplay name={dl.name} />}
              <div className="grow text-end font-mono">{formatBig(dl.inCirculation, 0)}</div>
              {props.mobileView && <div className="mr-2 text-end font-mono opacity-50">/{formatBig(dl.total, 0)}</div>}
              <div className="h-5 w-5 justify-self-end md:h-6 md:w-6">
                <StatsPercentageCircle percentage={dl.inCirculationPercentage} darkMode={props.isDarkMode} />
              </div>
            </div>
          ))}
        </div>
        {props.mobileView && <hr />}
        <div className="flex flex-col items-end gap-2 md:gap-1">
          <span className="whitespace-nowrap font-bold">Holders</span>
          {data.map((dl) => (
            <div key={dl.name} className="flex w-full flex-shrink-0 flex-row gap-2">
              {props.mobileView && <AssetDisplay name={dl.name} />}
              <span className="grow text-end font-mono">{formatBig(dl.holders, 0)}</span>
            </div>
          ))}
        </div>
      </div>
      {!props.mobileView && (
        <div className="mt-4 w-full text-end text-[0.75em]">* NFT on the market and APEin in liquidity pool</div>
      )}
    </StatsCard>
  );
}

export default StatsDistributionLiquidity;
