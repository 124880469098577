import classNames from 'classnames';
import { NftListItem, Spinner } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useIds } from 'helpers/idHelpers';
import React, { useCallback, useEffect, useState } from 'react';
import { LazyComponentProps, trackWindowScroll } from 'react-lazy-load-image-component';
import { ICart, INft } from 'types';
import getIds from './getIds';

const itemsMinMaxWidth = 'basis-[150px] max-w-[220px] sm:basis-[215px] sm:max-w-[280px]';

export interface INftSelectionHandle {
  selectedNftIds: string[];
  isSelectable: (nft: INft) => boolean;
  onResetSelection: () => void;
  onToggleSelection: (nftId: string) => void;
}

interface INftListProps extends IBaseComponentProps, LazyComponentProps {
  mode: 'cart' | 'select';
  page: 'vault' | 'wallet';
  nfts: INft[];
  totalNumber: number;
  cart: ICart;
  isLoading: boolean;
  favoriteIds: string[];
  emptyListMessage?: string;
  selectedIcon?: React.ReactElement;
  displayLocation: boolean;
  onSetFavorite: (nftId: string, isFavorite: boolean) => void;
  onShowNftInfo?: (nftId: string) => void;
  selectionHandle?: INftSelectionHandle;
  hideNftInfoButtons: boolean;
}

function NftList(props: INftListProps) {
  const ID = useIds(props.id, getIds);
  const [firstRender, setFirstRender] = useState(true);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => setFirstRender(false), 700);
  }, []);

  useEffect(() => {
    if (props.selectionHandle) {
      if (props.mode === 'cart' && props.selectionHandle.selectedNftIds.length > 0) {
        props.selectionHandle.onResetSelection();
      } else if (props.mode === 'select' && props.cart.items.size > 0) {
        props.cart.onResetCart();
      }
    }
  }, [props.cart, props.mode, props.selectionHandle]);

  const toggleSelection = useCallback(
    (nft: INft) => {
      props.selectionHandle?.onToggleSelection && props.selectionHandle.onToggleSelection(nft.id);
    },
    [props.selectionHandle]
  );

  const onImageLoaded = useCallback(() => {
    setFirstImageLoaded(true);
  }, []);

  const renderItem = (nft: INft, quantity: number, toBeRemoved: boolean, index: number, lazyLoadFromIndex: number) => {
    const isSelectable = props.selectionHandle?.isSelectable(nft);
    return (
      <NftListItem
        id={`${ID.nft}_${index}`}
        key={`nft_${nft.id}`}
        mode={props.mode}
        page={props.page}
        nft={nft}
        totalNumber={props.totalNumber}
        quantity={quantity}
        toBeRemoved={toBeRemoved}
        maxQuantity={nft.type === 'random' ? props.cart.maxRandomQuantityPerCollection.get(nft.collection.id) ?? 99 : 1}
        className={classNames('mb-2 min-w-0 flex-shrink flex-grow transition-opacity sm:mb-4', itemsMinMaxWidth, {
          'opacity-10': props.mode === 'select' && !isSelectable,
        })}
        lazyLoad={index >= lazyLoadFromIndex}
        scrollPosition={props.scrollPosition}
        isFavorite={props.favoriteIds.includes(nft.id)}
        displayLocation={props.displayLocation}
        isSelected={
          props.mode === 'select' &&
          props.selectionHandle != null &&
          props.selectionHandle.selectedNftIds.includes(nft.id)
        }
        selectedIcon={props.selectedIcon}
        isSelectable={isSelectable}
        hideInfoButton={props.hideNftInfoButtons}
        onSetFavorite={props.onSetFavorite}
        onQuantityChange={props.cart.onQuantityChange}
        onImageLoaded={index === 0 ? onImageLoaded : undefined}
        onShowNftInfo={props.onShowNftInfo}
        onSelect={props.mode === 'select' ? toggleSelection : undefined}
      />
    );
  };

  const nfts = firstRender ? [] : props.nfts;

  // add invisible placeholders to fill up last row
  const placeholders = props.nfts.length > 0 ? [...Array(25).keys()].map(() => undefined) : [];

  const messageWrapperClassName = 'flex w-full mt-10 flex-col items-center justify-end';

  const loadingIndicator = (props.isLoading || firstRender || (nfts.length > 0 && !firstImageLoaded)) && (
    <div className={messageWrapperClassName}>
      <div className="flex h-40 w-40 flex-col items-center justify-center rounded-full bg-amber-300 opacity-50 dark:opacity-80">
        <Spinner className="h-20 w-20" variant="jumping_ape" />
        <span className="-translate-y-2 text-base font-bold uppercase tracking-wider text-apedao-black-950">
          Loading
        </span>
      </div>
    </div>
  );

  const noNftsIndicator = !loadingIndicator && !props.isLoading && !firstRender && nfts.length <= 0 && (
    <div className={messageWrapperClassName}>
      <span className="max-w-lg rounded-lg border border-amber-500/70 px-4 py-2 text-center font-normal dark:font-light">
        {props.emptyListMessage ?? 'All Apes are out in the woods.'}
      </span>
    </div>
  );

  return (
    <>
      {loadingIndicator}
      {noNftsIndicator}
      <div
        className={classNames('flex flex-row flex-wrap justify-center gap-x-2 sm:gap-x-4', {
          invisible: props.isLoading || firstRender || !firstImageLoaded,
        })}
      >
        {nfts.map((nft, index) =>
          renderItem(
            nft,
            props.cart.items.get(nft.id)?.quantity ?? 0,
            props.cart.items.get(nft.id)?.toBeRemoved ?? false,
            index,
            50
          )
        )}
        {nfts.length > 0 &&
          placeholders.map((_, index) => (
            <div
              key={`placeholder_${index}`}
              className={classNames('max-h-[0rem] flex-shrink flex-grow', itemsMinMaxWidth)}
            ></div>
          ))}
      </div>
    </>
  );
}

export default trackWindowScroll(NftList);
