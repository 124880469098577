import { calculatePriceAndFee } from 'helpers/nftHelpers';
import { IAssetAmount, IAssetBalance, ICartItem, ICartSums } from 'types';

const calculateTotalPriceAndFees = (cartItems: Pick<ICartItem, 'nft' | 'quantity'>[], mode: 'buy' | 'sell') => {
  return cartItems.reduce(
    (acc, cartItem) => {
      const { price, fee } = calculatePriceAndFee(cartItem.nft, cartItem.quantity, mode);
      return {
        price: acc.price + price,
        fee: acc.fee + fee,
      };
    },
    { price: 0, fee: 0 } as ICartSums<number>
  );
};

const isApeInFundsSufficient = (balance: IAssetBalance, price: number) => {
  const balanceApeInDecimals = balance.decimals ?? 0;
  const maxApeInDecimals = Math.max(balanceApeInDecimals, 0);
  const apeInBalance = toDecimals(balance.value ?? BigInt(0), balanceApeInDecimals, maxApeInDecimals);
  const totalApeInPrice = toDecimals(BigInt(price), 0, maxApeInDecimals);

  return apeInBalance >= totalApeInPrice;
};

const isIotaFundsSufficient = (balance: IAssetBalance, price: IAssetAmount) => {
  const balanceIotaDecimals = balance.decimals ?? 0;
  const maxIotaDecimals = Math.max(balanceIotaDecimals, price.decimals);
  const iotaBalance = toDecimals(balance.value ?? BigInt(0), balanceIotaDecimals, maxIotaDecimals);
  const totalIotaPrice = toDecimals(price.amount, price.decimals, maxIotaDecimals);

  return iotaBalance >= totalIotaPrice;
};

const toDecimals = (value: bigint, valueDecimals: number, targetDecimals: number) => {
  if (valueDecimals === targetDecimals) {
    return value;
  } else if (valueDecimals > targetDecimals) {
    return value / BigInt(Math.pow(10, valueDecimals - targetDecimals));
  } else {
    return value * BigInt(Math.pow(10, targetDecimals - valueDecimals));
  }
};

export { calculateTotalPriceAndFees, isApeInFundsSufficient, isIotaFundsSufficient };
