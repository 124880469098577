import Big from 'big.js';
import { assetStatsKeyNameMapping, CollectionKey, IStats, TokenKey } from 'helpers/backendApi/stats_v1';
import React, { useEffect } from 'react';
import { IStatsMarketCapProps } from '../StatsMarketCap';

export interface IStatsMarketCapAssetData {
  name: string;
  total: Big;
}

function useStatsMarketCap(props: IStatsMarketCapProps) {
  const [data, setData] = React.useState<{ total: Big; assets: IStatsMarketCapAssetData[] }>();

  useEffect(() => {
    const assets = Object.keys(props.stats.assetStats)
      .map((key: string) => {
        const assetStats = props.stats.assetStats[key as keyof IStats['assetStats']];
        const marketCapEvm = Big(assetStats.marketCapInUSD?.evm ?? 0);

        return {
          name: assetStatsKeyNameMapping[key as CollectionKey | TokenKey],
          total: marketCapEvm, // only evm mc is taken into account anymore
        };
      })
      .filter((asset) => asset.total.gt(0));

    const total = assets.reduce((prev, current) => {
      return prev.add(current.total);
    }, Big(0));

    setData({
      total: total,
      assets: assets,
    });
  }, [props.stats.assetStats]);

  return data;
}

export default useStatsMarketCap;
