import { createIds } from 'helpers/idHelpers';

export const appRootId = 'apedao';

const getIds = (root: string) => {
  return createIds(
    root,
    'header',
    'home',
    'original_home',
    'leaderboard',
    'stats',
    'marketplace',
    'wallet',
    'migration',
    'governance',
    'club'
  );
};

export default getIds;
