import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@material-tailwind/react';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { INftLocation } from 'types';
import { getChainByEvmChainName } from 'web3/chainsAndWallets';

function NftChainIcon(props: { location: INftLocation; tooltip?: boolean }) {
  return props.location.chain === 'shimmerL1' ? (
    <ConditionalTooltip content="Shimmer L1" enabled={props.tooltip ?? true}>
      <img
        alt={props.location.chain}
        src={getChainByEvmChainName('shimmerEvm')?.chainIconSrc}
        className={classNames('font- h-4 w-4 rounded-full saturate-0')}
      />
    </ConditionalTooltip>
  ) : props.location.chain !== 'n/a' ? (
    <ConditionalTooltip
      content={`${props.location.tag === 'onBridge' ? 'Moving to ' : ''}${
        getChainByEvmChainName(props.location.chain)?.name
      }`}
      enabled={props.tooltip ?? true}
    >
      <img
        alt={props.location.chain}
        src={getChainByEvmChainName(props.location.chain)?.chainIconSrc}
        className={classNames('h-4 w-4 rounded-full', {
          'animate-pulse saturate-0': props.location.tag === 'onBridge',
        })}
      />
    </ConditionalTooltip>
  ) : (
    <ConditionalTooltip content="Unknown Chain" enabled={props.tooltip ?? true}>
      <QuestionMarkCircleIcon className="h-5 w-5 fill-apedao-black-100 dark:fill-apedao-black-600" />
    </ConditionalTooltip>
  );
}

function ConditionalTooltip(props: { children: ReactElement; content: string; enabled?: boolean }) {
  return props.enabled ? <Tooltip content={props.content}>{props.children}</Tooltip> : <>{props.children}</>;
}

export default NftChainIcon;
