import { usePrevious } from '@material-tailwind/react';
import { useWalletInfo } from 'contexts/WalletInfo';
import { layerZeroHelpers, msg, useChain, useNftCollectionApprovals } from 'helpers';
import { SESSION_STORAGE_ON_BRIDGE } from 'helpers/storageHelpers';
import { TransferEventListener } from 'helpers/useNftTransferEvents';
import links from 'links';
import React, { useCallback, useEffect, useState } from 'react';
import { IBaseCollection, INft } from 'types';
import { useSessionStorage } from 'usehooks-ts';
import { Address } from 'viem';
import { waitForTransactionReceipt } from 'viem/actions';
import { useWalletClient } from 'wagmi';
import { EvmChainName, IExtendedChain, mainChain, supportedChainsByName } from 'web3/chainsAndWallets';

export interface INftOnLayerZeroBridge {
  collectionId: number;
  nftId: string;
  from: { address: Address; chainName: EvmChainName };
  to: { address: Address; chainName: EvmChainName };
  txHash: `0x${string}`;
}

export interface IUseLayerZeroBridgeProps {
  collection?: IBaseCollection;
  registerTransferEventListener: (listener: TransferEventListener) => () => void;
}

const useLayerZeroBridge = (props: IUseLayerZeroBridgeProps) => {
  const walletInfo = useWalletInfo();
  const { data: walletClient } = useWalletClient();
  const { chainName } = useChain();

  const [selectedNftIds, setSelectedNftIds] = React.useState<string[]>([]);
  const previousSelectedNftIds = usePrevious(selectedNftIds);

  const [supportedTargetChains, setSupportedTargetChains] = useState<EvmChainName[]>([]);
  const [targetChain, setTargetChain] = useState<EvmChainName>();
  const [estimatedFee, setEstimatedFee] = useState<bigint>();
  const [batchLimit, setBatchLimit] = useState<number>(1);

  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const lzProxyAddress = chainName ? props.collection?.contracts[chainName]?.lzProxyAddress : undefined;
  const bridgeViaProxy = lzProxyAddress != null;

  const contractApprovals = useNftCollectionApprovals({
    accountAddress: walletInfo.address,
    operator: lzProxyAddress,
  });

  const [nftsOnBridge, setNftsOnBridge] = useSessionStorage<Record<string, INftOnLayerZeroBridge>>(
    SESSION_STORAGE_ON_BRIDGE,
    {}
  );

  const registerTransferEventListener = props.registerTransferEventListener;
  useEffect(() => {
    return registerTransferEventListener((transfer) => {
      setNftsOnBridge((prevState) => {
        if (
          prevState[transfer.nftId] &&
          prevState[transfer.nftId].to.chainName === transfer.chain &&
          prevState[transfer.nftId].to.address === transfer.to
        ) {
          const newNftsOnBridge = { ...prevState };
          msg.success(`NFT arrived on ${supportedChainsByName[transfer.chain]?.name}`);
          delete newNftsOnBridge[transfer.nftId];
          return newNftsOnBridge;
        }
        return prevState;
      });
    });
  }, [registerTransferEventListener, setNftsOnBridge]);

  const allDataAvailableToSend =
    walletClient &&
    chainName &&
    targetChain &&
    walletInfo.address &&
    selectedNftIds.length > 0 &&
    walletInfo.address &&
    supportedTargetChains.includes(targetChain);

  const guardValidNfts = useCallback(
    (nftIds: string[] | undefined) => {
      const nftsNotEmpty = nftIds && nftIds.length > 0;
      const allOfSameCollection =
        nftsNotEmpty &&
        props.collection != null &&
        nftIds.every((nftId) => Number(nftId.split('-')[0]) === props.collection!.id);

      if (nftsNotEmpty && !allOfSameCollection) {
        msg.error('You can only bridge NFTs from the same collection at once');
        return false;
      } else if (!nftsNotEmpty) {
        return false;
      }

      return true;
    },
    [props.collection]
  );

  useEffect(() => {
    (async () => {
      const toChain = supportedChainsByName[targetChain as EvmChainName] as IExtendedChain | undefined;
      if (walletClient && chainName && props.collection && toChain) {
        const batchLimit = await layerZeroHelpers.getBatchLimit(
          walletClient,
          chainName,
          props.collection.id,
          toChain.lzChainId
        );

        setBatchLimit(batchLimit === 0 ? 1 : batchLimit);
      } else {
        setBatchLimit(1);
      }
    })();
  }, [chainName, props.collection, targetChain, walletClient]);

  useEffect(() => {
    const doIt = async () => {
      const toChain = supportedChainsByName[targetChain as EvmChainName] as IExtendedChain | undefined;
      const estimatedFee =
        allDataAvailableToSend && walletInfo.address && toChain
          ? await layerZeroHelpers.estimateSendFee(
              walletClient,
              chainName,
              selectedNftIds,
              walletInfo.address,
              toChain.lzChainId
            )
          : undefined;

      setEstimatedFee(estimatedFee);
    };

    doIt().catch((e) => {
      console.error(e);
      setEstimatedFee(undefined);
    });
  }, [
    allDataAvailableToSend,
    chainName,
    selectedNftIds,
    supportedTargetChains,
    targetChain,
    walletClient,
    walletInfo.address,
  ]);

  useEffect(() => {
    if (selectedNftIds !== previousSelectedNftIds) {
      setIsSending(false);
      setError(false);
    }
  }, [previousSelectedNftIds, selectedNftIds]);

  const sendSelectedNfts = useCallback(async () => {
    const toChain = supportedChainsByName[targetChain as EvmChainName] as IExtendedChain | undefined;

    if (allDataAvailableToSend && walletClient && walletInfo.address && toChain && guardValidNfts(selectedNftIds)) {
      try {
        setError(false);
        setIsSending(true);

        const txHash = await layerZeroHelpers.sendNfts(
          walletClient,
          chainName,
          selectedNftIds,
          walletInfo.address,
          toChain.lzChainId
        );

        const nftsOnBridge: Record<string, INftOnLayerZeroBridge> = selectedNftIds
          .map((nftId): INftOnLayerZeroBridge => {
            return {
              collectionId: Number(nftId.split('-')[0]),
              nftId: nftId,
              from: { address: walletInfo.address!, chainName: chainName },
              to: { address: walletInfo.address!, chainName: targetChain },
              txHash,
            };
          })
          .reduce((obj, nftOnBridge) => Object.assign(obj, { [nftOnBridge.nftId]: nftOnBridge }), {});

        setNftsOnBridge((prev) => ({ ...prev, ...nftsOnBridge }));

        await waitForTransactionReceipt(walletClient, { hash: txHash });
        msg.txHashInfo(
          `${selectedNftIds.length > 1 ? selectedNftIds.length + ' ' : ''}NFT${
            selectedNftIds.length > 1 ? 's are on their ' : ' is on its '
          }way to ${toChain.name}`,
          `${links.layerZeroExplorer}/tx/${txHash}`
        );
      } catch (error) {
        console.error(error);
        msg.error(`Couldn't bridge NFT to ${toChain.name}`);
        setError(true);
      } finally {
        setIsSending(false);
      }
    }
  }, [
    allDataAvailableToSend,
    chainName,
    guardValidNfts,
    selectedNftIds,
    setNftsOnBridge,
    targetChain,
    walletClient,
    walletInfo.address,
  ]);

  useEffect(() => {
    const newSelectableChains: EvmChainName[] = [];

    if (props.collection != null) {
      Object.keys(props.collection.contracts).forEach((evmChainName) => {
        const contractInfo = props.collection!.contracts[evmChainName as EvmChainName];
        if (
          ((chainName === mainChain.chainName && evmChainName !== chainName) ||
            (chainName !== mainChain.chainName && evmChainName === mainChain.chainName)) &&
          (contractInfo?.contractType === 'onft721ApeStyle' || contractInfo?.lzProxyAddress != null)
        ) {
          newSelectableChains.push(evmChainName as EvmChainName);
        }
      });
    }

    setSupportedTargetChains(newSelectableChains);
    newSelectableChains.length > 0 && setTargetChain(newSelectableChains[0]);
  }, [chainName, props.collection]);

  const finalizePreviouslyTransferredNfts = useCallback(
    (nfts: INft[]) => {
      setNftsOnBridge((prevState) => {
        let dataChanged = false;
        const newNftsOnBridge = { ...prevState };
        nfts.forEach((nft) => {
          const nftOnBridge = newNftsOnBridge[nft.id];
          if (
            nftOnBridge?.to.chainName === nft.location.chain &&
            (nft.location.tag === 'wallet' || nft.location.tag === 'vault')
          ) {
            delete newNftsOnBridge[nft.id];
            dataChanged = true;
          }
        });
        return dataChanged ? newNftsOnBridge : prevState;
      });
    },
    [setNftsOnBridge]
  );

  const toggleNftIdSelection = useCallback((nftId: string) => {
    setSelectedNftIds((prevState) => {
      return prevState.includes(nftId)
        ? prevState.filter((selectedNftId) => selectedNftId !== nftId)
        : [...prevState, nftId];
    });
  }, []);

  const resetNftIdSelection = () => {
    setSelectedNftIds([]);
  };

  return {
    collection: props.collection,
    selectedNftIds,
    setSelectedNftIds,
    resetNftIdSelection,
    toggleNftIdSelection,
    nftsOnBridge: nftsOnBridge,
    supportedTargetChains,
    targetChain,
    setTargetChain,
    estimatedFee,
    batchLimit,
    sendSelectedNfts,
    isSending,
    error,
    needsContractApproval: bridgeViaProxy,
    contractApprovals,
    finalizePreviouslyTransferredNfts,
  };
};

export default useLayerZeroBridge;
