import { ICartItemProps } from 'components/CartItem/CartItem';
import { calculatePriceAndFee } from 'helpers/nftHelpers';
import { useIsFirstRender } from 'usehooks-ts';

const useCartItem = (props: ICartItemProps, removeAnimationDuration: number) => {
  const isFirstRender = useIsFirstRender();
  const priceAndFee = calculatePriceAndFee(props.nft, props.quantity, props.mode);

  return {
    isFirstRender,
    priceAndFee,
  };
};

export default useCartItem;
