import { msg, useNftContractAbstraction } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { IBaseCollection, ICollection } from 'types';
import { Address } from 'viem';
import { EvmChainName, supportedChains } from 'web3/chainsAndWallets';

export interface IWalletHoldings {
  nftHoldings: Map<EvmChainName, BigInt[]>;
  currentCollectionId: number | undefined;
  refresh: () => Promise<void>;
  getCurrentBalances: (collection: ICollection) => Promise<Map<EvmChainName, BigInt>>;
  getCurrentHoldings: (collection: ICollection) => Promise<Map<EvmChainName, BigInt[]>>;
  isLoading: boolean;
  isError: boolean;
}

const useWalletNftHoldings = (props: {
  address?: Address;
  collection?: IBaseCollection;
  refreshOnInit?: boolean;
}): IWalletHoldings => {
  const [holdings, setHoldings] = React.useState<Map<EvmChainName, BigInt[]>>(new Map());
  const [collectionId, setCollectionId] = React.useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { getHoldings, getBalanceOf } = useNftContractAbstraction();

  const getCurrentBalances = useCallback(
    async (collection: IBaseCollection): Promise<Map<EvmChainName, BigInt>> => {
      const holdingsOnChain = new Map<EvmChainName, BigInt>();
      for (const chain of supportedChains) {
        const holdings = props.address ? await getBalanceOf(chain.chainName, collection, props.address) : 0n;
        holdingsOnChain.set(chain.chainName, holdings);
      }

      return holdingsOnChain;
    },
    [props.address, getBalanceOf]
  );

  const getCurrentHoldings = useCallback(
    async (collection: IBaseCollection): Promise<Map<EvmChainName, BigInt[]>> => {
      const holdingsOnChain = new Map<EvmChainName, BigInt[]>();
      for (const chain of supportedChains) {
        const holdings = props.address ? await getHoldings(chain.chainName, collection, props.address) : [];
        holdingsOnChain.set(chain.chainName, holdings);
      }

      return holdingsOnChain;
    },
    [props.address, getHoldings]
  );

  const refresh = useCallback(async () => {
    try {
      setIsLoading(true);
      const currentHoldings = props.collection
        ? await getCurrentHoldings(props.collection)
        : new Map<EvmChainName, BigInt[]>();
      setHoldings(currentHoldings);
      setCollectionId(props.collection?.id);
      setIsError(false);
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [props.collection, getCurrentHoldings]);

  useEffect(() => {
    if (props.collection && (props.refreshOnInit ?? true)) {
      refresh().catch(() => msg.error(`Error fetching account holdings`));
    }
  }, [props.collection, props.refreshOnInit, refresh]);

  return {
    nftHoldings: holdings,
    currentCollectionId: collectionId,
    refresh,
    getCurrentBalances,
    getCurrentHoldings,
    isLoading,
    isError,
  };
};

export default useWalletNftHoldings;
