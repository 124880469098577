import classNames from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import './RotatingImages.css';

export interface IHomeImageProps {
  imageURLs: string[];
  imageRotationTimeMs: number;
  numberOfTiles?: number;
  tilesFlipOffsetMs?: number;
  tilesFlipDurationMs?: number;
  className?: string;
}

function RotatingImages(props: IHomeImageProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [transitionInProgress, setTransitionInProgress] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement | null>(null);

  useInterval(() => {
    setTransitionInProgress(true);
  }, props.imageRotationTimeMs);

  useLayoutEffect(() => {
    if (transitionInProgress) {
      for (let i = 0; i < (props.numberOfTiles ?? 15); i++) {
        const flipCard = document.getElementById(`flip-tile-${i}`);
        if (flipCard) {
          setTimeout(() => {
            flipCard.classList.add('flip-tile-flipped');
          }, i * (props.tilesFlipOffsetMs ?? 100));
        }
      }

      setTimeout(() => {
        setCurrentImageIndex((currentImageIndex + 1) % props.imageURLs.length);
        setTransitionInProgress(false);
      }, (props.tilesFlipDurationMs ?? 800) + (props.numberOfTiles ?? 15) * (props.tilesFlipOffsetMs ?? 100));
    } else {
      for (let i = 0; i < (props.numberOfTiles ?? 15); i++) {
        const flipCard = document.getElementById(`flip-tile-${i}`);
        if (flipCard) {
          flipCard.classList.remove('flip-tile-flipped');
        }
      }
    }
  }, [
    currentImageIndex,
    props.imageURLs.length,
    props.numberOfTiles,
    props.tilesFlipDurationMs,
    props.tilesFlipOffsetMs,
    transitionInProgress,
  ]);

  const barWidth = (rootRef.current?.clientWidth ?? 0) / (props.numberOfTiles ?? 15);

  return (
    <div ref={rootRef} className={classNames('relative overflow-hidden', props.className)}>
      <div>
        <img src={props.imageURLs[currentImageIndex]} alt="placehoder" className="invisible" />
      </div>
      <div
        className={classNames('absolute bottom-0 left-0 right-0 top-0 bg-cover', {
          invisible: transitionInProgress,
        })}
        style={{
          backgroundImage: `url(${props.imageURLs[currentImageIndex]})`,
        }}
      ></div>
      <div
        className={classNames('absolute bottom-0 left-0 right-0 top-0 flex flex-row', {
          invisible: !transitionInProgress,
        })}
      >
        {new Array(props.numberOfTiles ?? 15).fill(0).map((_, index) => (
          <div id={`flip-tile-${index}`} key={`flip-tile-${index}`} className="flip-tile h-full w-full bg-transparent">
            <div
              className="flip-tile-inner relative h-full transition-transform"
              style={{
                transitionDuration: `${props.tilesFlipDurationMs ?? 800}ms`,
              }}
            >
              <div
                className="flip-tile-front absolute top-0 h-full bg-cover"
                style={{
                  width: barWidth,
                  backgroundImage: `url(${props.imageURLs[currentImageIndex]})`,
                  backgroundPosition: `${barWidth * ((props.numberOfTiles ?? 15) - index)}px 0px`,
                }}
              />
              <div
                className="flip-tile-back absolute top-0 h-full bg-cover"
                style={{
                  width: barWidth,
                  backgroundImage: `url(${props.imageURLs[(currentImageIndex + 1) % props.imageURLs.length]})`,
                  backgroundPosition: `${barWidth * ((props.numberOfTiles ?? 15) - index)}px 0px`,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RotatingImages;
