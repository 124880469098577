import { ChevronDownIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button, ButtonGroup, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';
import { ReactComponent as RandomImage } from 'assets/images/random.svg';
import classNames from 'classnames';
import { CartItem, ConnectButton, FadeInOut, Spinner } from 'components';
import ApproveRevokeAccess from 'components/ApproveRevokeAccess';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import useCart from 'components/Cart/hooks/useCart';
import { useChainName } from 'helpers';
import { formatNumber } from 'helpers/formatHelpers';
import { useIds } from 'helpers/idHelpers';
import useWindowSize from 'helpers/useWindowSize';
import React from 'react';
import { ICart, ICartItem, ICollectionApprovals, IWalletInfo } from 'types';
import { mainChain } from 'web3/chainsAndWallets';
import './Cart.css';
import getIds from './getIds';

export interface ICartProps extends IBaseComponentProps {
  walletInfo: IWalletInfo;
  cart: ICart;
  collectionApprovals: ICollectionApprovals;
  onShowNftInfo?: (nftId: string) => void;
}

function Cart(props: ICartProps) {
  const ID = useIds(props.id, getIds);
  const chainName = useChainName();
  const { isWidthSmallerThan } = useWindowSize();

  const itemsRef = React.useRef<HTMLDivElement>(null);
  const {
    sums,
    sumsInIota,
    quantityPerCollection,
    totalNumberOfItems,
    itemsIsOverflow,
    missingCollectionApprovals,
    insufficientFunds,
    errorFetchAmountsInIota,
  } = useCart(props, itemsRef);

  const renderCartItem = (cartItem: ICartItem) => {
    const { nft, quantity, toBeRemoved, checkoutErrorCause } = cartItem;
    return (
      <CartItem
        key={nft.id}
        id={nft.id}
        nft={nft}
        name={nft.name}
        image={
          nft.image ?? (
            <RandomImage className="h-[3.5rem] w-[3.5rem] rounded-md bg-yellow-600 object-cover p-2 md:h-[5rem] md:w-[5rem]" />
          )
        }
        quantity={quantity}
        maxQuantity={nft.type === 'random' ? props.cart.maxRandomQuantityPerCollection.get(nft.collection.id) ?? 99 : 1}
        mode={props.cart.mode}
        isLoading={props.cart.checkoutInProgress}
        toBeRemoved={toBeRemoved ?? false}
        checkoutErrorCause={checkoutErrorCause}
        onRemoveClick={() => props.cart.onQuantityChange(nft, 0)}
        onQuantityChange={(quantity) => props.cart.onQuantityChange(nft, quantity)}
        onShowNftInfo={props.onShowNftInfo}
      />
    );
  };

  return (
    <>
      <div className="flex min-h-0 w-full flex-col">
        <FadeInOut isVisible={totalNumberOfItems <= 0} animateFadeOut={false}>
          <div className="flex w-full grow flex-col items-center">
            <Typography>Your cart is empty.</Typography>
            <Typography variant="small">(Click on an Ape to put it into your Cart)</Typography>
          </div>
        </FadeInOut>
        {totalNumberOfItems > 0 && (
          <>
            <div className={classNames('text min-h-0 overflow-y-auto', { 'pr-2': itemsIsOverflow })} ref={itemsRef}>
              <div className="flex flex-col gap-2 md:gap-3">
                {[...props.cart.items].map((cartItem) => renderCartItem(cartItem[1]))}
              </div>
            </div>

            <div>
              <hr className="my-3 dark:border-apedao-black-500 md:my-4" />
              <div className="mb-2">
                <div className="right-0 mb-1 text-[0.6rem] font-normal dark:font-light sm:text-[0.7rem] sm:leading-tight">
                  <span>
                    {quantityPerCollection.map((numberOfItems, index) => {
                      return `${index !== 0 ? ' / ' : ''}${numberOfItems.quantity} ${numberOfItems.collectionName}${
                        numberOfItems.quantity > 1 ? 's' : ''
                      }`;
                    })}
                  </span>
                </div>
                <div className="flex flex-row justify-between text-sm leading-tight tracking-wider sm:text-base">
                  <span>Total</span>
                  <span
                    className={classNames({
                      'opacity-20': props.cart.buyWithIota && errorFetchAmountsInIota != null,
                    })}
                  >
                    <span className="text-[0.6em] font-normal dark:font-light">
                      {props.cart.buyWithIota ? 'wIOTA' : 'APEin'}
                    </span>
                    <span className="ml-1 font-medium">
                      {props.cart.buyWithIota
                        ? formatNumber(sumsInIota.price.amount, sumsInIota.price.decimals, 2)
                        : formatNumber(sums.price, 0, 0)}
                    </span>
                  </span>
                </div>
                <div
                  className={classNames('flex flex-row justify-between text-xs leading-tight tracking-wider', {
                    hidden: sums.fee === 0,
                  })}
                >
                  <span className="font-base dark:font-light">Fees incl.</span>
                  <span>
                    <span className="font-base text-[0.6em] dark:font-light">
                      {props.cart.buyWithIota ? 'wIOTA' : 'APEin'}
                    </span>
                    <span className="ml-1 font-medium">
                      {props.cart.buyWithIota
                        ? formatNumber(sumsInIota.fee.amount, sumsInIota.fee.decimals, 2)
                        : formatNumber(sums.fee, 0, 0)}
                    </span>
                  </span>
                </div>

                <FadeInOut isVisible={insufficientFunds} animateFadeOut={false}>
                  <div
                    className={classNames(
                      'my-1 text-end text-[0.7rem] font-semibold leading-none tracking-wider text-red-500 dark:font-semibold dark:text-red-500 sm:my-2'
                    )}
                  >
                    Insufficient funds in wallet
                  </div>
                </FadeInOut>

                <FadeInOut isVisible={errorFetchAmountsInIota != null} animateFadeOut={false}>
                  <div
                    className={classNames(
                      'my-1 text-end text-[0.7rem] font-semibold leading-none tracking-wider text-red-500 dark:font-semibold dark:text-red-500 sm:my-2'
                    )}
                  >
                    {errorFetchAmountsInIota}
                  </div>
                </FadeInOut>

                <FadeInOut isVisible={chainName === mainChain.chainName && missingCollectionApprovals.length > 0}>
                  <div className="my-1 flex flex-row items-center justify-end gap-2 sm:my-3">
                    <span className="text-end text-[0.7rem] font-semibold leading-none tracking-wider text-red-500 dark:font-semibold dark:text-red-500">
                      Please grant access to the collections
                    </span>
                    <ApproveRevokeAccess
                      collections={missingCollectionApprovals}
                      collectionApprovals={props.collectionApprovals}
                      variant="dialog"
                      placement="top-end"
                      operatorType="apeDaoVault"
                      className="z-[100] -translate-y-1 transition-all"
                    />
                  </div>
                </FadeInOut>

                <FadeInOut isVisible={chainName !== mainChain.chainName}>
                  <div className="my-1 flex flex-row items-center justify-end gap-2 sm:my-3">
                    <span className="text-end text-[0.7rem] font-semibold leading-none tracking-wider text-red-500 dark:font-semibold dark:text-red-500">
                      Please connect to {mainChain.name}
                    </span>
                  </div>
                </FadeInOut>
              </div>

              <div className="flex w-full flex-row gap-1">
                {!props.walletInfo.connected && <ConnectButton id={ID.connectButton} />}
                {props.walletInfo.connected && (
                  <ButtonGroup className="w-full basis-2/3">
                    <Button
                      className={classNames('flex basis-full flex-col items-center justify-center px-0 py-0', {
                        'rounded-lg': props.cart.mode !== 'buy',
                      })}
                      size={isWidthSmallerThan('sm') ? 'sm' : 'md'}
                      disabled={
                        props.cart.checkoutInProgress ||
                        totalNumberOfItems <= 0 ||
                        missingCollectionApprovals.length > 0 ||
                        insufficientFunds ||
                        (props.cart.mode === 'buy' && props.cart.buyWithIota && errorFetchAmountsInIota != null) ||
                        [...props.cart.items.entries()].some(
                          (cartItem) => cartItem[1].checkoutErrorCause === 'unavailable'
                        ) ||
                        chainName !== mainChain.chainName
                      }
                      onClick={props.cart.onCheckout}
                    >
                      <span>
                        {props.cart.mode === 'buy'
                          ? props.cart.buyWithIota
                            ? 'Buy with wIOTA'
                            : 'Buy with APEin'
                          : 'Sell'}
                      </span>
                      {props.cart.mode === 'buy' && props.cart.buyWithIota && (
                        <span className="text-[0.7em] font-semibold leading-relaxed tracking-wider">
                          (via MagicSea)
                        </span>
                      )}
                      {props.cart.mode === 'sell' && props.cart.checkoutInProgress && (
                        <Spinner className="absolute ml-4 h-4 w-4 !border-white"></Spinner>
                      )}
                    </Button>
                    {props.cart.mode === 'buy' && (
                      <Menu placement="bottom-end">
                        <MenuHandler className="w-[2.5rem] rounded-l-none px-0">
                          <Button
                            variant="filled"
                            disabled={props.cart.checkoutInProgress || chainName !== mainChain.chainName}
                          >
                            {props.cart.checkoutInProgress ? (
                              <Spinner className="mx-auto h-4 w-4"></Spinner>
                            ) : (
                              <ChevronDownIcon className="mx-auto h-4 w-4"></ChevronDownIcon>
                            )}
                          </Button>
                        </MenuHandler>
                        <MenuList className="p-2">
                          {props.cart.buyWithIota && (
                            <MenuItem
                              onClick={() => props.cart.onChangeBuyWithIota(false)}
                              className="flex justify-center"
                            >
                              Buy with APEin
                            </MenuItem>
                          )}
                          {!props.cart.buyWithIota && (
                            <MenuItem
                              onClick={() => props.cart.onChangeBuyWithIota(true)}
                              className="flex justify-center"
                            >
                              Buy with wIOTA
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </ButtonGroup>
                )}
                <Button
                  className="w-full basis-1/3 font-normal"
                  size={isWidthSmallerThan('sm') ? 'sm' : 'md'}
                  variant="outlined"
                  disabled={props.cart.checkoutInProgress || totalNumberOfItems <= 0}
                  onClick={props.cart.onResetCart}
                >
                  Reset
                </Button>
              </div>
            </div>
            <FadeInOut isVisible={props.cart.mode === 'buy' && props.cart.buyWithIota}>
              <div className="mt-4 flex h-6 flex-row items-center justify-center gap-2">
                <ExclamationTriangleIcon className="h-10 w-10 text-amber-500 " />
                <span className="text-[0.7rem] font-medium leading-normal tracking-wider dark:font-normal">
                  Buying a large amount of apes with wIOTA might cause high slippage!
                </span>
              </div>
            </FadeInOut>
          </>
        )}
      </div>
    </>
  );
}

export default Cart;
