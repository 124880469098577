import { useEffect, useState } from 'react';
import { useChainId } from 'wagmi';
import { EvmChainName, mainChain, supportedChainsById } from 'web3/chainsAndWallets';

const useChainName = () => {
  const [chainName, setChainName] = useState<EvmChainName>(mainChain.chainName);
  const chainId = useChainId();

  useEffect(() => {
    const chainName = supportedChainsById[chainId]?.chainName;
    setChainName(chainName);
  }, [chainId]);

  return chainName;
};

export default useChainName;
