import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FadeInOut, Spinner } from 'components';
import { useMemo } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import useImage from './hooks/useImage';

export interface IImageProps {
  src: string;
  blurLowRes?: 'blur-sm' | 'blur-md' | 'blur-lg' | 'blur-xl' | 'blur-2xl';
  lowResPlaceholderSrc?: string;
  delayLowResPlaceholder?: number;
  alt?: string;
  className?: string;
  lazyLoad?: boolean;
  onLoad?: (lowRes?: boolean) => void;
  onClick?: () => void;
  onError?: () => void;
}

function Image(props: IImageProps) {
  const {
    showImage,
    showLowResImage,
    showErrorImage,
    onImgLoaded,
    onLowResImgLoaded,
    onImgError,
    onLowResImgError,
    dropLowResImage,
  } = useImage(props);
  const placeholder = useMemo(() => <Spinner className="absolute inset-0 mx-auto my-auto h-8 w-8" />, []);

  return (
    <div
      className={classNames(
        'relative h-fit w-full overflow-hidden',
        { border: !showImage && !showLowResImage },
        props.className
      )}
      onClick={props.onClick}
    >
      <FadeInOut isVisible={showErrorImage} duration="fast">
        <ExclamationCircleIcon
          className={classNames(
            { 'text-red-500/10': !showLowResImage },
            {
              'absolute right-3 top-3 h-10 w-10 stroke-[1.5px] text-red-500/70': showLowResImage,
            }
          )}
        />
      </FadeInOut>
      <LazyLoadComponent visibleByDefault={!props.lazyLoad}>
        <img
          src={props.src}
          className={classNames(
            'w-full transition-opacity duration-500',
            { 'opacity-100': showImage },
            { 'h-0 opacity-0': !showImage }
          )}
          onLoad={() => {
            onImgLoaded();
            props.onLoad && props.onLoad(false);
          }}
          onError={onImgError}
          alt={props.alt ?? 'n/a'}
        />
        {props.lowResPlaceholderSrc && !dropLowResImage && (
          <img
            src={props.lowResPlaceholderSrc}
            className={classNames(
              'w-[100vw] overflow-hidden transition-opacity duration-500',
              { 'animate-pulse': !showErrorImage },
              { 'opacity-100': showLowResImage },
              { [`${props.blurLowRes}`]: props.blurLowRes && showLowResImage },
              { 'h-0 opacity-0': !showLowResImage }
            )}
            onLoad={() => {
              setTimeout(() => {
                onLowResImgLoaded();
                props.onLoad && props.onLoad(true);
              }, props.delayLowResPlaceholder ?? 200);
            }}
            onError={onLowResImgError}
            alt={props.alt ?? 'n/a'}
          />
        )}
        {!showImage && !showLowResImage && !showErrorImage && <div className="h-full w-[100vw]">{placeholder}</div>}
      </LazyLoadComponent>
    </div>
  );
}

export default Image;
