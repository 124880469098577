import { Typography } from '@material-tailwind/react';
import {
  FadeInOut,
  PageTemplate,
  Spinner,
  StatsDistributionLiquidity,
  StatsMarketCap,
  StatsMyApeHoldings,
  StatsPrices,
  StatsTreasury,
  StatsVotingPower,
} from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useWalletInfo } from 'contexts/WalletInfo';
import { useDarkMode } from 'helpers';
import { useIds } from 'helpers/idHelpers';
import getIds from 'pages/OriginalHome/getIds';
import useStats from './hooks';

export interface IStatsProps extends IBaseComponentProps {}

function Stats(props: IStatsProps) {
  const ID = useIds(props.id, getIds);
  const walletInfo = useWalletInfo();
  const { darkMode } = useDarkMode();
  const { stats, statsLoading, statsError, holdingStats, holdingStatsLoading, holdingStatsError, isWidthSmallerThan } =
    useStats();

  return (
    <PageTemplate walletInfo={walletInfo} isLoading={true}>
      <div id={ID.root} className="mx-2 flex flex-col py-5 sm:mx-5 sm:py-8 md:mx-20 md:py-10">
        <div className="flex flex-col items-center">
          <Typography variant="h6" className="uppercase">
            ApeDao Stats
          </Typography>
          <Typography variant="small" className="text-center">
            Stats are updated about every 10 minutes.
          </Typography>

          {statsLoading && !statsError && (
            <div className="mt-10">
              <div className="flex h-40 w-40 flex-col items-center justify-center rounded-full bg-amber-300 opacity-50 dark:opacity-80">
                <Spinner className="h-20 w-20" variant="jumping_ape" />
                <span className="-translate-y-2 text-base font-bold uppercase tracking-wider text-apedao-black-950">
                  Loading
                </span>
              </div>
            </div>
          )}

          {!statsLoading && statsError && (
            <span className="mt-10 max-w-lg rounded-lg border border-amber-500/70 px-4 py-2 text-center font-normal dark:font-light">
              Failed to fetch stats data. Please try again later.
            </span>
          )}

          <FadeInOut isVisible={!statsLoading && stats != null} duration="slow">
            <div className="grid grid-cols-1 gap-3 pt-6 sm:gap-5 md:pt-8 2xl:grid-cols-2">
              <StatsTreasury stats={stats!} isDarkMode={darkMode} mobileView={isWidthSmallerThan('sm')} />
              <StatsMyApeHoldings
                holdings={holdingStats}
                loading={holdingStatsLoading}
                error={holdingStatsError}
                isDarkMode={darkMode}
                mobileView={isWidthSmallerThan('sm')}
                walletConnected={walletInfo.connected ?? false}
              />
              <StatsDistributionLiquidity stats={stats!} isDarkMode={darkMode} mobileView={isWidthSmallerThan('sm')} />
              <StatsVotingPower stats={stats!} isDarkMode={darkMode} />
              <StatsPrices stats={stats!} isDarkMode={darkMode} />
              <StatsMarketCap stats={stats!} isDarkMode={darkMode} mobileView={isWidthSmallerThan('sm')} />
            </div>
          </FadeInOut>
        </div>
      </div>
    </PageTemplate>
  );
}

export default Stats;
