import React, { useCallback, useEffect, useState } from 'react';

function useScrollToTop(elementRef: React.RefObject<HTMLDivElement>) {
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  const onScroll = useCallback(() => {
    if (elementRef.current) {
      setIsAtTop(elementRef.current.scrollTop <= 0);
    }
  }, [elementRef]);

  useEffect(() => {
    if (elementRef.current != null) {
      const resizeObserver = new ResizeObserver(() => {
        setTimeout(
          () => setScrollbarVisible((elementRef.current?.scrollHeight ?? 0) > (elementRef.current?.clientHeight ?? 0)),
          100
        );
      });
      resizeObserver.observe(elementRef.current);
      elementRef.current.onscroll = onScroll;
    }
  }, [elementRef, onScroll]);

  const scrollToTop = useCallback(() => {
    if (elementRef.current) {
      setIsAtTop(true);
      elementRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [elementRef]);

  return { elementRef, scrollbarVisible, isAtTop, scrollToTop };
}

export default useScrollToTop;
