import { useIsOverflow, useScrollToTop } from 'helpers';
import useWindowSize from 'helpers/useWindowSize';
import React from 'react';
import { useAccount, useConfig } from 'wagmi';

function usePageTemplate() {
  const { isWidthSmallerThan, width } = useWindowSize();
  const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = React.useState(false);
  const { chainId } = useAccount();
  const { chains: wagmiChains } = useConfig();
  const isCurrentChainSupported = wagmiChains.some((chain) => chain.id === chainId);

  const mainAreaRef = React.useRef<HTMLDivElement>(null);
  const scrollToTopHook = useScrollToTop(mainAreaRef);
  const [mainAreaIsOverflow] = useIsOverflow(mainAreaRef);

  return {
    windowWidth: width,
    isWindowWidthSmallerThan: isWidthSmallerThan,
    leftSidebarOpen,
    rightSidebarOpen,
    setLeftSidebarOpen,
    setRightSidebarOpen,
    mainAreaRef,
    mainAreaIsOverflow,
    scrollToTopHook,
    unsupportedNetwork: !isCurrentChainSupported,
  };
}

export default usePageTemplate;
