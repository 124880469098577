import useNftContractONFT721ApeStyle from 'helpers/useNftContractONFT721ApeStyle';
import { useCallback } from 'react';
import { IBaseCollection } from 'types';
import { Address } from 'viem';
import { EvmChainName } from 'web3/chainsAndWallets';
import useNftContractERC721Enumerable from './useNftContractERC721Enumerable';
import useNftContractERC721a from './useNftContractERC721a';

export interface INftContractAbstraction {
  getBalanceOf: (chainName: EvmChainName, collection: IBaseCollection, address: Address) => Promise<BigInt>;
  getHoldings: (chainName: EvmChainName, collection: IBaseCollection, address: Address) => Promise<BigInt[]>;
  getOwnerOf: (chainName: EvmChainName, collection: IBaseCollection, edition: number) => Promise<Address | null>;
  getApproval: (
    chainName: EvmChainName,
    collection: IBaseCollection,
    address: Address,
    operator: Address
  ) => Promise<boolean>;
  setApproval: (
    chainName: EvmChainName,
    collection: IBaseCollection,
    operator: Address,
    approve: boolean
  ) => Promise<void>;
  watchTransferEvent: (
    chain: EvmChainName,
    collection: IBaseCollection,
    onTransfer: (transfer: { tokenId: bigint; from: Address; to: Address }) => void,
    onError: (error: Error) => void
  ) => () => void;
}

export interface INftContractImplementation {
  getBalanceOf: (chainName: EvmChainName, contractAddress: Address, address: Address) => Promise<BigInt>;
  getHoldings: (chainName: EvmChainName, contractAddress: Address, address: Address) => Promise<BigInt[]>;
  getOwnerOf: (chainName: EvmChainName, contractAddress: Address, edition: number) => Promise<Address>;
  getApproval: (
    chainName: EvmChainName,
    contractAddress: Address,
    address: Address,
    operator: Address
  ) => Promise<boolean>;
  setApproval: (
    chainName: EvmChainName,
    contractAddress: Address,
    operator: Address,
    approve: boolean
  ) => Promise<void>;
  watchTransferEvent: (
    chain: EvmChainName,
    contractAddress: Address,
    onTransfer: (transfer: { tokenId: bigint; from: Address; to: Address }) => void,
    onError: (error: Error) => void
  ) => () => void;
}

const useNftContractAbstraction = (): INftContractAbstraction => {
  const {
    getBalanceOf: getBalanceOfERC721a,
    getHoldings: getHoldingsERC721a,
    getOwnerOf: getOwnerOfERC721a,
    getApproval: getApprovalERC721a,
    setApproval: setApprovalERC721a,
    watchTransferEvent: watchTransferEventERC721a,
  } = useNftContractERC721a();

  const {
    getBalanceOf: getBalanceOfERC721Enumerable,
    getHoldings: getHoldingsERC721Enumerable,
    getOwnerOf: getOwnerOfERC721Enumerable,
    getApproval: getApprovalERC721Enumerable,
    setApproval: setApprovalERC721Enumerable,
    watchTransferEvent: watchTransferEventERC721Enumerable,
  } = useNftContractERC721Enumerable();

  const {
    getBalanceOf: getBalanceOfONFT721ApeStyle,
    getHoldings: getHoldingsONFT721ApeStyle,
    getOwnerOf: getOwnerOfONFT721ApeStyle,
    getApproval: getApprovalONFT721ApeStyle,
    setApproval: setApprovalONFT721ApeStyle,
    watchTransferEvent: watchTransferEventONFT721ApeStyle,
  } = useNftContractONFT721ApeStyle();

  const getBalanceOf = useCallback(
    async (chainName: EvmChainName, collection: IBaseCollection, address: Address): Promise<BigInt> => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return Promise.resolve(0n);
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return getBalanceOfERC721a(chainName, contractInfo.address, address);
        case 'erc721Enumerable':
          return getBalanceOfERC721Enumerable(chainName, contractInfo.address, address);
        case 'onft721ApeStyle':
          return getBalanceOfONFT721ApeStyle(chainName, contractInfo.address, address);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [getBalanceOfERC721a, getBalanceOfERC721Enumerable, getBalanceOfONFT721ApeStyle]
  );

  const getHoldings = useCallback(
    async (chainName: EvmChainName, collection: IBaseCollection, address: Address): Promise<BigInt[]> => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return Promise.resolve([]);
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return getHoldingsERC721a(chainName, contractInfo.address, address);
        case 'erc721Enumerable':
          return getHoldingsERC721Enumerable(chainName, contractInfo.address, address);
        case 'onft721ApeStyle':
          return getHoldingsONFT721ApeStyle(chainName, contractInfo.address, address);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [getHoldingsERC721Enumerable, getHoldingsERC721a, getHoldingsONFT721ApeStyle]
  );

  const getOwnerOf = useCallback(
    (chainName: EvmChainName, collection: IBaseCollection, edition: number): Promise<Address | null> => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return Promise.resolve(null);
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return getOwnerOfERC721a(chainName, contractInfo.address, edition);
        case 'erc721Enumerable':
          return getOwnerOfERC721Enumerable(chainName, contractInfo.address, edition);
        case 'onft721ApeStyle':
          return getOwnerOfONFT721ApeStyle(chainName, contractInfo.address, edition);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [getOwnerOfERC721Enumerable, getOwnerOfERC721a, getOwnerOfONFT721ApeStyle]
  );

  const getApproval = useCallback(
    async (
      chainName: EvmChainName,
      collection: IBaseCollection,
      address: Address,
      operator: Address
    ): Promise<boolean> => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return Promise.resolve(false);
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return getApprovalERC721a(chainName, contractInfo.address, address, operator);
        case 'erc721Enumerable':
          return getApprovalERC721Enumerable(chainName, contractInfo.address, address, operator);
        case 'onft721ApeStyle':
          return getApprovalONFT721ApeStyle(chainName, contractInfo.address, address, operator);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [getApprovalERC721Enumerable, getApprovalERC721a, getApprovalONFT721ApeStyle]
  );

  const setApproval = useCallback(
    async (
      chainName: EvmChainName,
      collection: IBaseCollection,
      operator: Address,
      approve: boolean
    ): Promise<void> => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return Promise.resolve();
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return setApprovalERC721a(chainName, contractInfo.address, operator, approve);
        case 'erc721Enumerable':
          return setApprovalERC721Enumerable(chainName, contractInfo.address, operator, approve);
        case 'onft721ApeStyle':
          return setApprovalONFT721ApeStyle(chainName, contractInfo.address, operator, approve);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [setApprovalERC721Enumerable, setApprovalERC721a, setApprovalONFT721ApeStyle]
  );

  const watchTransferEvent = useCallback(
    (
      chainName: EvmChainName,
      collection: IBaseCollection,
      onTransfer: (transfer: { tokenId: bigint; from: Address; to: Address }) => void,
      onError: (error: Error) => void
    ): (() => void) => {
      const contractInfo = getContractInfo(collection, chainName);
      if (!contractInfo) {
        return () => {};
      }

      switch (contractInfo?.contractType) {
        case 'erc721a':
          return watchTransferEventERC721a(chainName, contractInfo.address, onTransfer, onError);
        case 'erc721Enumerable':
          return watchTransferEventERC721Enumerable(chainName, contractInfo.address, onTransfer, onError);
        case 'onft721ApeStyle':
          return watchTransferEventONFT721ApeStyle(chainName, contractInfo.address, onTransfer, onError);
        default:
          throw new Error(`Unsupported contract type: ${contractInfo?.contractType}`);
      }
    },
    [watchTransferEventERC721Enumerable, watchTransferEventERC721a, watchTransferEventONFT721ApeStyle]
  );

  return {
    getBalanceOf,
    getHoldings,
    getOwnerOf,
    getApproval,
    setApproval,
    watchTransferEvent,
  };
};

function getContractInfo(collection: IBaseCollection, chainName: EvmChainName) {
  const contractInfo = collection.contracts[chainName];
  if (!contractInfo) {
    console.warn(`No contract info for collection ${collection.name} on chain ${chainName}`);
  }
  return contractInfo;
}

export default useNftContractAbstraction;
