import Big from 'big.js';
import { AssetDisplay, StatsCard } from 'components';
import { IStats } from 'helpers/backendApi/stats_v1';
import { formatBig } from 'helpers/formatHelpers';
import useStatsPrices from './hooks/useStatsPrices';

export interface IStatsPricesProps {
  stats: IStats;
  isDarkMode: boolean;
}

const getDelimiter = (key: string) => <div key={key} className="h-[1em]" />;

export function StatsPrices(props: IStatsPricesProps) {
  const data = useStatsPrices(props);

  if (!data) {
    return <div></div>;
  }

  return (
    <StatsCard title="Prices">
      <div className="flex h-full flex-col">
        <div className="flex flex-row gap-4 sm:gap-6">
          <div className="flex grow flex-col justify-end gap-2 pr-4 sm:gap-1">
            <span className="whitespace-nowrap font-bold">Token / NFT</span>
            {data.map((p, idx) =>
              p === 'delimiter' ? (
                getDelimiter('d_token_' + idx)
              ) : p.isToken ? (
                <AssetDisplay key={p.name} name={p.name} />
              ) : (
                <span key={p.name}>{p.name}</span>
              )
            )}
          </div>
          <div className="flex flex-col items-end gap-2 sm:gap-1">
            <span className="whitespace-nowrap font-bold">USD</span>
            {data.map((p, idx) =>
              p === 'delimiter' ? (
                getDelimiter('d_usd_' + idx)
              ) : (
                <div key={p.name} className="flex w-full flex-row sm:gap-2">
                  <div className="grow text-end font-mono">
                    {p.usd.gt(Big(0)) ? formatBig(p.usd, p.usdDecimals) : '-'}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="flex flex-col items-end gap-2 sm:gap-1">
            <span className="whitespace-nowrap font-bold">wIOTA</span>
            {data.map((p, idx) =>
              p === 'delimiter' ? (
                getDelimiter('d_wiota_' + idx)
              ) : (
                <div key={p.name} className="flex w-full flex-row sm:gap-2">
                  <div className="relative grow text-end font-mono">
                    {p.wiota && (p.wiota.gt(Big(0)) ? formatBig(p.wiota, p.wiotaDecimals) : '-')}
                    {p.smr && (p.smr.gt(Big(0)) ? formatBig(p.smr, p.smrDecimals) : '-')}
                    {p.smr && <span className="absolute -right-2 -top-1.5 text-[0.7em]">*</span>}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {data.some((p) => p !== 'delimiter' && p.smr) && (
          <div className="mt-auto w-full pt-3 text-end text-[0.75em]">* Price in SMR</div>
        )}
      </div>
    </StatsCard>
  );
}

export default StatsPrices;
