import { msg, useNftContractAbstraction } from 'helpers';
import { ACTIVE_COLLECTIONS } from 'helpers/useNftCollections';
import React, { useState } from 'react';
import { Address } from 'viem';

export interface IWalletHoldings {
  nftHoldings?: Map<number, BigInt[]>;
  refresh: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

const useWalletNftHoldingsActiveCollections = (props: {
  address?: Address;
  refreshOnInit?: boolean;
}): IWalletHoldings => {
  const [holdings, setHoldings] = React.useState<Map<number, BigInt[]>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { getHoldings } = useNftContractAbstraction();

  const refresh = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const holdingsPerCollection = new Map<number, BigInt[]>();
      const holdings = await Promise.all(
        ACTIVE_COLLECTIONS.map((collection) =>
          props.address ? getHoldings(collection.chain, collection, props.address) : []
        )
      );

      let i = 0;
      for (const collection of ACTIVE_COLLECTIONS.values()) {
        holdingsPerCollection.set(collection.id, holdings[i++]);
      }
      setHoldings(holdingsPerCollection);
      setIsError(false);
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [getHoldings, props.address]);

  React.useEffect(() => {
    if (props.refreshOnInit ?? true) {
      refresh().catch(() => msg.error(`Error fetching account holdings`));
    }
  }, [props.refreshOnInit, refresh]);

  return {
    nftHoldings: holdings,
    refresh,
    isLoading,
    isError,
  };
};

export default useWalletNftHoldingsActiveCollections;
