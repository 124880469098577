import { useEffect, useState } from 'react';
import { ICollection } from 'types';

const useNftCollection = (initialId: number, getCollection: (id: number) => Promise<ICollection | undefined>) => {
  const [id, setId] = useState<number>(initialId);
  const [collection, setCollection] = useState<ICollection>();

  useEffect(() => {
    getCollection(id).then((collection) => {
      collection && setCollection(collection);
    });
  }, [getCollection, id]);

  return {
    collection,
    setId,
  };
};

export default useNftCollection;
