import Big from 'big.js';
import { assetStatsKeyNameMapping, CollectionKey, IStats, TokenKey } from 'helpers/backendApi/stats_v1';
import React, { useEffect } from 'react';
import { IStatsDistributionLiquidityProps } from '../StatsDistributionLiquidity';

export interface IStatsDistributionLiquidityAssetData {
  name: string;
  total: Big;
  inCirculation: Big;
  inCirculationPercentage: Big;
  locked: Big;
  lockedPercentage: Big;
  holders: Big;
}

function useStatsDistributionLiquidity(props: IStatsDistributionLiquidityProps) {
  const [data, setData] = React.useState<IStatsDistributionLiquidityAssetData[]>();

  useEffect(() => {
    setData(
      Object.keys(props.stats.assetStats).map((key: string) => {
        const assetStats = props.stats.assetStats[key as keyof IStats['assetStats']];
        const inCirculation = Big(assetStats.inCirculation ?? 0);
        const locked = Big(assetStats.locked ?? 0);
        const holders = Big(assetStats.holders ?? 0);

        const total = inCirculation.add(locked);

        return {
          name: assetStatsKeyNameMapping[key as CollectionKey | TokenKey],
          total: inCirculation.add(locked),
          inCirculation: inCirculation,
          inCirculationPercentage: inCirculation.div(total).mul(100),
          locked: locked,
          lockedPercentage: locked.div(total).mul(100),
          holders: holders,
        };
      })
    );
  }, [props.stats.assetStats]);

  return data;
}

export default useStatsDistributionLiquidity;
