import classNames from 'classnames';
import { ApeDaoIcon, SocialIcons } from 'components';
import links from 'links';
import './Footer.css';

export interface IFooterProps {
  className?: string;
}

function Footer(props: IFooterProps) {
  return (
    <footer
      className={classNames(
        props.className,
        'footer-drop-shadow bg-white bg-opacity-80 backdrop-blur-2xl backdrop-saturate-200',
        'dark:bg-apedao-black-800/60 dark:text-apedao-black-100 dark:shadow-none'
      )}
    >
      <hr className="dark:border-apedao-black-500" />
      <div className="flex h-full flex-row items-center gap-3 px-3 md:grid md:grid-cols-3 md:px-4">
        <ApeDaoIcon className="footer-ape-image h-6 w-6 justify-self-start sm:h-8 sm:w-8" noBackground={true} />
        <div className="md: flex grow flex-col items-start justify-center justify-self-center text-xs tracking-wider dark:font-light md:grow-0 md:items-center md:text-sm md:leading-relaxed">
          <span className="flex flex-col gap-1 sm:flex-row">
            <span className="hidden whitespace-nowrap sm:inline">
              &copy; {new Date().getFullYear()} ApeDAO<span className="hidden lg:inline">.</span>
            </span>
            <span className="hidden whitespace-nowrap lg:inline">All rights reserved.</span>
            <a
              href={links.docs + '/litepaper/terms-and-conditions'}
              target="_blank"
              rel="noreferrer"
              className="whitespace-nowrap underline underline-offset-2 hover:font-normal sm:ml-5"
            >
              Terms & Conditions
            </a>
          </span>
        </div>
        <SocialIcons className="translate-x-2 md:justify-self-end" />
      </div>
    </footer>
  );
}

export default Footer;
