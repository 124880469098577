import { ClipboardIcon } from '@heroicons/react/24/outline';
import { Alert, Button, Card, CardBody, CardFooter, IconButton, Typography } from '@material-tailwind/react';
import { ApeDaoIcon, PageTemplate } from 'components';
import { IBaseComponentProps } from 'components/BaseComponentProps';
import { useWalletInfo } from 'contexts/WalletInfo';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetch } from 'usehooks-ts';
import { useSignMessage } from 'wagmi';

export interface IMigrationProps extends IBaseComponentProps {}

interface IMigrationData {
  eth: string;
  smr: string;
}

function Migration(props: IMigrationProps) {
  const walletInfo = useWalletInfo();
  const { data: migrationData, error: migrationDataError } = useFetch<IMigrationData[]>('/migration_data.json');
  const { isSuccess, error: walletError, signMessage } = useSignMessage();
  const [smrAddress, setSmrAddress] = useState<string>('');

  const checkWallet = async () => {
    await signMessage({ message: 'Approve the ownership of your wallet' });
  };

  const copySmrAddressToClipboard = async () => {
    await navigator.clipboard.writeText(smrAddress);
  };

  const openDiscord = () => {
    window.open('https://discord.com/channels/952695802415943750/1151033328305180702');
  };

  useEffect(() => {
    if (isSuccess && migrationData) {
      const walletAddress = walletInfo.address?.toLowerCase();
      const mapping = migrationData.find((data) => data.eth.toLowerCase() === walletAddress);
      if (mapping) {
        setSmrAddress(mapping.smr);
      }
    }
  }, [walletInfo.address, isSuccess, migrationData]);

  return (
    <PageTemplate walletInfo={walletInfo}>
      <div id={props.id} className="flex min-h-[inherit] flex-col items-center justify-center gap-6 p-4">
        {!walletInfo.connected && (
          <>
            <div className="aspect-square max-w-[70vw]">
              <ApeDaoIcon className="w-full opacity-30 dark:opacity-80" />
            </div>
            <Typography variant="lead" className="text-center text-base md:text-xl">
              Connect your wallet
            </Typography>
          </>
        )}

        {walletInfo.connected && migrationDataError && (
          <Typography className="text-center">Something went totally wrong. Please try to refresh the page.</Typography>
        )}

        {walletInfo.connected && !migrationDataError && (
          <Card className="w-[40rem] max-w-[calc(100vw-2rem)]">
            <CardBody className="flex flex-col gap-4">
              <Typography variant="h5">Ape/Banana Migration</Typography>
              <hr />
              {!isSuccess && (
                <div className="flex flex-col gap-4">
                  <Typography>You have to sign a message to approve wallet ownership.</Typography>
                  <Typography>
                    If the message has been signed successfully and your wallet address is on the snapshot list from
                    Soonaverse, you will see a smr1 address dedicated to you.
                  </Typography>
                </div>
              )}
              {isSuccess && smrAddress && (
                <div className="flex flex-col gap-4">
                  <Typography>Here we go!</Typography>
                  <div className="relative flex max-w-full flex-row items-center justify-between rounded-md border p-3 pr-11">
                    <span className="w-full break-words text-center font-mono text-sm dark:font-light">
                      {smrAddress}
                    </span>
                    <IconButton
                      variant="text"
                      onClick={copySmrAddressToClipboard}
                      className="!absolute right-0.5 !overflow-visible hover:bg-transparent focus:bg-transparent"
                    >
                      <ClipboardIcon className="h-6 w-6"></ClipboardIcon>
                    </IconButton>
                  </div>
                  <Typography>
                    This smr1 address has been assigned to you. Send apes/bananas that you wish to migrate from your
                    Firefly wallet to this address. Be careful to send apes/bananas only to that address! And don't
                    forget to
                    <b className="ml-1 font-semibold">gift storage deposit</b>.
                  </Typography>
                  <Typography>
                    Wait for our script to recognise received apes/bananas and airdrop it to your address on ShimmerEVM.
                    You won't see them automatically in Metamask but here on 'My NFTs' page. If you also want to see
                    them in Metamask follow the{' '}
                    <Link
                      to="https://docs.apedao.finance/ape-nfts/our-nft-collections/add-apes-to-metamask"
                      target="_blank"
                      className="text-blue-500 underline dark:text-blue-400"
                    >
                      instructions
                    </Link>{' '}
                    in our docs.
                  </Typography>
                </div>
              )}
              {isSuccess && !smrAddress && (
                <Typography>
                  Sorry, your address could not be found in the Soonaverse snapshot. Please head over to ApeDAO Discord
                  and open a support ticket.
                </Typography>
              )}
              {walletError && <Alert color="red">Signing the message failed. Please retry.</Alert>}
            </CardBody>
            <CardFooter className="ml-auto mr-auto pt-0">
              {!isSuccess && <Button onClick={checkWallet}>Sign message</Button>}
              {isSuccess && !smrAddress && <Button onClick={openDiscord}>Open Discord</Button>}
            </CardFooter>
          </Card>
        )}
      </div>
    </PageTemplate>
  );
}

export default Migration;
