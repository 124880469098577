import { useRcpBucket } from 'contexts/RcpBucket';
import { INftContractImplementation } from 'helpers/useNftContractAbstraction';
import { useCallback } from 'react';
import { Address } from 'viem';
import { multicall, readContract, simulateContract, watchContractEvent } from 'viem/actions';
import { useWalletClient } from 'wagmi';
import erc721EnumerableAbi from 'web3/abi/ERC721EnumerableAbi';
import erc721aAbi from 'web3/abi/ERC721aAbi';
import { EvmChainName, getClient } from 'web3/chainsAndWallets';

const range = (start: number, end: number) =>
  Array.from(Array(end - start + 1).keys()).map((x) => BigInt(x) + BigInt(start));

const useNftContractERC721a = (): INftContractImplementation => {
  const { data: walletClient } = useWalletClient();
  const { throttleRequest } = useRcpBucket();

  const getBalanceOf = useCallback(
    async (chain: EvmChainName, contractAddress: Address, address: Address): Promise<BigInt> => {
      throttleRequest && (await throttleRequest());
      const client = getClient(chain);
      return await readContract(client, {
        address: contractAddress,
        abi: erc721aAbi,
        functionName: 'balanceOf',
        args: [address],
      });
    },
    [throttleRequest]
  );

  const getHoldings = useCallback(
    async (chain: EvmChainName, contractAddress: Address, address: Address): Promise<BigInt[]> => {
      throttleRequest && (await throttleRequest());
      const client = getClient(chain);
      const numberOfHoldings = await readContract(client, {
        address: contractAddress,
        abi: erc721EnumerableAbi,
        functionName: 'balanceOf',
        args: [address],
      });

      const responses = await multicall(client, {
        contracts: range(0, Number(numberOfHoldings) - 1).map((index) => ({
          address: contractAddress,
          abi: erc721EnumerableAbi,
          functionName: 'tokenOfOwnerByIndex',
          args: [address, index],
        })) as { address: Address; abi: typeof erc721EnumerableAbi; functionName: 'tokenOfOwnerByIndex' }[],
      });

      return responses.map((response) => response.result!);
    },
    [throttleRequest]
  );

  const getOwnerOf = useCallback(
    async (chain: EvmChainName, contractAddress: Address, edition: number): Promise<Address> => {
      throttleRequest && (await throttleRequest());
      const client = getClient(chain);
      return await readContract(client, {
        address: contractAddress,
        abi: erc721EnumerableAbi,
        functionName: 'ownerOf',
        args: [BigInt(edition)],
      });
    },
    [throttleRequest]
  );

  const getApproval = useCallback(
    async (chain: EvmChainName, tokenAddress: Address, address: Address, operator: Address): Promise<boolean> => {
      throttleRequest && (await throttleRequest());
      const client = getClient(chain);
      return await readContract(client, {
        address: tokenAddress,
        abi: erc721EnumerableAbi,
        functionName: 'isApprovedForAll',
        args: [address, operator],
      });
    },
    [throttleRequest]
  );

  const setApproval = useCallback(
    async (chain: EvmChainName, tokenAddress: Address, operator: Address, approve: boolean): Promise<void> => {
      throttleRequest && (await throttleRequest());
      const client = getClient(chain);
      const { request } = await simulateContract(client, {
        address: tokenAddress,
        abi: erc721EnumerableAbi,
        functionName: 'setApprovalForAll',
        args: [operator, approve],
      });
      walletClient && (await walletClient.writeContract(request));
    },
    [throttleRequest, walletClient]
  );

  const watchTransferEvent = useCallback(
    (
      chain: EvmChainName,
      contractAddress: Address,
      onTransfer: (transfer: { tokenId: bigint; from: Address; to: Address }) => void,
      onError: (error: Error) => void
    ) => {
      const client = getClient(chain, true);
      return watchContractEvent(client, {
        address: contractAddress,
        abi: erc721EnumerableAbi,
        eventName: 'Transfer',
        onError: (error) => {
          console.error(`Error watching transfer event on chain ${chain}`, error);
          onError(error);
        },
        onLogs: (logs) => {
          for (const log of logs) {
            const { tokenId, from, to } = log.args;
            tokenId && from && to && onTransfer({ tokenId, from, to });
          }
        },
      });
    },
    []
  );

  return {
    getBalanceOf,
    getHoldings,
    getOwnerOf,
    getApproval,
    setApproval,
    watchTransferEvent,
  };
};

export default useNftContractERC721a;
