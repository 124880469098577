import { useNftContractAbstraction } from 'helpers';
import { COLLECTIONS } from 'helpers/useNftCollections';
import React, { useCallback, useEffect } from 'react';
import { IBaseCollection } from 'types';
import { Address } from 'viem';
import { EvmChainName, supportedChains } from 'web3/chainsAndWallets';

export interface INftTransferEvent {
  nftId: string;
  collectionId: number;
  edition: number;
  from: Address;
  to: Address;
  chain: EvmChainName;
  onBridge: boolean;
}

export type TransferEventListener = (transfer: INftTransferEvent) => void;

const useNftTransferEvents = (props: { collection: IBaseCollection | undefined }) => {
  const { watchTransferEvent } = useNftContractAbstraction();
  const [newTransferEvents, setNewTransferEvents] = React.useState<INftTransferEvent[]>([]);
  const [transferEventListeners, setTransferEventListeners] = React.useState<Map<string, TransferEventListener>>(
    new Map()
  );

  useEffect(() => {
    if (props.collection) {
      const unwatch = supportedChains.map((chain) => {
        const transferHandler = (transfer: { tokenId: bigint; from: Address; to: Address }) => {
          const contracts = COLLECTIONS.get(props.collection!.id)?.contracts;
          const { address: nftAddress, lzProxyAddress, contractType } = contracts?.[chain.chainName] ?? {};
          const onBridge =
            contractType === 'onft721ApeStyle' ? transfer.to === nftAddress : transfer.to === lzProxyAddress;

          const transferEvent: INftTransferEvent = {
            nftId: `${props.collection!.id}-${transfer.tokenId}`,
            collectionId: props.collection!.id,
            edition: Number(transfer.tokenId),
            from: transfer.from,
            to: transfer.to,
            chain: chain.chainName,
            onBridge,
          };
          console.log('NFT transfer transferEvent: ', transferEvent);
          setNewTransferEvents((prevState) => [...prevState, transferEvent]);
        };

        console.log('registering event handlers');
        return watchTransferEvent(chain.chainName, props.collection!, transferHandler, () => {
          unwatch.forEach((unwatch) => unwatch());
          setTransferEventListeners(new Map(transferEventListeners.entries()));
        });
      });

      return () => {
        unwatch.forEach((unwatch) => unwatch());
      };
    }
  }, [props.collection, transferEventListeners, watchTransferEvent]);

  useEffect(() => {
    if (newTransferEvents.length > 0) {
      newTransferEvents.forEach((transfer) => {
        transferEventListeners.forEach((listener) => {
          listener(transfer);
        });
      });
      setNewTransferEvents([]);
    }
  }, [newTransferEvents, transferEventListeners]);

  const registerListener = useCallback((listener: TransferEventListener): (() => void) => {
    const id = Math.random().toString(16).slice(2);
    setTransferEventListeners((prevState) => {
      return new Map(prevState.set(id, listener));
    });

    return () => {
      setTransferEventListeners((prevState) => {
        prevState.delete(id);
        return new Map(prevState);
      });
    };
  }, []);

  return { registerListener };
};

export default useNftTransferEvents;
