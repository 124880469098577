import React from 'react';

const useIsOverflow = (
  ref: React.RefObject<HTMLElement>,
  callback?: (hasOverflow: boolean) => void
): [boolean, () => void] => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
  const trigger = React.useCallback(() => {
    if (ref.current) {
      const hasOverflow = (ref.current?.scrollHeight ?? 0) > (ref.current?.clientHeight ?? 0);
      setIsOverflow(hasOverflow);

      callback && callback(hasOverflow);
    }
  }, [ref, callback]);

  React.useLayoutEffect(() => {
    if ('ResizeObserver' in window) {
      if (ref.current) {
        new ResizeObserver(trigger).observe(ref.current);
      }
    }

    trigger();
  }, [trigger, callback, ref]);

  return [isOverflow, trigger];
};

export default useIsOverflow;
