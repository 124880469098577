import { IBridgePanelProps } from 'components/BridgePanel/BridgePanel';
import { useWalletInfo } from 'contexts/WalletInfo';

function useBridgePanel(props: IBridgePanelProps) {
  const walletInfo = useWalletInfo();

  const { contractApprovals, needsContractApproval, estimatedFee } = props.lzBridgeHandler;

  const approvalRequirementsAreMet =
    props.lzBridgeHandler.collection == null ||
    !needsContractApproval ||
    contractApprovals.isLoading ||
    contractApprovals.approvals.get(props.lzBridgeHandler.collection.id) === true;

  const insufficientFunds = estimatedFee != null && (walletInfo.nativeBalance?.value ?? 0n) < estimatedFee;
  let txInProgressForSelectedNfts = props.lzBridgeHandler.selectedNftIds
    .filter((nftId) => props.lzBridgeHandler.nftsOnBridge[nftId] != null)
    .map((nftId) => props.lzBridgeHandler.nftsOnBridge[nftId]!.txHash);

  const tooManyNftsSelected = props.lzBridgeHandler.selectedNftIds.length > props.lzBridgeHandler.batchLimit;

  return {
    lzBridge: props.lzBridgeHandler,
    approvalRequirementsAreMet,
    insufficientFunds,
    tooManyNftsSelected,
    nativeAssetParams: {
      decimals: walletInfo.nativeBalance?.decimals ?? 0,
      symbol: walletInfo.nativeBalance?.symbol ?? '',
    },
    txInProgressForSelectedNfts,
  };
}

export default useBridgePanel;
