import { httpGet } from 'helpers/fetch';
import { useApeDaoVaultInfo } from 'helpers/index';
import { createAttributesMap } from 'helpers/nftHelpers';
import { IApeDaoVaultInfo } from 'helpers/useApeDaoVaultInfo';
import { useCallback, useMemo } from 'react';
import {
  AttributeName,
  IBaseCollection,
  ICollection,
  ICollectionItem,
  ICollectionItemAttribute,
  INft,
  NftContractType,
  TraitName,
} from 'types';
import { Address } from 'viem';
import { nftAddresses } from 'web3/contracts';

const optional = (
  addresses?: {
    address: Address;
    lzProxyAddress?: Address;
  },
  contractType?: NftContractType
): { address: Address; contractType: NftContractType; lzProxyAddress?: Address } | undefined => {
  return (
    addresses?.address &&
    contractType && {
      address: addresses.address,
      contractType,
      lzProxyAddress: addresses?.lzProxyAddress,
    }
  );
};

export const COLLECTIONS: Map<number, IBaseCollection> = new Map([
  [
    0,
    {
      id: 0,
      name: 'OG Ape',
      icon: '/images/assets/ogApe.png',
      active: true,
      price: 500,
      totalNumber: 1074,
      url: '/collections/ogApes.json',
      backendKey: 'ogApe',
      lowResImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/25x25/',
      baseImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/ogapes/350x350/',
      baseHQImageUrl: 'https://apedao.mypinata.cloud/ipfs/QmNbZSywnbrf4HSAzDX1jvdvwCkHKgRACChZXF4j1t3X4S/',
      hqImageUrlIsPinata: true,
      sortBy: 'rank',
      emptyListMessage: 'All apes are out in the woods',
      featureVault: true,
      featureWallet: true,
      featureCart: true,
      contracts: {
        iotaEvm: optional(nftAddresses.iotaEvm?.apeDaoOGApeNFT, 'erc721Enumerable'),
        fantom: optional(nftAddresses.fantom?.apeDaoOGApeNFT, 'onft721ApeStyle'),
        bsc: optional(nftAddresses.bsc?.apeDaoOGApeNFT, 'onft721ApeStyle'),
      },
      chain: 'iotaEvm',
    },
  ],
  [
    1,
    {
      id: 1,
      name: "Lil' Ape",
      icon: '/images/assets/lilApe.png',
      active: true,
      price: 100,
      totalNumber: 5370,
      url: '/collections/lilApes.json',
      backendKey: 'lilApe',
      lowResImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/lilapes/25x25/',
      baseImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/lilapes/350x350/',
      baseHQImageUrl: 'https://apedao.mypinata.cloud/ipfs/QmV1WgpbwNwiafkdmVztmxhbAutuEpe4mg7oaL741QQ8vc/',
      hqImageUrlIsPinata: true,
      sortBy: 'rank',
      emptyListMessage: 'All apes are out in the woods',
      featureVault: true,
      featureWallet: true,
      featureCart: true,
      contracts: {
        iotaEvm: optional(nftAddresses.iotaEvm?.apeDaoLilApeNFT, 'erc721Enumerable'),
        fantom: optional(nftAddresses.fantom?.apeDaoLilApeNFT, 'onft721ApeStyle'),
        bsc: optional(nftAddresses.bsc?.apeDaoLilApeNFT, 'onft721ApeStyle'),
      },
      chain: 'iotaEvm',
    },
  ],
  [
    2,
    {
      id: 2,
      name: 'Banana',
      icon: '/images/assets/banana.png',
      active: false,
      price: null,
      totalNumber: 1111,
      url: '/collections/bananas.json',
      backendKey: 'banana',
      lowResImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/bananas/25x25/',
      baseImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/bananas/350x350/',
      baseHQImageUrl: 'https://apedao.mypinata.cloud/ipfs/QmS3dQddEwSW89ZT1HghFdrn9dF3EfkK3FMBF7Zkh2mb41/',
      hqImageUrlIsPinata: true,
      sortBy: 'rank',
      emptyListMessage: 'Hungry apes ate all the bananas',
      featureVault: false,
      featureWallet: true,
      featureCart: false,
      contracts: {
        shimmerEvm: optional(nftAddresses.shimmerEvm?.apeDaoBananaNFT, 'erc721Enumerable'),
        fantom: optional(nftAddresses.fantom?.apeDaoBananaNFT, 'onft721ApeStyle'),
        bsc: optional(nftAddresses.bsc?.apeDaoBananaNFT, 'onft721ApeStyle'),
      },
      chain: 'shimmerEvm',
    },
  ],
  [
    3,
    {
      id: 3,
      name: 'Blob',
      icon: '/images/assets/blob.png',
      active: false,
      price: null,
      totalNumber: 3200,
      url: '/collections/blob.json',
      backendKey: 'blob',
      lowResImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/blobs/25x25/',
      baseImageUrl: 'https://apedao-images.s3.eu-central-1.amazonaws.com/blobs/350x350/',
      baseHQImageUrl: 'https://apedao.mypinata.cloud/ipfs/QmUYvhdWummvdtZUkH48TWjoA4SCNd7FfRhJybrXG7Xwbr/',
      hqImageUrlIsPinata: true,
      sortBy: 'rank',
      emptyListMessage: 'Blob Blob Blob',
      featureVault: false,
      featureWallet: true,
      featureCart: false,
      contracts: {
        shimmerEvm: optional(nftAddresses.shimmerEvm?.apeDaoBlobNFT, 'erc721a'),
        fantom: optional(nftAddresses.fantom?.apeDaoBlobNFT, 'onft721ApeStyle'),
        bsc: optional(nftAddresses.bsc?.apeDaoBlobNFT, 'onft721ApeStyle'),
      },
      chain: 'shimmerEvm',
    },
  ],
]);

export const ACTIVE_COLLECTIONS: IBaseCollection[] = [...COLLECTIONS.values()].filter((c) => c.active);

export interface IUseNftCollectionResult {
  collections: Map<number, ICollection>;
  getCollection: (id: number) => Promise<ICollection | undefined>;
  isLoading: boolean;
}

function useNftCollections(): IUseNftCollectionResult {
  const { info: apeDaoVaultInfo } = useApeDaoVaultInfo();
  const collections = useMemo<Map<number, ICollection>>(() => new Map(), []);
  const inProgress = useMemo<Map<number, Promise<ICollection | undefined>>>(() => new Map(), []);

  const getCollection = useCallback(
    async (id: number): Promise<ICollection | undefined> => {
      if (apeDaoVaultInfo && collections.get(id) == null && !inProgress.has(id)) {
        try {
          const fetchPromise = fetchCollectionData(id, apeDaoVaultInfo);
          inProgress.set(id, fetchPromise);

          const collection = await inProgress.get(id);
          collections.set(id, collection!);
          return collection;
        } finally {
          inProgress.delete(id);
        }
      } else if (inProgress.has(id)) {
        return inProgress.get(id);
      } else {
        return collections.get(id);
      }
    },
    [apeDaoVaultInfo, collections, inProgress]
  );

  return {
    collections,
    getCollection,
    isLoading: apeDaoVaultInfo == null,
  };
}

async function fetchCollectionData(id: number, apeDaoVaultInfo: IApeDaoVaultInfo): Promise<ICollection> {
  const baseCollection = COLLECTIONS.get(id)!;
  const collectionItems = await httpGet<ICollectionItem[]>(baseCollection.url).then((response) => response.parsedBody!);
  const collectionPrice = baseCollection.price ?? 0;

  const symbol = apeDaoVaultInfo.symbol;
  const sellFeePercentage = apeDaoVaultInfo.mintFeePercentage;
  const buyFeePercentage = apeDaoVaultInfo.targetFeePercentage;
  const buyRandomFeePercentage = apeDaoVaultInfo.randomFeePercentage;

  const collection: ICollection = {
    ...baseCollection,
    symbol: symbol,
    buyFee: (collectionPrice * Number(buyFeePercentage)) / 100,
    buyFeePercentage: Number(buyFeePercentage),
    buyRandomFee: (collectionPrice * Number(buyRandomFeePercentage)) / 100,
    buyRandomFeePercentage: Number(buyRandomFeePercentage),
    sellFee: (collectionPrice * Number(sellFeePercentage)) / 100,
    sellFeePercentage: Number(sellFeePercentage),
    nfts: [],
    attributes: new Map(),
  };

  collection.nfts = collectionItems
    .map((item) => {
      return {
        id: `${id}-${item.edition}`,
        type: 'nft',
        collection: collection,
        lowResImage: `${collection!.lowResImageUrl}${item.edition}.png`,
        image: `${collection!.baseImageUrl}${item.edition}.png`,
        hqImage: collection.hqImageUrlIsPinata
          ? `${collection!.baseHQImageUrl}${item.edition}.png?img-width=480&img-height=480`
          : `${collection!.baseHQImageUrl}${item.edition}.png`,
        originalImage: collection.hqImageUrlIsPinata
          ? `${collection!.baseHQImageUrl}${item.edition}.png`
          : `${collection!.baseHQImageUrl}${item.edition}.png`,
        name: item.name,
        edition: item.edition,
        rank: item.rank ?? -1,
        dna: item.dna,
        nftAttributes: toAttributesMap(item.attributes),
        location: {
          chain: 'n/a',
        },
      } as INft;
    })
    .sort((a, b) => {
      if (collection.sortBy === 'rank' && a.rank !== b.rank) {
        return a.rank - b.rank;
      }
      return a.edition - b.edition;
    });

  collection.attributes = createAttributesMap(collection.nfts);

  return collection;
}

const toAttributesMap = (attributes: ICollectionItemAttribute[]): Map<AttributeName, TraitName> => {
  const attributesMap = new Map<string, string>();
  attributes.forEach((attribute) => {
    attributesMap.set(attribute.trait_type, attribute.value);
  });

  return attributesMap;
};

export default useNftCollections;
