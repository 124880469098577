import { isDevMode } from 'helpers/storageHelpers';
import { IContract, IContracts, NftAddresses } from 'types';
import { Address, erc721Abi } from 'viem';
import accumulatorV6Abi from 'web3/abi/AccumulatorVaultV6Abi';
import apeDaoMarketAbi from 'web3/abi/ApeDaoMarketAbi';
import apeDaoVaultUpgradeableAbi from 'web3/abi/ApeDaoVaultUpgradeableAbi';
import epochZeroNFTMarketAbi from 'web3/abi/EpochZeroNFTMarketAbi';
import ierc20 from 'web3/abi/IERC20Abi';
import magicSeaRouterAbi from 'web3/abi/IUniswapV2Router02Abi';
import magicSeaLpAbi from 'web3/abi/MagicSeaLPAbi';
import magicSeaMasterChefAbi from 'web3/abi/MagicSeaMasterChefAbi';
import magicSeaNFTMarketAbi from 'web3/abi/MagicSeaNFTMarketAbi';

type ContractAddresses = keyof IContracts | 'apeDaoMigrationWallet';

const unknownAddress: Address = '0x0000000000000000000000000000000000000000';

// --- shimmerEvm ---
// const mainnetNftAddresses: NftAddresses = {
//   shimmerEvm: {
//     apeDaoOGApeNFT: { address: '0xf640ed4ADFD525a3DEae9FA76a840898d61009C1' },
//     apeDaoLilApeNFT: { address: '0x3F5ae5270b404fF94BB4d2f15A4f3b46f16470D1' },
//     apeDaoBananaNFT: { address: '0x2285e8d510F06fAc966F0D3F6522eDe874EBe9F4' },
//     apeDaoBlobNFT: { address: '0xc0CDfa2b4300ec3cE105216106485c57Cd61F5B5' },
//   },
// };
//
// const mainnetAddresses: Record<ContractAddresses, Address> = {
//   apeDaoMarket: '0x6127C40AA99E5764Ebc730F8c3f7602E27EF07A3',
//   apeDaoVault: '0x264F2e6142CE8bEA68e5C646f8C07db98A9E003A',
//   apeDaoMigrationWallet: '0x784c15C25284A1070621E297aE1a850EFAC6b04A',
//   apeDaoBronzeSupporter: '0xbCf75243604Eb9933C65A5633Ad66c06A0f0A775',
//   apeDaoSilverSupporter: '0xdf66016d939332644149185d080321aC209820EF',
//   apeDaoGoldSupporter: '0x4084C25818F8FF85F608413c7530B4e1d4493C39',
//   magicSeaRouter: '0x3EdAFd0258F75E0F49d570B1b28a1F7A042bcEC3',
//   magicSeaMasterChef: '0x686eAd3Fee35C811684E6158408B49220d912dD4',
//   magicSeaLP: '0xf187872d281c001552687479c8b92a0d45f0463f',
//   magicSeaNFTMarket: '0xA2F63232c962357C5866218D47623c244Fc82f58',
//   accumulatorSMRAPEin: '0x0eD432E6055caB8f486950Ba656015c04B62B9FA',
//   tokenShimmer: '0x1074010000000000000000000000000000000000',
// };

const mainnetNftAddresses: NftAddresses = {
  iotaEvm: {
    apeDaoOGApeNFT: { address: '0xC70516C114003202C6b6cD37972B8d9f5e7D05E3' },
    apeDaoLilApeNFT: { address: '0xE96661680Dde025d112cfB915071FB3B8f7d1fd0' },
    apeDaoBananaNFT: { address: unknownAddress },
    apeDaoBlobNFT: { address: unknownAddress },
  },
  shimmerEvm: {
    apeDaoOGApeNFT: { address: unknownAddress },
    apeDaoLilApeNFT: { address: unknownAddress },
    apeDaoBananaNFT: { address: '0x2285e8d510F06fAc966F0D3F6522eDe874EBe9F4' },
    apeDaoBlobNFT: { address: '0xc0CDfa2b4300ec3cE105216106485c57Cd61F5B5' },
  },
};

const mainnetAddresses: Omit<Record<ContractAddresses, Address>, 'epochZeroNFTMarket'> = {
  apeDaoMarket: '0xdf3C89B783c18831253046cA2CcEd94fB54E1977',
  apeDaoVault: '0xe1fD2e5676396B95fcB0F766392E1a277Bc0BeE4',
  apeDaoMigrationWallet: unknownAddress,
  apeDaoBronzeSupporter: unknownAddress,
  apeDaoSilverSupporter: unknownAddress,
  apeDaoGoldSupporter: unknownAddress,
  magicSeaRouter: '0x531777F8c35fDe8DA9baB6cC7093A7D14a99D73E',
  magicSeaMasterChef: '0x686eAd3Fee35C811684E6158408B49220d912dD4',
  magicSeaLP: '0x1ca7c9eaa3a06a9d9add5ef1f43647fa7ee9007c',
  magicSeaNFTMarket: unknownAddress,
  accumulatorIotaAPEin: unknownAddress,
  tokenShimmer: '0x1074010000000000000000000000000000000000',
};

const testnetNftAddresses: NftAddresses = {
  // shimmerEvm: {
  //   apeDaoOGApeNFT: {
  //     address: '0xf7f0687F4A658C47821FF1E839620Fac35cc5d62',
  //     lzProxyAddress: '0xB9F36830641F0D676c604b0Fe64E8d5554C90C37',
  //   },
  //   apeDaoLilApeNFT: { address: '0xAeb46a1769C09Ec13e2E7937041f21b117Ca77f7' },
  //   apeDaoBlobNFT: { address: '0x1362659D95b23F3c72b34Eaf5d5424Ab50C91f65' },
  // },
  fantom: {
    apeDaoOGApeNFT: {
      address: '0x2e9ef91d5E7a99512f5cC6A1Ab0DF3804FD66457',
    },
  },
  bsc: {
    apeDaoOGApeNFT: {
      address: '0xE3cA4D93277cDFf239032E08A7383ea6775b6A95',
    },
  },
};

const testnetAddresses: Omit<Record<ContractAddresses, Address>, 'epochZeroNFTMarket'> = {
  apeDaoMarket: '0xf69d0aEe3b67c5E201D8D0686Fc3eAF1C1e61707',
  apeDaoVault: '0x1a63cf53A3e1c1CaE22eFE1aE924A124212087A7',
  apeDaoMigrationWallet: '0x784c15C25284A1070621E297aE1a850EFAC6b04A',
  apeDaoBronzeSupporter: unknownAddress,
  apeDaoSilverSupporter: unknownAddress,
  apeDaoGoldSupporter: unknownAddress,
  magicSeaRouter: '0x531777F8c35fDe8DA9baB6cC7093A7D14a99D73E',
  magicSeaMasterChef: '0x46C0A19257663E71602df0cfD2Ae76e6b7871c71',
  magicSeaLP: unknownAddress,
  magicSeaNFTMarket: unknownAddress,
  accumulatorIotaAPEin: unknownAddress,
  tokenShimmer: '0x1074010000000000000000000000000000000000',
};

const contractAddresses: Omit<Record<ContractAddresses, Address>, 'epochZeroNFTMarket'> = isDevMode()
  ? testnetAddresses
  : mainnetAddresses;
const nftAddresses: NftAddresses = isDevMode() ? testnetNftAddresses : mainnetNftAddresses;

const optional = <T>(address: Address, abi: T): IContract<T> | undefined => {
  if (address !== unknownAddress) {
    return {
      address,
      abi,
    };
  }
  return undefined;
};

let contracts: IContracts = {
  apeDaoVault: {
    address: contractAddresses.apeDaoVault,
    abi: apeDaoVaultUpgradeableAbi,
  },
  apeDaoMarket: {
    address: contractAddresses.apeDaoMarket,
    abi: apeDaoMarketAbi,
  },
  magicSeaLP: {
    address: contractAddresses.magicSeaLP,
    abi: magicSeaLpAbi,
  },
  magicSeaMasterChef: {
    address: contractAddresses.magicSeaMasterChef,
    abi: magicSeaMasterChefAbi,
  },
  magicSeaRouter: {
    address: contractAddresses.magicSeaRouter,
    abi: magicSeaRouterAbi,
  },
  magicSeaNFTMarket: {
    address: contractAddresses.magicSeaNFTMarket,
    abi: magicSeaNFTMarketAbi,
  },
  tokenShimmer: {
    address: contractAddresses.tokenShimmer,
    abi: ierc20,
  },
  epochZeroNFTMarket: {
    iotaEvm: {
      address: '0x33143817AE44CEE99D6d3Ee85B03d46A8964d412',
      abi: epochZeroNFTMarketAbi,
    },
    shimmerEvm: {
      address: '0xeeA20dbe589430cad9e94D662fCC43eC1c46AA36',
      abi: epochZeroNFTMarketAbi,
    },
  },

  // optional contracts
  accumulatorIotaAPEin: optional(contractAddresses.accumulatorIotaAPEin, accumulatorV6Abi),
  apeDaoBronzeSupporter: optional(contractAddresses.apeDaoBronzeSupporter, erc721Abi),
  apeDaoSilverSupporter: optional(contractAddresses.apeDaoSilverSupporter, erc721Abi),
  apeDaoGoldSupporter: optional(contractAddresses.apeDaoGoldSupporter, erc721Abi),
};

export default contracts;
export { contractAddresses, nftAddresses };
