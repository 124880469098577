import {
  ArrowsRightLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import { Card, IconButton, Typography } from '@material-tailwind/react';
import classNames from 'classnames';
import { Cart, FadeInOut } from 'components';
import { INftFilterProps } from 'components/NftFilter';
import { useIds } from 'helpers/idHelpers';
import useWindowSize from 'helpers/useWindowSize';
import { CollectionAttributes, ICart, ICollectionApprovals, INft, IWalletInfo } from 'types';
import { IBaseComponentProps } from '../BaseComponentProps';
import { NftFilter } from '../index';
import './Sidebar.css';
import getIds from './getIds';
import useSidebar from './hooks/useSidebar';

export const SIDEBAR_ANIMATION_DURATION = 'duration-500';

export interface ISidebarProps extends IBaseComponentProps, INftFilterProps {
  className?: string;
  position: 'left' | 'right';
  walletInfo: IWalletInfo;
  vaultWalletNfts: INft[];
  collectionAttributes: CollectionAttributes;
  cart?: ICart;
  collectionApprovals: ICollectionApprovals;
  isLoading: boolean;
  forceClose?: boolean;
  onChangeOpen?: (open: boolean) => void;
  onShowNftInfo?: (nftId: string) => void;
  onSwapSidebarPosition?: () => void;
}

function Sidebar(props: ISidebarProps) {
  const ID = useIds(props.id, getIds);
  const { isOpen, content, open, close, swapPosition } = useSidebar(props);
  const { isWidthSmallerThan } = useWindowSize();

  const cartTotalNumberOfItems =
    props.cart != null
      ? [...props.cart.items.values()]
          .filter((cartItem) => !cartItem.toBeRemoved)
          .reduce((sum, { quantity }) => sum + quantity, 0)
      : 0;

  return (
    <div id={ID.root} className={classNames(props.className, 'h-full')}>
      <FadeInOut isVisible={isOpen && isWidthSmallerThan('md')} maxOpacity="opacity-50" duration="slow">
        <div
          id={ID.backdrop}
          className={classNames('fixed top-0 z-10 block h-full w-full bg-black')}
          onClick={() => close()}
        />
      </FadeInOut>
      <Card
        id={ID.symbols}
        className={classNames(
          'absolute z-30 flex h-[3.5rem] w-full flex-row-reverse gap-2 rounded-none px-3 py-2 dark:bg-apedao-black-800 dark:shadow-none md:h-full md:w-fit md:flex-col md:justify-start md:py-3',
          { 'right-0': props.position === 'right' },
          { 'left-0': props.position === 'left' },
          'dark:border-b dark:border-b-apedao-black-500 md:dark:border-0',
          { 'dark:md:border-l-[1px] dark:md:border-l-apedao-black-500': props.position === 'right' },
          { 'dark:md:border-r-[1px] dark:md:border-r-apedao-black-500': props.position === 'left' }
        )}
      >
        {props.cart != null && (
          <div className="md:h-[3rem]">
            <IconButton
              ripple={false}
              variant={content === 'cart' ? 'filled' : 'text'}
              className={classNames(
                'border-black text-apedao-black-950 transition-all dark:border',
                {
                  'bg-amber-100 dark:border-amber-500 dark:bg-transparent dark:hover:bg-amber-500/10 dark:active:bg-amber-500/30':
                    content === 'cart',
                },
                { 'bg-transparent dark:border-apedao-black-800': content !== 'cart' },
                {
                  first_cart_item_animation: cartTotalNumberOfItems > 0,
                }
              )}
              onClick={() => (isOpen && content === 'cart' ? close() : open('cart'))}
            >
              <ShoppingCartIcon className="h-6 w-6" />
              <div className="absolute -right-4 -top-3.5 flex h-[1.5rem] w-[1.5rem] scale-75 items-center justify-center rounded-full border border-apedao-black-950 bg-amber-500 dark:border-apedao-black-600">
                <Typography className="text-xs font-medium text-apedao-black-950">{cartTotalNumberOfItems}</Typography>
              </div>
            </IconButton>
            <hr className="mb-4 hidden border-gray-300 dark:border-apedao-black-500 md:mt-2 md:block" />
          </div>
        )}

        <div className="md:h-[3rem]">
          <IconButton
            ripple={false}
            variant={content === 'filter' ? 'filled' : 'text'}
            className={classNames(
              'border-black text-apedao-black-950 transition-all dark:border',
              {
                'bg-amber-100 dark:border-amber-500 dark:bg-transparent dark:hover:bg-amber-500/10 dark:active:bg-amber-500/30':
                  content === 'filter',
              },
              { 'bg-transparent dark:border-apedao-black-800': content !== 'filter' }
            )}
            onClick={() => (isOpen && content === 'filter' ? close() : open('filter'))}
          >
            <MagnifyingGlassIcon className="h-6 w-6" onClick={() => open('filter')} />
            <FadeInOut
              isVisible={
                props.filters.nameFilter !== '' ||
                props.filters.attributeFilters.size > 0 ||
                props.filters.onlyFavorites ||
                Object.entries(props.filters.locationFilter).some(([, value]) => !value) ||
                Object.entries(props.filters.chainFilter).some(([, value]) => !value)
              }
            >
              <div className="absolute -right-4 -top-3.5 flex h-[1.5rem] w-[1.5rem] scale-75 items-center justify-center rounded-full border border-gray-900 bg-amber-500 transition-opacity">
                <Typography className="text-sm font-bold text-apedao-black-950">!</Typography>
              </div>
            </FadeInOut>
          </IconButton>
          <hr className="mb-4 hidden border-gray-300 dark:border-apedao-black-500 md:mt-2 md:block" />
        </div>
        <div className="mt-auto hidden md:block md:h-[3rem]">
          <hr className="hidden border-gray-300 dark:border-apedao-black-500 md:mb-2 md:block" />
          <IconButton
            ripple={false}
            variant="text"
            className={classNames('border-black bg-transparent text-apedao-black-950')}
            onClick={swapPosition}
          >
            <ArrowsRightLeftIcon className="text-apedao-black h-6 w-6" onClick={swapPosition} />
          </IconButton>
        </div>
      </Card>
      <Card
        id={ID.content}
        className={classNames(
          'absolute z-20 w-[18rem] rounded-none rounded-bl-md p-3 text-apedao-black-950 transition-transform dark:bg-apedao-black-800 dark:text-apedao-black-100 md:h-full md:rounded-bl-none md:p-3',
          SIDEBAR_ANIMATION_DURATION,
          { 'left-0': !isWidthSmallerThan('md') && props.position === 'right' },
          { 'right-0': isWidthSmallerThan('md') || props.position === 'left' },
          { '-translate-y-full md:translate-y-0': !isOpen },
          { 'max-h-[calc(100%-3.5rem)] translate-y-[3.5rem]': isWidthSmallerThan('md') && isOpen }
        )}
      >
        <div className="flex flex-row items-center justify-between md:min-h-[2.5rem]">
          <Typography variant="h5">{content === 'filter' ? 'Filters' : 'Cart'}</Typography>

          {props.cart != null && (
            <FadeInOut
              isVisible={
                content === 'cart' &&
                [...props.cart.items.values()].find((cartItem) => cartItem.nft.type !== 'random') != null
              }
            >
              <div className="s w-full text-center text-[0.7rem] font-normal tracking-wider dark:font-light">
                (Click on image for details)
              </div>
            </FadeInOut>
          )}

          {props.position === 'right' && (
            <ChevronRightIcon
              onClick={() => close()}
              strokeWidth={2}
              className="hidden h-6 w-6 translate-x-1 cursor-pointer justify-self-end md:block"
            />
          )}
          {props.position === 'left' && (
            <ChevronLeftIcon
              strokeWidth={2}
              onClick={() => close()}
              className="hidden h-6 w-6 translate-x-1 cursor-pointer justify-self-end md:block"
            />
          )}
          <ChevronUpIcon
            onClick={() => close()}
            className="h-6 w-6 translate-x-0.5 cursor-pointer justify-self-end md:hidden"
          />
        </div>
        <hr className="mb-3 mt-2 border-apedao-black-100 dark:border-apedao-black-500 md:mb-4" />

        {content === 'filter' && (
          <NftFilter
            id={ID.filter}
            collectionAttributes={props.collectionAttributes}
            filters={props.filters}
            vaultWalletNfts={props.vaultWalletNfts}
            isLoading={props.isLoading}
          />
        )}

        {content === 'cart' && props.cart != null && (
          <Cart
            id={ID.cart}
            cart={props.cart}
            collectionApprovals={props.collectionApprovals}
            onShowNftInfo={props.onShowNftInfo}
            walletInfo={props.walletInfo}
          />
        )}
      </Card>
      )
    </div>
  );
}

export default Sidebar;
